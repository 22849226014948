@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {

  --poppins: 'Poppins', sans-serif;
  --Montserrat: 'Montserrat', sans-serif;
}



@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }

}

button {
  cursor: pointer !important;
}





a:hover {
  text-decoration: none !important;
}

a {
  text-decoration: none !important;
}


header .navbar {
  /*background-image: url('../images/Navbg.png');*/
  background-size: cover;
  background-position: center;
}


header .navbar .navbar-nav .active {
  border-bottom: 1px solid #FFB300;
}

.navmenuid {
  display: flex;
  align-items: center;
  justify-content: center;
}

#bg-video{
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  padding-bottom: 56.25%;
}

.belldropdown button {
  background: linear-gradient(to right, #FFB300, #0482D8);
  padding: 7px 20px !important;
  border-radius: 30px;
  display: inline-block;
}


.belldropdown .dropdown-item {
  white-space: normal !important;
  background-color: #efeeee94;
  margin: 5px 0px;
  font-size: 14px;
}


.navmenuid .text h6 {
  font-size: 12px;
  color: #FFB300;
}

.navmenuid .text {
  color: #fff;
  font-size: 16px;
  text-align: left;
  font-weight: 600;
  margin-left: 10px;
}

header .dropdown-menu {
  position: absolute !important;
  left: -105px !important;
  top: 44px !important;
}

header .navbar ul li a {
  color: #fff !important;
  font-family: 'Poppins', sans-serif !important;
  font-family: var(--poppins) !important;
  transition: .2s ease-in-out;
  padding: 0px 0px;
  font-size: 16px;
}

header .navbar ul li:hover a {
  color: #FFB300 !important;
  transition: .2s ease-in-out;
}

header .navbar ul li {
  margin-right: 30px;
}

.bashboard-banner #bg-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
}

#bg-video iframe {
  position: absolute;
  min-height: 100vh;
  min-width: 177.77vh;
  width: 100vw;
  height: 56.25vw;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.bashboard-banner:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #15191e;
  top: 0;
  left: 0;
  opacity: .8;
}


header button#dropdownMenuButton span {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: block;

  cursor: pointer;
  transition: .2s ease-in-out;
  overflow: hidden;

}

.dropdown-menu.show {
  display: block;
  transition: .2s ease-in-out;
}

header .dropdown-toggle::after {
  display: none !important;
}

header .dropdown button {
  padding: 0;
  border: none;
  background-color: transparent;
}

header .dropdown button:focus {
  box-shadow: none;
  outline: none !important;
}

header .dropdown-item:hover {
  background-image: linear-gradient(to right, #FF8F4B, #FF3D38, #FF3D38);
}

.bashboard-banner .banner-items-list {
  background: linear-gradient(0.25turn, #842DD1, #AB2DBA, #E22D9A);
  border-radius: 15px;
  padding: 5px;
}

.bashboard-banner .banner-items p {
  color: #fff !important;
  font-size: 14px;
}

.bashboard-banner .banner-items a {
  margin-top: 15px;
}

.bg2 {
  background: linear-gradient(0.25turn, #2C7180, #1EAB91, #14D29D) !important;
}

.bg3 {
  background: linear-gradient(0.25turn, #2370BE, #4BB1CE, #4BB4CF) !important;
}

/* .banner-content{
  padding: 136px 0px;
} */

.banner-items {
  padding: 136px 0px;
}


.bashboard-banner {
  position: relative;
}

/* .banner-video-project::before{
  content: "";
  position: absolute;
  width: 100%;
  height: auto;
  background-image: url('images/banner-botbg.png');
  bottom:0;
  left: 0;
  opacity: 0.8;
} */

.banner-video-project:before {
  content: '';
  background-image: url(/static/media/banner-botbg.c5aafb03.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 200px;
  width: 100%;
  position: absolute;
  top: 0px;
  display: block;
  margin-top: -62px;
}

/* .bashboard-banner::before{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color:#15191E;
  top:0;
  left: 0;
  opacity: 0.8;
} */

.bashboard-banner {
  /*background-image: url('../images/Mask\ Group\ 3.png');*/
  background-size: cover;
  background-position: top center;
  width: 100%;

  background-repeat: no-repeat;

}

.banner-content .watch-demo-btn button {
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-weight: 600;
  /* background-image: linear-gradient(to right , #00C2FF , #00E9D8 , #00FFC2);
  padding: 15px 58px;
  border-radius: 30px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  overflow: hidden; */
}

button:focus {
  box-shadow: none !important;
  outline: none !important;
}



.banner-content .watch-demo-btn button span {
  margin-right: 5px;

}


.banner-content h4 {
  font-size: 39px;
  font-family: 'Montserrat', sans-serif;
  font-family: var(--Montserrat);
  font-weight: 300;
}

.banner-content h1 {
  font-size: 73px;
  font-weight: 800;
  font-family: 'Montserrat', sans-serif;
  font-family: var(--Montserrat);
}

.banner-content h1 span {
  color: #FFAA00;
}

.banner-content p {
  width: 544px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-size: 18px;
  font-weight: 300;
}

.dashborad-sec-3 {
  position: relative;
  overflow: hidden;
  padding: 80px 0px;
  background-color: #C8AED6;
}

.banner-video-project {
  background-color: #15191e;
  padding: 40px 0px;
  position: relative;
}

.banner-video-project .video-project h5 {
  color: #6A6A6A;
  font-size: 40px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.banner-video-project .video-project h5 span {
  color: #B54AE2;
}


.banner-video-project .video-project a span {
  color: #6A6A6A;
  margin-left: 20px;
}

.video-card {
  background-image: linear-gradient(to bottom, #00FDC3, #2DA8C7);
  border-radius: 10px;
  padding-top: 1px;
}


.project-create-btn button:hover {
  color: #2fcac9 !important;
}


.ClipsReel-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .8s ease-in-out;
  background-color: #000000a3;
  border-radius: 10px;
  -webkit-clip-path: circle(30.3% at 100% 0);
          clip-path: circle(30.3% at 100% 0);
}

.ClipsReel-overlay ul {
  height: auto;
}

.ClipsReel-overlay ul li a span {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  color: #000;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vidoe-play-icon span i {
  color: #fff !important;
}


.ClipsReel-overlay ul li:hover i {
  color: #00F5A0 !important;
}

.ClipsReel-overlay ul li:hover a {
  color: #00F5A0;
}

.ClipsReel-overlay ul li a {
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ClipsReel-overlay ul li a i {
  font-size: 18px;
  color: #000;
}


.video-img:hover .ClipsReel-overlay {
  opacity: 1;
  transition: .8s ease-in-out;
  -webkit-clip-path: circle(141.4% at 100% 0);
          clip-path: circle(141.4% at 100% 0);
}

.video-card .video-img {
  border-radius: 5px;
  position: relative;
  margin: 10px;
}

.video-card .video-title {
  padding: 10px;
  padding-top: 0px;
}

.video-card .video-title .vidoe-play-icon span {
  cursor: pointer;

}

.video-name {
  background-color: #B1FCFF;
  width: 100%;
  padding: 5px 10px;
  border-radius: 30px;
}

.video-name p {
  color: #000;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-size: 14px;
  padding-left: 7px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600;
}

.vidoe-play-icon span img {
  width: 45px;
}

.banner-video-project .video-project a {
  /* color:#6a6a6a;
  font-size: 25px;
  font-family: var(--poppins); */
  transition: .2s ease-in-out;
}

.banner-video-project .video-project a:hover {
  /* color:#00F5A0; */
  transition: .2s ease-in-out;
}

.banner-video-project .video-project a:hover span {
  color: #00F5A0;
}





.dashborad-sec-3-content h4 {
  font-size: 30px;
  font-weight: 800;
  color: #915BD8;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  transition: .2s ease-in-out;
}


.dashborad-sec-3-content h4:hover {
  color: #915BD8;
  opacity: .6;
  transition: .2s ease-in-out;
}

.dashborad-sec-3-content h6 {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  color: #fff;
  text-transform: uppercase;

}

.dashborad-sec-3-content p {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-weight: 200;
}

.imgsizeleft {
  margin-left: 50px;
}

.imgsizeright {
  margin-left: -320px;
}


.logoimg {
  /*background-image: url('../images/BG.png');*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
}

.logo-img {
  width: 200px;
}

.login-box {
  padding: 0px 0px 30px;
  min-height: calc(100vh - 118px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-content h1 {
  font-size: 55px;
  font-family: 'Montserrat', sans-serif;
  font-family: var(--Montserrat);
  font-weight: 800;
}

.login-content h1 span {
  color: #FF9100;
}

.login-content p {
  width: 90%;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-weight: 300;
  margin: auto;
  color: #C1C1C1;
}

.login-content h5 {
  font-size: 29px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}



.login-form {
  padding: 50px;
  /* background-color: rgb(255 255 255 / 52%); */
  border-radius: 25px;
  border: 1px solid #eb0022 !important;
  background-color: #4b0064;
}

.login-form h4 span {
  color: #FF9100;
}

.login-form h4 {
  font-family: 'Montserrat', sans-serif;
  font-family: var(--Montserrat);
  font-size: 26px;
  /* font-weight: 600; */
}

/* .login-form button{
  background-image: linear-gradient(to left , #00D3EB , #16B891);
  border:none;
  color:#fff;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 600;
  font-family: var(--poppins);
  cursor: pointer;
} */

/* .login-form button:focus{
  color:#00b889 !important;
  background-color: #fff !important;
} */

/* .login-form button:focus{
  background-image: none !important;
} */

.login-form .forgot a {
  color: #c3c3c3 !important;
}

.login-form .forgot span:hover {
  color: #ff9100;
  transition: .2s ease-in-out;
}

.login-form label {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  color: #c3c3c3;
}

/* .login-form .form-group input{
    background-color: transparent !important;
    border-color: #34B8CC !important;
    border-radius: 10px !important;
  } */

.login-form .form-group input:focus {
  box-shadow: none !important;
}

.login-form .form-group .form-control {
  color: #878787 !important;
}

.login-form a {
  font-weight: 500;
}

.login-form a span {
  color: #ff3d38;
  margin-left: 5px;
  transition: .2s ease-in-out;
}

/* .login-form a span:hover{
  color:#FF9100;
} */

.login-form .form-group {
  background-color: red;
}

.footer .footer-menu ul li a {
  color: #fff;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  transition: .2s ease-in-out;
}

.footer .footer-menu ul li a:hover {
  color: #00F5A0;
  transition: .2s ease-in-out;
}

.footer-copyright p a:hover {
  color: #ff9100 !important;
  transition: .2s ease-in-out;
}

.footer .footer-menu ul li+li {
  margin-left: 40px;
}

.footer .footer-copyright p {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.footer .footer-copyright p a {
  transition: .2s ease-in-out;
  color: #fff;
}

.footer {
  background-color: #0000007a;
  padding: 10px 0px;
}



.footerbg {
  background-color: #000000;
  padding: 15px 0px;
}

.vineasx-logo img {
  width: 170px;
  object-fit: cover !important;
}


.banner-items .banner-items-list .col-md-3 img {
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: .4s ease-in-out;
}

/* .banner-items .banner-items-list{
    transform: scale(1);
    transition: .4s ease-in-out;
}

 .banner-items .banner-items-list:hover{
    transform: scale(1.05);
    transition: .4s ease-in-out;
}  */

.banner-items .banner-items-list:hover .col-md-3 img {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  transition: .4s ease-in-out;
}



.project-banner {
  padding: 20px 0px;
  background-color: #15191E;
}


.project-header-name .project-conut h6 {
  color: #A2A2A2;
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  margin-left: 30px !important;
  position: relative;
  font-weight: 200;
}

.project-header-name .project-conut h6::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 20px;
  background-color: #A2A2A2;
  top: 3px;
  left: -15px;
}



.project-header-name .project-item {
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-weight: 600;
}



.video-img img {
  width: 100%;
  height: 192px;
}


.project-button .project-create-btn button {
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  background-image: linear-gradient(to right, #36D6FF, #C867FF);
  border: none;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-weight: 600;
  cursor: pointer;
}

.project-button .project-create-btn button:focus {
  background-color: #fff !important;
  background-image: none !important;
  color: #2fcac9 !important
}

.project-input-box .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background: #fff;
  border: none !important;
  color: #979797;
  padding-top: 10px;
  font-size: 14px;
}

.project-input-box .input-group .input-group-prepen {
  margin-right: 0px !important;
}

.project-input-box .input-group input {
  border: none !important;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 44px;
}

.project-input-box .input-group input:focus {
  box-shadow: none !important;
}

.project-input-box ::-webkit-input-placeholder {
  color: #7A7A7A !important;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.project-input-box ::placeholder {
  color: #7A7A7A !important;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.project-input-box {
  margin-right: 20px;
}

.project-banner .bottom-line {
  border-bottom: 1px solid #A2A2A2;
  padding-bottom: 20px;
  display: block;
  width: 98%;
  margin: auto;
}

.project-banner {
  min-height: 100vh;
  padding-bottom: 40px;
  padding-top: 90px;
}



.login-form .form-group label {
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  color: #C3C3C3;
  margin-left: 10px;
}

.login-form .form-group input {
  height: 50px;
}

.login-form ::-webkit-input-placeholder {
  font-size: 16px;
  color: #707070;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  padding-left: 10px;
}

.login-form ::placeholder {
  font-size: 16px;
  color: #707070;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  padding-left: 10px;
}

.btn-change6 {

  box-shadow: 0 0 1px #ccc;
  transition-duration: .3s;
  transition-timing-function: linear;
  box-shadow: 0 0 0 #fff inset;
  cursor: pointer;

}






/* .login-form button:hover {
  color: #00b889 !important;
} */


.btn-change6:hover {
  box-shadow: 1000px 0 0 #fff inset;
  transition: .3s ease-in-out;
  cursor: pointer;
}


.medium-btn {

  box-shadow: 0 0 1px #ccc;
  transition-duration: .3s;
  transition-timing-function: linear;
  box-shadow: 0 0 0 #fff inset;
  cursor: pointer;
}


.medium-btn:hover {
  box-shadow: 300px 0 0 #fff inset;
  transition: .3s ease-in-out;
  cursor: pointer;
}


.btn-change7 {

  box-shadow: 0 0 1px #ccc;
  transition-duration: .3s;
  transition-timing-function: linear;
  box-shadow: 0 0 0 #fff inset;
  cursor: pointer;
}

.btn-change7:hover {
  box-shadow: 400px 0 0 #fff inset;
  transition: .3s ease-in-out;
  cursor: pointer;
}

.toggleupdate {
  background: linear-gradient(to left, #00D3EB, #16B891);
  border: none;
  padding: 7px 30px;
  border-radius: 30px;
  color: #fff;
  height: 45px;
}

.toggleupdate:hover {
  color: #2fcac9;
}

.cancl-btn a {
  color: #fff;
}

.btn-cancl {
  position: relative;
  z-index: 9999;
  display: inline-block;
}

.cancl-btn {
  background: #fff;
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
  color: #000;
  overflow: hidden;
  z-index: 2;
  position: relative;
}

.cancl-btn:hover {
  color: #fff !important;
}


.cancl-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to left, #00D3EB, #16B891);
  width: 0%;
  height: 100%;
  z-index: -1;
  transition: .3s ease-in-out;
  border-radius: 30px;

}

.cancl-btn:hover::before {
  width: 100%;
  transition: .3s ease-in-out;
  color: #fff !important;
}


.fixed-me {
  /*background-image: url('../images/Navbg.png');*/
  background-size: cover;
  background-position: center;

  margin-bottom: 5px;
  position: fixed;
  top: 0;
  z-index: 8888;
  width: 100%;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 50%);
}






.watch-demo-btn button {
  position: relative;
  z-index: 999;
}

/* .watch-demo-btn button::before {
  content: '';
  width: 0%;
  height: 100%;
  background-color: #fff;
  top: 0;
  left: 0;
  position: absolute;
  border-radius: 30px;
  color:#00F5A0;
  transition: .3s ease-in-out;
  z-index: -1;
} */

.watch-demo-btn button:hover::before {
  width: 100%;
  transition: .3s ease-in-out;
  border-radius: 30px;
}

/* 
.watch-demo-btn button:hover{
  color:#00b889 !important; 
  transition: .4s ease-in-out;
} */

.select-box .form-group {
  width: 260px;
  border: 1px solid #00FFD4;
  border-radius: 5px;
  margin-right: 20px !important;
}

.select-box .form-group select {
  border: none !important;
}

.select-box .form-group select:focus {
  box-shadow: none !important;
}

.select-box .form-group select {
  background-color: #101010;
}

.search-select .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background: #fff;
  border: none !important;
  color: #979797;
  padding-top: 10px;
  font-size: 14px;
}

.search-select .input-group .input-group-prepen {
  margin-right: 0px !important;
}

.search-select .input-group input {
  border: none !important;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 44px;
}

.search-select .input-group input:focus {
  box-shadow: none !important;
}

.search-select ::-webkit-input-placeholder {
  color: #8b7676 !important;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.search-select ::placeholder {
  color: #8b7676 !important;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}



.premium-header {
  background-color: #303030;
  padding: 15px 0px;
  margin-top: 65px;
}

.select-box select {
  color: #fff !important;
}


.template-name h4 {
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-weight: 400;
}



.template-number h6 {
  color: #A2A2A2;
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  margin-left: 30px;
  position: relative;
  font-weight: 200;
}


.template-number h6::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 18px;
  background-color: #A2A2A2;
  top: 4px;
  left: -15px;
}


.template-body {
  padding: 60px 0px;
  background-color: #171717;
  min-height: 100vh;
}

.template-names h6 {
  color: #DEDFDD;
}


/* .template-body .row {
    overflow-y: auto;
    height: 60vh;
} */




.template-body ::-webkit-scrollbar {
  width: 5px;
}

.template-body ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.template-body ::-webkit-scrollbar-thumb {
  background: #00F5A0;
  border-radius: 10px;
  height: 10vh !important;
}


.customModal .modal-dialog .modal-body {
  height: 60vh;
}

.customModal .modal-dialog .modal-footer button {
  background: linear-gradient(to left, #00D3EB, #16B891);
  padding: 7px 25px;
  border: none;
  color: #fff;
  border-radius: 5px;
}

.customModal .modal-dialog .modal-footer button:hover {
  color: #2fcac9;
}

.customModal .modal-dialog .modal-footer button:focus {
  background: #fff;
  color: #2fcac9;
}

.customModal .modal-dialog .modal-footer {
  border: none;
}

.customModal .modal-scrollbar {
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.modal-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.modal-scrollbar::-webkit-scrollbar-thumb {
  background: #00F5A0 !important;
  border-radius: 10px;
  height: 10vh !important;
}



/* .template-body ::-webkit-scrollbar-thumb:hover {
  background: #b30000;
} */



.modal-header .close {
  padding: 0px !important;
  margin: 0px !important;
}

.modal-header {
  width: 100%;
  margin: auto;
}

.modal-header .modal-title span {
  color: #00F5A0;
}

.modal-dialog {
  max-width: 1000px !important;
}


button.close {
  opacity: 0.5;
}

button.close:hover {
  opacity: 0.8;
}

.modal-content {
  background-color: #0c0c0c;
  color: #000 !important;
}

/* .banner-content .modal-dialog .close{
  opacity: 9 !important;
} */

.modal-title button span {
  color: #fff !important;
}

.facebook-item-box {
  border: 1px solid #707070;
  border-radius: 10px;
  background-color: #1C1C1C;
  transition: .4s ease-in-out;
  cursor: pointer;
}

.facebook-item-box:hover {
  border: 1px solid #00F5A0;
  transition: .4s ease-in-out;
  cursor: pointer;
}

.facebook-item-box h6 {
  color: #6A6A6A;
}

.facebook-item-box h5 {
  font-size: 14px;
}

.vidoe-play-icon {
  margin-top: 6px;
}



/* editor css start */


.editor-sec {
  background: #000000;
  min-height: 95vh;

  overflow: hidden;
}

.save-render-sec {
  background-color: #0c0c0c9e;
  padding: 10px;
}

/* .render-projectbtn {
    margin-right: 85px;
} */
.editor-part {
  overflow-y: auto;
  overflow-x: hidden;
  background: #191919;
  padding: 10px 0px;
  height: 650px;
}

.editor-part {
  overflow-y: auto;
  overflow-x: hidden;
  background: #191919;
  padding: 10px 0px;
  height: 650px;
}


.editor-part ::-moz-scrollbar {
  width: 5px !important;
  height: 10em !important;
}

.editor-part ::-moz-scrollbar-track {
  box-shadow: inset 0 0 10px grey !important;
  border-radius: 10px !important;

}

.editor-part ::-moz-scrollbar-thumb {
  background: #00F5A0 !important;
  border-radius: 10px !important;
  height: 10px !important;
}








.editor-part::-webkit-scrollbar {
  width: 5px !important;
  height: 10em !important;
}

.editor-part::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px grey !important;
  border-radius: 10px !important;

}

.editor-part::-webkit-scrollbar-thumb {
  background: #00F5A0 !important;
  border-radius: 10px !important;
  height: 10px !important;
}





.save-render-sec .add-campaign input {
  border-radius: 30px;
  border: none;
  background: #161616;
  width: 290px;
  height: 42px;
}

.save-render-sec ::-webkit-input-placeholder {
  color: #A5A5A5;
}

.save-render-sec ::placeholder {
  color: #A5A5A5;
}

.save-render-sec .add-campaign .form-control {
  color: #A5A5A5;
}

.save-render-sec .save-projectbtn button {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  padding: 7px 25px;
  border-radius: 5px;
  border: none;
  color: #fff;
}

.save-render-sec .render-projectbtn button {
  background: linear-gradient(to right, #A72CE3, #CB3B71);
  padding: 7px 25px;
  border-radius: 5px;
  border: none;
  color: #fff;
}

.editor-tab-menu .nav-item .nav-link h6 {
  color: #fff;
  margin-top: 5px;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  margin-bottom: 0px;
}

.editor-tab-menu .nav-item {
  padding: 0px 10px;
}

.editor-tab-menu .nav-item .nav-link.active {
  background: #414141;
  border-radius: 10px;
  padding: 5px 10px;
}

.editor-tab-menu .nav-item .nav-link.active h6 {
  color: #00FFB1;
}

.editor-tab-menu .nav-item .nav-link {
  padding: 5px 10px;
}

.editor-tab-menu .nav-item .nav-link img {
  width: 17px;
}

.editor-tab-menu {
  padding: 10px;
}

.editor-preview {
  padding: 40px 0px;
  background: #333333;
  position: relative;
  /* width: 110%; */
}


.story-part-sec .story-para p {
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  color: #fff;
}

.story-part-sec .story-para {
  background-color: #242424;
  padding: 20px;
  border-radius: 30px;
  border: 1px solid #707070;
}

.editor-preview-box {
  width: 656px;
  height: 369px;
  overflow: hidden;
  margin: auto;
}

.para-active {
  background-color: #2DA882 !important;
}

.slide-box ul li {
  width: 150px;
  height: 70px;
  border: 1px solid #434343;
  background-color: #101010;
  margin: 0px 10px;
  text-align: center;
  display: flex;
  align-items: center;
  color: #9A9A9A;
  border-radius: 10px;
  font-size: 14px;
}


.slide-sec {
  background-color: #0c0c0c;
  padding: 15px;
}

.slide-box .plus-icon i {
  color: #565656;
}

.start-end-time input {
  width: 100px;
  background: transparent;
  border: none;
  color: #fff !important;
}

.start-end-time input:focus-visible {
  outline: none !important;
}

.start-end-time input.form-control {
  color: #fff;
}

.start-end-time ::-webkit-input-placeholder {
  color: #fff;
}

.start-end-time ::placeholder {
  color: #fff;
}


.controls-box {
  background: #000;
  padding: 20px;
  width: 657px;
  margin: auto;
  position: relative;
  z-index: 999;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 3px 5px -1px #000;
}



.controls-box .start-end-time ul li>input:focus {
  box-shadow: none !important;
}

.save-projectbtn button:hover {
  color: #2fcac9;
}

.save-projectbtn button:focus {
  background-color: #fff !important;
  background-image: unset !important;
  color: #2fcac9 !important;
}

.add-campaign input:focus {
  box-shadow: none;
}

.render-projectbtn button:hover {
  color: #2fcac9;
}

.render-projectbtn button:focus {
  background-color: #fff !important;
  background-image: unset !important;
  color: #2fcac9 !important;
}

.style-upload-part {
  background: #303030;
  /* padding: 10px; */
  border-radius: 10px;
}

.style-upload-box {
  background-color: #171717;
  height: 77px;
  border: 1px dashed #707070;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.style-upload-box .custom-file-input {
  position: absolute;
  top: 0;
  opacity: 0;
  background: red;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.style-upload-box h6 {
  font-size: 12px;
  margin-bottom: 0px;
  color: #8F8F8F;
}

.style-upload-box .custom-file {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.position-text-box {
  background: #303030;
  padding: 10px 10px;
  border-radius: 10px;
}

.position-text-box .text-position-dropdown label {
  font-size: 14px;
  margin-left: 14px;
}

.position-text-box .text-position-dropdown .box:hover {
  background-color: #00BF85;
  transition: all .2s;
  border: 2px solid #00BF85;
}

.position-text-box .text-position-dropdown .box.box-active {
  background-color: #00BF85 !important;
  border: 2px solid #00BF85 !important;

}

.position-text-box .text-position-dropdown .box {
  margin: 5px;
  border: 2px solid #707070;
  border-radius: 0;
  background-color: #636363;
  cursor: pointer;
  padding: 10px;
  width: 20px;
  height: 20px;
  color: #fff;
  transition: all .2s;
}

.position-text-box .text-position-dropdown .box-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.remove-logo-switch-btn .form-check label {
  font-size: 14px;
  color: #8F8F8F;
}

.remove-logo-switch-btn .form-check .form-check-input {
  margin-top: 2px;
  margin-left: 10px;
  font-size: 24px;
}

.style-remove-cricle {
  width: 50px;
  height: 50px;
  background-color: #000;
  border-radius: 50%;
  border: 2px solid #686868;
}

.dimmer-wrapper {
  background-color: #303030;
  padding: 10px;
  border-radius: 10px;
}

.dimmer-wrapper .dimmer-light h6 {
  font-size: 14px;
}

.dimmer-switch-btn .remove-logo-switch-btn .form-check-input {
  margin-top: 2px;
  font-size: 24px;
}

.swap-template-wrapper {
  background-color: #303030;
  padding: 10px;
  border-radius: 10px;
}

.swap-template-wrapper .swap-template-box {
  background-color: #101010;
  height: 100px;
}

.swap-template-wrapper h6 {
  font-size: 14px;
}

.editor-tab-menu {
  /* width: 110%; */
  background: #232323;
}



/* media tab css start */




.media-menu-box .nav-item .nav-link {
  color: #fff;
  background-color: #3E3E3E;
  padding: 6px 20px;

  font-size: 13px;
  padding: 9px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  text-align: center;

}

.media-menu-box .nav-item .nav-link.active {
  background-color: #fff;
  color: #10CA9C;
  font-size: 13px;
  padding: 9px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  text-align: center;
}

.media-search-box .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: #fff;
  border: 1px solid #fff;
  color: #979797;
  cursor: pointer;
}

.media-search-box .input-group input {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border: none;

}

.media-search-box .input-group-prepend {
  margin-left: 0px;
}

.media-search-box input:focus {
  box-shadow: none;
}

.search-laod {
  background-color: #101010;
  height: 100px;
}

.media-upload-box button {
  background: linear-gradient(to right, #B376FF, #4AC6FF);
  border: none;
  color: #fff;
  border-radius: 30px;
}

.media-upload-box {
  position: relative;
}

.media-upload-box .custom-file {
  position: absolute;
  top: 0px;
}

.media-upload-box .custom-file .custom-file-input {
  opacity: 0;
  background-color: red;
  border-radius: 30px;
  font-size: 2px;
  position: absolute;
  top: 0px;
  cursor: pointer;
}

/* media start css */


.credites-wrapper textarea {
  background-color: #0C0C0C;
  border: 1px solid #707070;
}

.credites-wrapper textarea:focus {
  background-color: #0C0C0C;
  box-shadow: none;
}

.credites-wrapper textarea.form-control {
  color: #787878;
}


.credites-wrapper label {
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.credites-upload-box .outer-images {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #000;
}

.credites-uplaod {
  position: relative;
  height: 100px;
  background-color: #171717;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #707070
}

.credites-uplaod h6 {
  font-size: 14px;
  color: #8F8F8F;
}

.credites-uplaod .custom-file {
  position: absolute;
  top: 0;
}


.credites-uplaod .custom-file-input {
  position: absolute;
  z-index: 2;
  width: 100%;
  margin: 0;
  opacity: 0;
  background: red;
  height: 261%;
  font-size: 1px;
  cursor: pointer;
}

.credites-upload-box {
  padding: 20px;
  background: #303030;
  border-radius: 10px;
}

.remove-outer-logo .form-check-input {
  margin-left: -5px;
  font-size: 24px;
  margin-top: 0px;
}

.outro-template-box {
  padding: 20px;
  background: #303030;
  border-radius: 10px;
}

.outro-template-box h6 {
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.outer-images-box {
  background-color: #000000;
  height: 100px;
}


/* audio editor css start */



.audio-box-sec .audio-box-menu .nav-item .nav-link.active {
  background-color: #fff;
  color: #10CA9C;
  text-align: center;
  padding: 10px;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.audio-box-sec .audio-box-menu .nav-item .nav-link {
  background-color: #3E3E3E;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  padding: 10px;
  text-align: center;
}

.horigental-line {
  border: 1px solid #BEBEBE;
  width: 100%;
  display: block;
}

.audio-music-box .card {
  background: linear-gradient(to bottom, #00EDBC, #009DB0);
  border-radius: 10px;
  transition: .4s ease-in-out;
}

.audio-music-box .card:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: .4s ease-in-out;
}

.audio-music-box .card .card-footer {
  background-color: #54D1CE;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}


.audio-music-box .card .card-title {
  padding: 10px;
  font-size: 14px;
}

.audio-music-box .card-body {
  padding: 0px;
}

.card-music-name h6 {
  font-size: 14px;
}

.audio-slide-box .form-group select {
  background-color: #0C0C0C;
  color: #fff;
  box-shadow: none;
  border-color: #707070;
  font-size: 14px;
}

.audio-language-box .form-group select {
  background-color: #0C0C0C;
  color: #fff;
  box-shadow: none;
  border-color: #707070;
  font-size: 14px;
}

.audio-language-box .form-group label {
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.audio-voice-box .form-group label {
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.audio-slide-box .form-group label {
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.audio-language-box .form-group .form-select {
  padding-right: 0px;
}

.audio-voice-box .form-group select {
  background-color: #0C0C0C;
  color: #fff;
  box-shadow: none;
  border-color: #707070;
  font-size: 14px;
}

.audio-voice-box .form-group .form-select {
  padding-right: 0px;
}

.audio-add-text-box textarea {
  background: #0C0C0C;
  border-color: #707070;

}

.audio-add-text-box input {
  background: #0C0C0C !important;
  border-color: #707070 !important;

}

.audio-add-text-box textarea:focus {
  background: #0C0C0C;
  box-shadow: none;
}

.audio-add-text-box textarea.form-control {
  color: #787878;
}

.tts-box-wrapper {
  background: #303030;
  border-radius: 10px;
  padding: 10px;
}

.audio-preview-btn button {
  background: linear-gradient(to right, #4AC6FF, #B376FF);
  border: none;
  color: #fff;
  border-radius: 50px;
  height: 45px;
}


.audio-preview-btn button:hover {
  color: #2fcac9;
}

.audio-save-use-btn button:hover {
  color: #2fcac9;
}

.audio-save-use-btn button {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  border: none;
  color: #fff;
  border-radius: 50px;
  height: 45px;
}

.audio-library-boxs .audio-upload-btn button {
  border-radius: 30px;
  background: linear-gradient(to right, #4AC6FF, #B376FF);
  border: none;
  height: 45px;
  color: #fff;
}

.audio-upload-btn {
  position: relative;
}






.audio-library-boxs .audio-upload-btn .custom-file-input {
  position: absolute;
  z-index: 2;
  width: 100%;
  margin: 0;
  opacity: 0;
  background: red;
  border-radius: 30px;
  font-size: 1px;
  height: 45px;
  cursor: pointer;
}

.audio-library-boxs .audio-upload-btn .custom-file {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
  top: 0;
}


.upload-box-card .card {
  background: linear-gradient(to bottom, #B94EFF, #DB438F);
  border: none;
  border-radius: 10px;
  transition: .4s ease-in-out;
}

.upload-box-card .card:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: .4s ease-in-out;
}

.upload-box-card .card-footer {
  background: #B240E6;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.upload-box-card .card-title {
  padding: 10px;
  font-size: 14px;
}

.upload-box-card .card-body {
  padding: 0px;
}

.card-play-icon {
  cursor: pointer;
}


.remove-logo-switch-btn .form-check-input:checked {
  background: #C23636;
  box-shadow: none;
  border-color: #C23636;
}

.remove-logo-switch-btn .form-switch .form-check-input:checked {
  background-position: right center !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-repeat: no-repeat !important;
  box-shadow: none;
}


.remove-logo-switch-btn .form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  box-shadow: none;
}



.dimmer-switch-btn .remove-logo-switch-btn .form-check-input:checked {
  background: #00BF85;
  box-shadow: none;
  border-color: #00BF85;
}


.dimmer-switch-btn .remove-logo-switch-btn .form-switch .form-check-input:checked {
  background-position: right center !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-repeat: no-repeat !important;
  box-shadow: none;
}


.dimmer-switch-btn .remove-logo-switch-btn .form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  box-shadow: none;
}


header .editormenu {
  left: -182px !important;
}

.slide-box {
  overflow-x: auto;
  overflow-y: hidden;
}

.text-font-size {
  width: 120px;
}

.text-box-sec .text-box-wrapper select {
  background-color: #0C0C0C;
  color: #fff;
}

.text-box-sec .text-box-wrapper .text-font-style select {
  border-color: #00F5A0;
}


.font-style-boxs .btn-group button {
  background-color: #0C0C0C;
  margin: 0px 10px;
  border-radius: 5px !important;
}



.profile-sec {
  background-color: #E5E3F2;
  padding: 120px 0px 60px;
  min-height: 100vh;
}

.user-img {
  position: relative;
  width: 100px;
  height: 100px;

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  overflow: hidden;
}

.user-img .custom-file {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 65px);
  margin-bottom: 0;
  top: 0;
  left: 0;
  background: red;
  font-size: 1px;
  border-radius: 50%;
  opacity: 0;
}


.user-img .custom-file-input {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  background: orange;
  border-radius: 100%;
  cursor: pointer;
}

.user-details h5 {
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  color: #fff;
}

/* .user-details button{
  padding: 10px 30px;
  border:none;
  background:linear-gradient(to right,#16B891 ,  #00D3EB);
  border-radius: 30px;
  color:#fff;
  font-size: 16px;
  font-weight: 400;
  font-family: var(--poppins);
} */

.user-details .user-img span {
  color: #14BB9B;
}


.form-box .form-group input {
  background-color: transparent !important;
  border: none;
  padding: 0px;
}

.form-box .form-group textarea {
  background-color: transparent !important;
  border: none;
  padding: 0px;
  height: 100px;
}

.form-box .form-group select {
  /* background-color: transparent !important; */
  border: none;
  padding: 0px;
  background: #1e1e1e !important;
}

.form-box .form-group select:focus {
  box-shadow: none;
  outline: none !important;
}

.form-box .form-group input:focus {
  box-shadow: none !important;
}

.form-box .form-group {
  background-color: #E1DFF2;
  color: #fff;
  padding: 5px 20px;
  border-radius: 10px;
  border: 1px solid #707070;
}

.formactive {
  border: 1px solid #00F8B8 !important;
}

.form-box .form-group .form-control {
  color: #2a2a2a !important;
  font-weight: 200;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.form-box .form-group ::-webkit-input-placeholder {
  color: #6c757d !important;
}

.form-box .form-group ::placeholder {
  color: #6c757d !important;
}


.personal-details-box h6 {
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  color: #606060;
}

/* .form-box button{
  background: linear-gradient(to right , #36D6FF , #C867FF);
  border-radius: 10px;
  border:none;
  height: 45px;
  color:#fff;
} */

/* .form-box button:hover{
  color:#2fcac9 !important
} */

/* .form-box button:focus{
  background: #fff;
  color:#2fcac9;
} */
.btn-submit {
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  margin: 0 auto;
}

.form-box {
  background-color: #fff;
  padding: 50px;
  border-radius: 30px;
}


.gdpr-sec {
  padding: 80px 0px 80px;
  min-height: 100vh;
  background: #171717;
}

.gdpr-update-email {
  padding: 30px;
  background: #000000;
  border-radius: 10px;
}

.gdpr-sec .gdpr-update-email .form-group input {
  background-color: transparent;
  border: none;
  padding: 0px;
}

.gdpr-update-email .form-group {
  background: #1E1E1E;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid #1E1E1E;
}




.gdpr-update-email .form-group input:focus {
  box-shadow: none !important;
}

.gdpr-update-email .form-group .form-control {
  color: #fff;
  font-weight: 200;
  font-size: 14px;
}

.gdpr-update-email .form-group ::-webkit-input-placeholder {
  color: #6c757d;
}

.gdpr-update-email .form-group ::placeholder {
  color: #6c757d;
}

.gdpr-update-btn button {
  background: linear-gradient(to left, #FF8F4B, #FF3D38);
  border: none;
  color: #fff;
  border-radius: 30px;
  padding: 7px 30px;
  font-size: 16px;
}

.gdpr-update-btn button:hover {
  color: #FF3D38;
}

.gdpr-update-btn button:focus {
  background: #fff;
  color: #2fcac9;
}


.manage-data-box {
  background: #000000;
  padding: 40px 20px;
  border-radius: 10px;
}

.manage-data-box p {
  font-size: 'Poppins', sans-serif;
  font-size: var(--poppins);
}

.manage-data-box p span {
  color: #f27277;
}

.manage-update-btn button {
  border: none;
  padding: 7px 20px;
  border-radius: 30px;
  color: #fff;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.manage-update-btn button::before {
  content: '';
  position: absolute;
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 30px;
  z-index: -1;
  transition: .3s ease-in-out;
  overflow: hidden;
}

.manage-update-btn button:hover::before {
  width: 100%;
  transition: .3s ease-in-out;
  overflow: hidden;
}

.manage-update-btn button:hover {
  color: #2fcac9;
  position: relative;
  z-index: 999;
  overflow: hidden;
}



.add-account-sec {
  padding: 60px 0px;
  background: #171717;
  min-height: 100vh;
}

.add-account-box {
  background: #000000;
  padding: 40px;
  border-radius: 10px;
}

.add-account-box .form-group input {
  background: transparent;
  border: none;
  padding: 0px;
}

.text-muted {
  color: #717171 !important;
}


.add-account-box .form-group input:focus {
  box-shadow: none;
}

.add-account-box .form-group ::-webkit-input-placeholder {
  color: #6c757d;
  font-weight: 200;
}

.add-account-box .form-group ::placeholder {
  color: #6c757d;
  font-weight: 200;
}

.add-account-box .form-group .form-control {
  color: #fff;
  font-weight: 200;
}

.add-account-box .form-group select {
  background: transparent;
  border: none !important;
  padding: 0px;
  text-align: left;
  height: 24px !important;
  color: #6c757d;
}

.add-account-box .form-group select:focus {
  box-shadow: none;
}

.add-account-box .form-group {
  background: #1E1E1E;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid #1E1E1E;
}

.add-account-box .form-group:focus-within {
  border-color: #FFB300 !important;
}

.add-account-save-btn button {
  background: linear-gradient(to left, #00D3EB, #16B891);
  border: none;
  padding: 7px 30px;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  border-radius: 30px;
}


.add-account-save-btn button:hover {
  color: #2fcac9;
}

.add-account-save-btn button:focus {
  background: #fff;
  color: #2fcac9;
}

.team-member-box {
  background: #000000;
  padding: 40px;
  border-radius: 10px;
}

.text-wrapper {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 150px;

}


.help-support-banner {
  background-image: url(/static/media/help-support-bg.d0c83d60.png);
  margin-top: 65px;
  overflow: hidden;
  height: 620px;
  background-repeat: no-repeat;
  background-size: cover;
}

.important-sec:before {
  content: '';
  background-image: url(/static/media/help-suppot-banner-down.20e47f2d.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 200px;
  width: 100%;
  position: absolute;
  top: 0px;
  display: block;
  margin-top: -62px;
}

.customer-sec {
  padding: 60px 0px;
  background-color: #fff;
}

.customer-content h6 {
  color: #000;
  font-weight: 300;
  font-size: 25px;
}

.customer-content h5 {
  font-size: 32px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-weight: 600;
}

.customer-content a {
  padding: 10px 30px;
  background-color: #01D084;
  color: #fff;
  border-radius: 30px;
  margin-top: 10px;
  display: inline-block;
  font-weight: 600;
}

/* .customer-content a:hover{
  color:#01D084;
  font-weight: 600;
} */

.gdpr-update-email .form-group:focus-within {
  border-color: #01D084 !important;
}

.form-group:focus-within {
  border-color: #FFB300 !important;
}

.secarchicone-part #searchimg {
  position: relative;
  left: 40%;
}



.help-banner-content .input-group .input-group-text {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  margin-left: 0px;
  background-color: #ffffff;
  border: none;
  color: #7A7A7A;
  font-size: 20px;
  padding-right: 0px;
  cursor: pointer;
}

.help-banner-content .input-group .input-group-prepend {
  margin-right: 1px;
}

.help-banner-content .input-group input {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  margin: 0px;
  background-color: #fff;
  border: none;
}

.help-banner-content .input-group input:focus {
  box-shadow: none;
}

.help-banner-content .input-group ::-webkit-input-placeholder {
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  color: #7A7A7A;
  font-size: 20px;
}

.help-banner-content .input-group ::placeholder {
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  color: #7A7A7A;
  font-size: 20px;
}

.help-banner-content .input-group .form-control {
  color: #000;
  font-size: 18px;
}


.help-banner-content h3 {
  font-size: 42px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-weight: 600;
}

.help-banner-content .input-group.mt-4 {
  height: 50px;
}

.help-banner-content h3 span {
  color: #F5CC00;
}

.important-topics span {
  width: 100px;
  height: 100px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}


.important-topics span img {
  max-width: 50%;
}

.important-sec {
  background-color: #fff;
  padding: 60px 0px;
  position: relative;
}


.important-topics {
  background: linear-gradient(to bottom, #00E2A4, #00B5DF);
  padding: 40px;
  border-radius: 15px;
  transition: .4s ease-in-out;
  height: 247px;
}

.important-topics:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  transition: .4s ease-in-out;
}

.popular-see {
  /* background-color: #000000; */
  background: linear-gradient(250deg, #72168D, #FF3E53);
  padding: 60px 0px;
}

.popular-video-sec h3 {
  font-size: 26px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.important-sec h3 {
  font-size: 26px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.player-box {
  background: #fff;
  /* background: linear-gradient(to bottom , #00FDC3, #2DA8C7); */
  padding: 10px;
  border-radius: 10px;
}

.support-sec {
  padding: 60px 0px;
  background-color: #242424;
}

.support-content h6 {
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  color: #00F5A0;
}

.support-content h3 {
  font-size: 40px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.support-content p {
  font-size: 18px;
  width: 90%;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
}

.support-content button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  color: #fff;
  padding: 7px 30px;
  border-radius: 30px;
  border: none;
}

.upgrade-menu .nav .nav-link:hover {
  background-color: #00BBAE;
}

.support-content button:hover {
  color: #2fcac9;
}

.support-content button:focus {
  background: #fff;
  color: #2fcac9;
}

.traning-sec {
  background-color: #232323;

  min-height: 100vh;
}

.popular-topics-content {
  background-color: #171717;
  padding: 20px 30px;
  min-height: 100vh;
}

.popular-topics-content .vidoebtn {
  background: linear-gradient(to right, #00D3EB, #16B891);
  padding: 10px 30px;
  border: none;
  border-radius: 30px;
  color: #fff;
}

.popular-topics-box .nav {
  background: #000000;
  border-radius: 10px;
  overflow: hidden;
  padding: 0px 0px 20px 0px;
}

.popular-topics-box .nav .nav-link {
  color: #fff;
  padding-left: 40px;
}


.popular-topics-box .nav .nav-link.active {
  background-color: #00BBAE;
}



.integration-box .img-wrraper {
  min-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.integration-box li {
  max-width: 220px;
  width: 100%;
  background-color: #000000;
  text-align: center;
  padding: 20px;
  margin: 0 12px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 40%), 0 0 20px 0 rgb(0 0 0 / 40%);
  display: block;
  color: #fff;
  cursor: pointer;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: .4s all;
}

.integration-box li:hover {
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: .4s all;
}


.integration-box ul li a {
  color: #fff;
}


.integration-sec {
  background: #171717;
  padding: 120px 0px 60px;
}


.my-connection {
  background: #171717;
  padding: 60px 0px;
}

.connection-box {
  background: #000000;
  padding: 20px;
  border-radius: 10px;
  scroll-margin: 80px 0 0 0;
}

.facebook-delete button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #fff;
}

.facebook-connection {
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
}

.facebook-connect-account button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #fff;
}

.facebook-connect-account button:hover {
  color: #2fcac9;
}

.facebook-connect-account button:focus {
  background: #fff;
  color: #2fcac9;
}

.facebook-delete button:hover {
  color: #2fcac9;
}

.facebook-delete button:focus {
  background: #fff;
  color: #2fcac9;
}

.youtube-connect button:hover {
  color: #2fcac9 !important;
}

.youtube-connect button:focus {
  background: #fff;
  color: #2fcac9;
}

.youbtube-connection .youtube-connect button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #fff;
}

.webinar-sec {
  padding: 60px 0px;
  /*background: url('../images/webnibeimg.png') 50%/cover no-repeat;*/


}

.webiner-content span {
  color: #fff;
}

.webiner-content h3 {
  font-size: 30px;
  font-family: 'Montserrat', sans-serif;
  font-family: var(--Montserrat);
  color: #fff;
}

.webiner-content h3 span {
  color: #F7FF00;
}


.webiner-content p {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  color: #fff;
}

/* 
.webiner-content button{
  background:linear-gradient( to right, #00C2FF ,#00FFC2);
  border-radius: 30px;
  padding: 10px 30px;
  border:none;
  color:#000;
  font-weight: 600;
}

.webiner-content button:hover{
  color: #00b889 !important;
} */

.warraper {
  min-height: 100vh;
  background: #171717;
}


.upgrads-sec {
  padding: 120px 0px 60px;
  background: #171717;
  min-height: 100vh;
}


.upgrade-menu {
  background: #000000;
  border-radius: 10px;
  overflow: hidden;
}

.upgrade-menu .nav .nav-link {
  color: #fff;
  padding: 10px 40px;

}

.upgrade-menu .nav .nav-link.active {
  background-color: #00BBAE;

}

.upgrade-content {
  background-color: #000000;
  padding: 20px 30px;
  min-height: 50vh;
}

.upgrade-box-content {
  background-color: #171717;
  border-radius: 10px;
  overflow: hidden;
}

.upgrade-heading h6 {
  background: #00BBAE;
  padding: 10px;
}

.upgrade-para {
  padding: 10px;
}

.upgrade-box-content button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  padding: 7px 30px;
  border-radius: 30px;
  border: none;
  color: #fff;
}


.upgrade-para button {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.upgrade-para button:hover {
  color: #2fcac9;

}

.upgrade-para button:focus {
  background: #fff !important;
  color: #2fcac9 !important;
}

.upgrade-box-content button:hover {
  color: #2fcac9;
}

.upgrade-box-content button:focus {
  background: #fff !important;
  color: #2fcac9;
}

.tutorial-content .upgrade button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  padding: 10px 30px;
  border: none;
  color: #fff;
  border-radius: 30px;

}

.tutorial-content .upgrade button {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 257px;
}

.tutorial-content .upgrade button:hover {
  color: #2fcac9;
}

.tutorial-content .upgrade button:focus {
  background: #fff;
  color: #2fcac9;
}


.modal .ecommercebox {
  max-width: 600px !important;
}


.ecommercebox .modal-body .form-group {
  width: 80%;
  margin: auto;
}

.ecommercebox .modal-body .modal-scrollbar {
  display: flex;
  align-items: center;
  justify-content: center;
}


.ecommercebox .modal-body .modal-scrollbar .form-group input {
  background: #1E1E1E;
  height: 50px;
}

.ecommercebox .modal-body .modal-scrollbar .form-group input:focus {
  border-color: #01D084;
  box-shadow: none;
}

.ecommercebox .modal-body .modal-scrollbar .form-group .form-control {
  color: #fff;
}

.ecommercebox .modal-body .modal-scrollbar {
  height: 40vh;
}



.ecommercebox .modal-body .modal-scrollbar .form-group button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
  color: #fff;
}

.ecommercebox .modal-body .modal-scrollbar .form-group button:hover {
  color: #2fcac9;
}

.ecommercebox .modal-body .modal-scrollbar .form-group button:focus {
  background: #fff;
  color: #2fcac9;
}

.hide-table-padding .form-group {
  background-color: #1E1E1E;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #1E1E1E;
}

.hide-table-padding .form-group:focus-within {
  border-color: #01D084;
}

.hide-table-padding .form-group label {
  font-size: 14px;
  color: #ffffffbd;
}

.brandonfocus:focus-within {
  border-color: #01D084;
  box-shadow: none;
}

.hide-table-padding .form-group input {
  background-color: transparent;
  border: none;
  padding: 0px;
}

.hide-table-padding .form-group input:focus {
  box-shadow: none;
}

.hide-table-padding .form-group .form-control {
  color: #fff !important;
  font-weight: 200;
}

.hide-table-padding ::-webkit-input-placeholder {
  color: #6c757d;
}

.hide-table-padding ::placeholder {
  color: #6c757d;
}


.template-cover {
  background-image: linear-gradient(to bottom, #00FDC3, #2DA8C7);
  border-radius: 10px;
  padding-top: 1px;
}

.template-cover .template-img {
  border-radius: 10px;
  position: relative;
  margin: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 50%);
}

.template-time {
  position: absolute;
  background-color: #fff;
  padding: 1px 10px;
  color: #000;
  top: 5px;
  left: 5px;
  display: inline-block;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
}

.template-names {
  padding: 0px 0px 5px;
}

.template-img:hover .ClipsReel-overlay {
  opacity: 1 !important;
  transition: .8s ease-in-out !important;
  -webkit-clip-path: circle(70.7% at 50% 50%) !important;
          clip-path: circle(70.7% at 50% 50%) !important;
}


/** Custom */
.my-account-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #16d489;
}

.hide-social {
  display: none !important;
}


.sweet-alert {
  color: #0C0C0C !important;
}

.modal {
  z-index: 99999;
}

.show-modal {
  display: block !important;
}

.load-icon-help {
  color: #02c98c;
  font-size: 30px;
}

.webinar-sec {
  padding: 60px 0px;
  background: url(/static/media/webnibeimg.9f9d56cc.png) 50%/cover no-repeat;


}

.webiner-content span {
  color: #fff;
}

.webiner-content h3 {
  font-size: 30px;
  font-family: 'Montserrat', sans-serif;
  font-family: var(--Montserrat);
  color: #fff;
}

.webiner-content h3 span {
  color: #F7FF00;
}


.webiner-content p {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  color: #fff;
}

/* .webiner-content button{
  background:linear-gradient( to right, #00C2FF ,#00FFC2);
  border-radius: 30px;
  padding: 10px 30px;
  border:none;
  color:#000;
  font-weight: 600;
}

.webiner-content button:hover{
  color: #00b889 !important;
} */

.accnt-mngmt-btn:hover {
  color: #00b889 !important;
}

.upload-btn-wrapper .custom-file-input {
  position: absolute;
  top: 0;
}


.script-sec {
  padding: 120px 0px 60px;
  background-color: #262626;
  min-height: 100vh;
}

.script-sec .form-group label {
  color: #fff;
}

.script-sec .form-group input {
  background-color: #0D0D0D;
  height: 49px;
}

.script-sec .form-group input:focus {
  border-color: #00FFB1;
  box-shadow: none;
}

.script-sec .form-group textarea {
  background-color: #0D0D0D;
  border-color: #707070;

}

.script-sec .form-group textarea:focus {
  border-color: #00FFB1;
  box-shadow: none;
}

.script-sec .form-group .form-control {
  color: #fff;
}

.script-create-btn button {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  color: #fff;
  border: none;
  padding: 10px 30px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-weight: 500;
  border-radius: 5px;
}

.script-create-btn a {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  color: #fff;
  border: none;
  padding: 10px 30px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-weight: 500;
  border-radius: 5px;
}

.script-create-btn a:hover {
  color: #2fcac9;
}


.script-create-btn button:hover {
  color: #2fcac9;
}


.secarchicone-part {
  position: relative;
  -webkit-animation: mymove 3s;
          animation: mymove 3s;
  -webkit-animation-iteration-count: 5;
          animation-iteration-count: 5;
}

@-webkit-keyframes mymove {
  from {
    -webkit-transform: scale(0.09);
            transform: scale(0.09);
  }

  to {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);

  }
}

@keyframes mymove {
  from {
    -webkit-transform: scale(0.09);
            transform: scale(0.09);
  }

  to {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);

  }
}


.fb-card .youtubeicon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-60%, -30%);
          transform: translate(-60%, -30%);
  font-size: 30px;

}


.script-create-btn button:focus-within {
  color: #2fcac9 !important;
  background: #fff !important;
}

.active-dim {
  border: 1px solid #00f5a0;
}

.active-slide {
  border-color: #00f5a0 !important;
}

.modal-backdrop {
  position: unset !important;
}

.cursor-pointer {
  cursor: pointer;
}

.hide-box {
  display: none !important;
}

.story-loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.preview-text {
  position: absolute;
  bottom: 0;
  padding: 20px;
}

/*color picker*/
.swatch {
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
  display: inline-block;
  cursor: pointer;
  width: 100%;
  padding: 10px;
}

.swatch-color {
  width: 100%;
  height: 14px;
  border-radius: 2px;
}

.swatch-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.sketch-picker {
  position: absolute;
  z-index: 999;
}

.templateModel {
  margin: auto;
  max-width: 450px;
  height: auto;
}


.templateModel .modal-body {
  height: auto !important;
  box-shadow: 0 0 5px rgba(255, 255, 255, .1);
}

.btn-custom {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  color: #fff;
  border: none;
  padding: 10px 30px;
  font-family: 'Poppins', sans-serif;
  font-family: var(--poppins);
  font-weight: 500;
  border-radius: 5px;
}

.btn-custom:hover {
  color: #2fcac9;
}

.preview-text span div {
  display: inline;
}

.highlight-text {
  background-color: yellow;
}


.search-load-box ::-webkit-scrollbar {
  width: 5px;
}

.search-load-box ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.search-load-box ::-webkit-scrollbar-thumb {
  background: #00F5A0;
  border-radius: 10px;
  height: 10vh !important;
}

.scroll-bar ::-webkit-scrollbar {
  width: 5px;
}

.scroll-bar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.scroll-bar ::-webkit-scrollbar-thumb {
  background: #00F5A0;
  border-radius: 10px;
  height: 10vh !important;
}

/* Infinite scroll */
.infinite-scroll-component {
  display: flex;
  flex-wrap: wrap;
}


.slide-active {
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #10ca9c;
}

.slide-active i {
  color: #10CA9C;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.loader-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.delete-media {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  -webkit-filter: drop-shadow(2px 4px 6px black);
          filter: drop-shadow(2px 4px 6px black);
  width: unset !important;
  height: unset !important;
}

.delete-media svg {
  position: absolute;
  right: 5px;
  top: 5px;
}

.delete-media i {
  color: #d20424;
}

.disable-click {
  pointer-events: none
}

.custom-anchor {
  text-decoration: underline !important;
  cursor: pointer;
}



/* Modified */
.dashborad-sec-3 {
  background-color: #15191e;
}

.dashborad-sec-3-content h6 {
  color: #FFAA00;
}

.dashborad-sec-3-content h4 {
  font-size: 30px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #FFF;
}

.dashborad-sec-3-content h4:hover {
  color: #FF8F4B;
  font-weight: 400;
}

.banner-video-project .video-project h5 {
  color: #FFF;
}

.banner-video-project .video-project h5 span {
  color: #FFAA00;
}

header .dropdown-item {
  font-size: 15px;
  color: #000;
}

header .dropdown-item:hover {
  color: #FFF;
}

.profile-sec {
  background: #171717;
}

.form-box {
  background: #000;
}

.form-box .form-group {
  background-color: #1e1e1e;
  color: #fff;
  padding: 5px 20px;
  border-radius: 10px;
  border: 1px solid #707070;
}

.form-box .form-group.forSelect {
  padding-left: 0;
  padding-right: 0;
}

.form-box .form-group.forSelect select {
  padding-left: 15px;
  padding-right: 15px;
}

.form-box .form-group .form-control {
  color: #fff !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  font-size: 14px;
}

.text-muted {
  color: #b1b1b1 !important;
  font-size: 14px;
}

.personal-details-box h6 {
  color: #FFF;
}

.footer .footer-menu ul li a:hover {
  color: #FF8F4B;
}

.manage-data-box p {
  font-size: 16px;
}

.manage-update-btn button {
  font-size: 16px;
}

.upgrade-menu .nav .nav-link {
  font-size: 16px;
}

.upgrade-para {
  font-size: 16px;
}

.upgrade-content {
  min-height: calc(100vh - 220px);
}

.upgrade-heading h6 {
  background: #FF8F4B;
}

.upgrade-menu .nav .nav-link {
  padding-top: 13px;
  padding-bottom: 13px;
}

.upgrade-menu .nav .nav-link:hover {
  background: #FF8F4B;
}

.upgrade-menu .nav .nav-link.active {
  background: #FF8F4B;
}

.upgrads-sec {
  font-size: 16px;
}

.upgrade-box-content button {
  background: linear-gradient(to right, #FF8F4B, #FF3D38);
}

.upgrade-box-content button:hover {
  color: #FF3D38 !important;
}

.tutorial-content .upgrade button {
  background: linear-gradient(to right, #FF8F4B, #FF3D38);
}

.tutorial-content .upgrade button:hover {
  color: #FF3D38;
}

.upgrade-box-content.alt {
  background: none;
}

.ibox-content .btn-primary {
  background-color: #FF8F4B !important;
  border-color: #FF8F4B !important;
}

.facebook-connect-account button {
  background: linear-gradient(to right, #FF8F4B, #FF3D38);
}

.facebook-connect-account button:hover {
  color: #FF3D38;
}

.add-account-save-btn button {
  background: linear-gradient(to right, #FF8F4B, #FF3D38);
}

.add-account-save-btn button:hover {
  color: #FF3D38;
}

.toggleupdate {
  background: linear-gradient(to right, #FF8F4B, #FF3D38);
}

.toggleupdate:hover {
  color: #FF3D38;
}

.add-account-sec input:-webkit-autofill,
.add-account-sec input:-webkit-autofill:hover,
.add-account-sec input:-webkit-autofill:focus,
.add-account-sec input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #1E1E1E inset !important;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.8) !important;
  border: none !important;
  border-radius: 0 !important;
}

.project-input-box {
  margin-right: 0 !important;
}

.my-video-projects-box-details .video-play {
  display: none !important;
}

.video-title {
  margin-left: 0;
  text-align: center !important;
}

.card-box-overlay .video-dropdown.dropdown {
  display: none !important;
}

.my-video-projects .card-box-date {
  text-align: center !important;
}

.my-video-projects .card-box-edit-more li a:hover {
  color: #FFB300 !important;
}

.my-video-projects .card-box-edit-more li a:hover i {
  color: #FFB300 !important;
}

.integration-box li p {
  font-size: 16px;
}

.my-connection,
.integration-sec {
  font-size: 16px;
}

.my-video-projects .card {
  border: none !important;
}

.banner-video-project .video-project a span {
  color: #FFF;
  font-size: 20px;
}

.banner-video-project .video-project a:hover span {
  color: #FF8F4B !important;
}

.text-white a {
  color: #FFF;
}

.popular-topics-box .nav .nav-link {
  font-size: 17px;
}

.popular-topics-box .nav .nav-link.active {
  background: #FF8F4B;
}

.popular-topics-content .vidoebtn {
  font-size: 16px;
  background: linear-gradient(to right, #FF8F4B, #FF3D38);
}

.popular-topics-content .vidoebtn:hover {
  color: #FF3D38;
}

.integration-box .img-wrraper img {
  transition: all 0.5s ease;
}

/* .integration-box li:hover .img-wrraper img{ filter: grayscale(0%);} */
/*.connection-box .connection-img img{ filter: grayscale(100%);}*/
/*.connection-box .youtube-logo img{ filter: grayscale(100%);}*/
.imgsizeright {
  margin-left: -200px;
}

.banner-video-project:before {
  background-position: center center;
}

.container {
  max-width: 1400px;
}

.upgrade-content {
  min-height: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.footer-copyright p a {
  color: #ff9100 !important;
}


.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

@-webkit-keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.fileLoad {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.95);
}

#v-pills-client img {
  width: auto !important;
  max-width: 100% !important;
}

/* body{ padding-top: 65px !important;} */
.integration-box li {
  transition: all 0.9s ease;
}

/* .integration-box li:hover{ 
  background: linear-gradient(180deg,#ff8d4b,#ff4139);
  box-shadow: 0 0 20px 0 #ff8649, 0 0 20px 0 #ff4c3b;
  transform: scale(1.1);
} */


body {
  padding-top: 60px;
}

.help-support-banner {
  margin-top: 0;
}

.profile-sec {
  padding-top: 60px;
}

.gdpr-sec {
  padding-top: 1px;
}

.footer .footer-menu ul li a:hover {
  color: blue;
}

.footer-copyright a {
  color: #00F5A0;
}

/* .integration-box li:hover{ background: linear-gradient( 180deg ,#FF8F4B,#FF3D38); box-shadow: 0 0 20px 0 #FF8F4B, 0 0 20px 0 #FF3D38; transform: scale(1.1) !important;} */
.user-img .custom-file {
  z-index: 999;
}

.imgEdit {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

.user-img:hover .imgEdit {
  opacity: 1;
  visibility: visible;
}

.imgsizeright {
  margin-left: -230px;
}

.commonModal {
  background: rgba(0, 0, 0, 0.5);
}

.commonModal .modal-content {
  background: none !important;
  border: none !important;
}

.commonModal .modal-body {
  background: #000;
  overflow: hidden;
  border-radius: 20px;
  padding: 25px;
}

.commonModal .embed-responsive {
  width: 100%;
  height: 0;
  padding-bottom: 56%;
  position: relative;
  aspect-ratio: 16 / 9;
}

.commonModal .embed-responsive iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}

.commonModal .modal-dialog {
  max-width: 1130px !important;
}

.commonModal .close {
  position: absolute;
  right: 0;
  top: -42px;
  opacity: 1 !important;
}

.modal {
  display: block !important;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

.modal.show-modal {
  opacity: 1;
  visibility: visible;
}

.commonModal .modal-dialog {
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: all 0.5s ease;
}

.commonModal.show-modal .modal-dialog {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.site-nav>ul>li {
  float: left;
  position: relative;
}

.site-nav>ul>li>a {
  display: inline-block;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1em;
  color: #2c2c2c;
  padding: 30px 30px 17px 30px;
}

.site-nav>ul>li:hover>a,
.site-nav>ul>li>a.active {
  background: #023e87;
  color: #FFF;
}

.site-nav>ul>li.has-sub>a {
  padding-right: 34px;
}

.subDropAlt {
  position: absolute;
  right: 15px;
  top: 26px;
}

.subDropAlt::before {
  content: '\f0d7';
  font-family: FontAwesome;
  color: #000;
  font-size: 1.2em;
}

.site-nav>ul>li:hover>ul {
  opacity: 1;
  visibility: visible;
  top: 70px;
}

.site-nav>ul>li:hover>.subDropAlt::before,
.site-nav>ul>li.active .subDropAlt::before {
  color: #FFF;
}

.site-nav>ul>li ul {
  position: absolute;
  left: 0;
  top: 75px;
  z-index: 99;
  background: #023e87;
  width: 200px;
  min-width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

.site-nav>ul>li ul li a {
  color: #FFF;
  font-size: 0.85em;
  padding: 10px 20px;
  padding-top: 12px;
  display: block;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.site-nav>ul>li>ul>li:hover>a {
  background: #f73131;
  color: #FFF;
}

.site-nav>ul>li ul li .subDropAlt {
  top: 8px;
}

.site-nav>ul>li ul li .subDropAlt::before {
  color: #FFF !important;
}

.site-nav>ul>li ul li:hover>.subDropAlt::before {
  color: #FFF;
}

.site-nav>ul>li ul li {
  position: relative;
}

.site-nav>ul>li ul li ul {
  top: 0;
  left: 100%;
  background: #1e47b4;
}

.site-nav>ul>li>ul>li:hover>ul {
  opacity: 1;
  visibility: visible;
  top: 0;
}

.site-nav>ul>li ul li ul li a:hover {
  background: #f73131;
  color: #FFF;
}

.switch input {
  opacity: 0;
}

.backgroundCanvas {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}

.scale-video video {
  z-index: 2;
}

.logoCanvas {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 4;
}

.overlayCanvas {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 3;
}

/* .scale-video canvas{ width: 100% !important; height: 100% !important;} */

.preview-bg {
  background-color: #202020
}

.vidPre5 {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.vidPre5 .vidPre5-left {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0;
  top: 0;
}

.vidPre5 .vidPre5-left.screen {
  left: 50%;
  right: 0;
}

.vidPre5 .vidPre5-left.screen.right-top {
  height: 50%;
}

.vidPre5 .vidPre5-left.screen.right-bottom {
  height: 50%;
  top: 50%;
}

.go-live-box-footer-row ul li {
  position: relative;
}

.addStbtn {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

.addStbtn button {
  background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%);
  color: #FFF;
  border: none !important;
  border-radius: 50px;
  font-size: 13px;
  padding-bottom: 2px;
  padding-left: 12px;
  padding-right: 12px;
}

.go-live-box-footer-row ul li:hover .addStbtn {
  opacity: 1;
  visibility: visible;
}

.commonModal-sm .modal-dialog {
  max-width: 600px !important;
}

.box-form-control .btn.popBtn {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.popTitle {
  font-size: 20px;
  margin: 0 0 15px 0;
  padding: 0 0 15px 0;
  border-bottom: 1px solid #565656;
}

.hh {
  text-decoration: none;
}

.content-box h6 {
  position: relative;
}

.downBtn {
  position: absolute;
  right: 0;
  top: 0;
  color: #9BF5FF !important;
}

.downBtn svg {
  color: #9BF5FF;
}

/* .footerEdit .row{ align-items: center; justify-content: center;}
.footerEdit [class*='col-']{ -ms-flex-preferred-size: 0 !important;
  flex-basis: 0 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  max-width: 100% !important; } */

@media screen and (max-height: 800px) {
  .commonModal .modal-dialog {
    max-width: 940px !important;
  }

  .commonModal-sm .modal-dialog {
    max-width: 600px !important;
  }
}


.add-account-sec {
  padding-top: 1px;
}

.upgrads-sec {
  padding-top: 40px;
}

.support-data {
  font-size: 16px;
  padding-bottom: 20px;
}

.project-banner {
  padding-top: 40px;
}

.logoimg {
  margin-top: -60px;
}


@media (max-width: 1600px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 1699px) {
  .imgsizeleft {
    margin: 0 !important;
    padding: 0 !important;
  }

  .imgsizeleft img {
    width: 100% !important;
    max-width: 100% !important;
  }

  .imgsizeright {
    margin-left: 0 !important;
  }

  .imgsizeright img {
    width: 100% !important;
    max-width: 100% !important;
  }

  .banner-content h1 {
    font-size: 60px;
  }

  .banner-content h4 {
    font-size: 30px;
  }

  .footer .footer-copyright p {
    font-size: 14px;
  }

  .footer .footer-menu ul li a {
    font-size: 14px;
  }
}

@media (max-width: 1199px) {
  .container {
    max-width: 960px;
  }

  .login-form h4 {
    font-size: 23px;
  }

  .login-form {
    padding: 35px;
  }

  .login-content h1 {
    font-size: 45px !important;
  }

  .login-content p {
    font-size: 15px;
  }

  .login-content h5 {
    font-size: 23px;
  }

  .login-form label {
    font-size: 17px;
  }

  .footer .footer-copyright p {
    font-size: 13px;
  }

  .footer .footer-menu ul li a {
    font-size: 13px;
  }

  .banner-content h1 {
    font-size: 50px;
  }

  .banner-content h4 {
    font-size: 30px;
  }

  .banner-content p {
    font-size: 15px;
    width: 100%;
  }

  .banner-video-project .video-project h5 {
    font-size: 35px;
  }

  .dashborad-sec-3-content h4 {
    font-size: 25px;
  }

  .dashborad-sec-3-content h6 {
    font-size: 16px;
  }

  .dashborad-sec-3-content p {
    font-size: 15px;
  }

  .webiner-content h3 {
    font-size: 25px;
  }

  .webiner-content p {
    font-size: 15px;
  }

  header .navbar ul li a {
    font-size: 14px;
  }

  header .navbar ul li {
    margin-right: 20px;
  }

  .banner-content h1 {
    font-size: 45px;
  }

  .banner-content h4 {
    font-size: 25px;
  }

  .banner-content p {
    font-size: 15px;
  }

  .banner-video-project .video-project h5 {
    font-size: 35px;
  }

  .dashborad-sec-3-content h4 {
    font-size: 25px;
  }

  .dashborad-sec-3-content h6 {
    font-size: 16px;
  }

  .dashborad-sec-3-content p {
    font-size: 15px;
  }

  .webiner-content p {
    font-size: 15px;
  }

  .webiner-content h3 {
    font-size: 25px;
  }

  .footer .footer-copyright p {
    font-size: 13px;
  }

  .footer .footer-menu ul li a {
    font-size: 13px;
  }

  .integration-sec {
    padding-top: 60px;
  }

  .help-banner-content h3 {
    font-size: 25px;
  }

  .secarchicone-part #searchimg {
    left: 0;
  }

  .popular-topics-box .nav .nav-link {
    padding-left: 20px;
  }

  .footer .footer-copyright p {
    font-size: 11px;
  }

  .footer .footer-menu ul li a {
    font-size: 11px;
  }
}

@media (max-width: 1023px) {
  .container {
    max-width: 720px;
  }

  .login-content {
    width: 100%;
  }

  .footer-copyright {
    padding: 8px 0;
  }

  .webinar-sec .banner-content {
    padding: 0;
    padding-bottom: 35px;
  }

  header .navbar .navbar-nav .nav-link {
    padding: 15px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  /* header .navbar{ height: 100vh;} */
  header .navbar .navbar-nav {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 20px;
  }

  header .dropdown {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .upgrade-menu .nav .nav-link {
    padding-left: 15px;
    padding-right: 15px;
  }


  .footer-copyright {
    padding: 8px 0;
  }

  .navbar-collapse {
    position: fixed;
    left: 0;
    top: 66px;
    width: 100%;
    height: 100%;
    background: #000;
    padding: 30px;
    padding-top: 0;
  }

  header .navbar ul li {
    margin-right: 0;
  }

  header .navbar .navbar-nav .nav-link {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  header .dropdown button {
    padding: 15px 0;
  }

  header .dropdown-menu {
    position: static !important;
    width: 100%;
  }

  .traning-sec .col-md-4,
  .traning-sec .col-md-8 {
    width: 100%;
  }

  .popular-topics-content {
    min-height: 10px;
    margin-top: 30px;
  }

  .col-lg-3.d-flex.align-items-center {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .login-form label {
    font-size: 15px;
  }

  #banner {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }

  #banner .banner-content {
    text-align: center !important;
  }

  #banner .watch-demo-btn {
    text-align: center !important;
    display: inline-block;
    margin: 0 !important;
    padding: 0;
  }

  .banner-video-project .video-project h5 {
    font-size: 30px !important;
  }

  .webinar-img {
    padding: 15px;
  }

  .connection-box {
    text-align: center !important;
  }

  .connection-box .text-white {
    display: block !important;
    padding: 20px 0 0 0 !important;
  }

  .upgrads-sec {
    padding-top: 40px;
  }

  .upgrade-content {
    padding: 20px;
    height: auto;
    min-height: 50px;
  }

  .upgrade-box-content {
    margin-bottom: 30px;
  }

  .popular-topics-content {
    padding-top: 60px;
  }

  .banner-content h1 {
    font-size: 35px;
  }

  .banner-content h4 {
    font-size: 20px;
  }

  .banner-items {
    padding: 0 0 30px 0 !important;
  }

  .banner-content {
    padding: 20px 0 !important;
    text-align: center !important;
  }

  .help-support-banner {
    padding-top: 60px;
  }

  .add-account-box,
  .team-member-box {
    padding: 15px;
  }

  .team-member-box table tr td,
  .team-member-box table tr th {
    white-space: nowrap;
  }
}

/* Modified */


#v-pills-client img {
  width: 100%;
}

.support-data {
  color: #FFFFFF;
}

.support-data a {
  text-decoration: none;
  color: #FFFFFF;
}

.disable-img img {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.active-help {
  color: #FF8F4B !important;
}

.thumb-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2;
}

/*color picker*/
.swatch {
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
  display: inline-block;
  cursor: pointer;
  width: 100%;
  padding: 10px;
}

.swatch-color {
  width: 100%;
  height: 14px;
  border-radius: 2px;
}

.swatch-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.sketch-picker {
  position: absolute;
  z-index: 999;
}

/* end Color picker */

.hidden-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
}

/* Adi Css */
.img-left-absolute {
  position: absolute;
  top: 0;
  left: 0;
}

.img-right-absolute {
  position: absolute;
  top: 0;
  right: 0;
}

.react-calendar {
  width: 100% !important;
}


/* for video training text color */
.text-white p span {
  color: #ffffff !important;
}

.broadcast-content-box .active .circle-img-select {
  opacity: 1 !important;
}

.btn-change-8{
  background: none !important;
  border: none;
  color: #FF8F4B !important;
}
.btn-change-8 img{
  width: 225px;
}



@charset "UTF-8";
@font-face {
  font-family: 'segoeui-regular';
  src: url(/static/media/segoeui.cf788957.eot);
  src: url(/static/media/segoeui.cf788957.eot) format("embedded-opentype"), url(/static/media/segoeui.cf6aefb8.woff2) format("woff2"), url(/static/media/segoeui.7d74daf8.woff) format("woff"), url(/static/media/segoeui.2a5f2a0e.ttf) format("truetype"), url(/static/media/segoeui.5e8888c2.svg#segoeui) format("svg");
}

@font-face {
  font-family: 'seguibl';
  src: url(/static/media/seguibl.afbf74bd.eot);
  src: url(/static/media/seguibl.afbf74bd.eot) format("embedded-opentype"), url(/static/media/seguibl.2c159e0f.woff2) format("woff2"), url(/static/media/seguibl.62eb681f.woff) format("woff"), url(/static/media/seguibl.0f07ffc9.ttf) format("truetype"), url(/static/media/seguibl.40da1205.svg#seguibl) format("svg");
}

.font1{ font-family: 'Montserrat', sans-serif;}
.font2{ font-family: 'Poppins', sans-serif;}

/* body{ font-family: 'Poppins', sans-serif;}
h1,h2,h3,h4,h5,h6{ font-family: 'Montserrat', sans-serif;} */

.btn-blue {
  background-color: #5278D5;
  color: #fff;
}

.btn-blue:hover {
  color: #fff;
}

.btn-hide-chat {
  background: #C8AED6;
  border-radius: 12px;
  color: #fff;
  font-weight: 500;
}

.btn-hide-chat .icon {
  color: #fff;
  margin-left: 0px;
  margin-right: 8px;
}

.btn-reply {
  background-color: #009FFF;
  color: #fff;
  border-radius: 50px;
}

.btn-admit {
  background: #5278D5;
  border: #5278D5;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
}

.btn-unban {
  background: #FF2D3B;
  border: #FF2D3B;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
}

.btn-dark-purple {
  background-color: #8A5CA8;
  border: #8A5CA8;
  font-size: 14px;
}

.btn-dark-purple:hover {
  background-color: #8A5CA8;
  border: #8A5CA8;
  opacity: .7;
}

.btn-dark-purple:active {
  background-color: #8A5CA8;
  border: #8A5CA8;
  opacity: .7;
}

.btn-dark-purple:focus {
  background-color: #8A5CA8;
  border: #8A5CA8;
  opacity: .7;
}

button.close {
  color: #FFB1B1 !important;
}

button.close span {
  font-size: 25px;
}

body {
  /* font-family: 'Montserrat', sans-serif; */
  font-size: 14px;
}

p.title {
  font-size: 14px;
  font-weight: 600;
}

#banner {
  padding: 220px 0px 120px 0px;
}

.s-bold {
  font-weight: 600;
}

.comp-logo {
  width: 210px;
  height: 50px;
  object-fit: contain;
}

.btn-submit {
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  margin: 0 auto;
  border-radius: 8px !important;
}

.border-15 {
  border-radius: 15px !important;
}

.border-50 {
  border-radius: 50px !important;
}

.btn-bundle {
  padding: 10px 30px;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.btn-bundle h6 {
  margin-bottom: 0;
}

.btn-link {
  text-decoration: none !important;
}

.play-dropdown-button.dropdown button {
  width: 100%;
  background-position: right center;
  text-align: left;
  border-radius: 8px;
  padding: 10px;
}

.play-dropdown-button.dropdown button:after {
  position: absolute;
  right: 10px;
  top: 18px;
}

.play-dropdown-button.dropdown .show {
  width: 100%;
}

.btn-bundle.primary-gradient {
  background: linear-gradient(90deg, #FF8D4B, #FF4139);
}

.btn-xs-small {
  padding: 8px ​20px !important;
  font-size: 14px;
  font-weight: 500;
}

.btn-add-more {
  font-size: 12px;
  font-weight: 500;
}

.btn-add-more .icon {
  font-size: 15px;
}

.btn-gradient-orange {
  text-align: center;
  text-transform: capitalize;
  transition: 0.5s;
  background-size: 200% auto;
  color: #fff;
  border-radius: 10px;
  border-style: hidden;
  text-decoration: none;
}

.btn-gradient-orange:hover {
  color: #fff;
}

.btn-gradient-orange {
  background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%);
}

.view-all {
  font-weight: 400;
  font-size: 16px;
}

.view-all img {
  position: relative;
  right: -12px;
  top: -2px;
}

.play-icon {
  width: 25px;
}

.uppertext {
  text-transform: uppercase;
}

.watch-btn img {
  width: 15px;
  position: relative;
  left: -5px;
  top: -2px;
  margin-right: 5px;
}

.webinar-img {
  background: #f23d54;
  background: linear-gradient(90deg, #f23d54 0%, #feb960 100%);
  padding: 20px;
  border-radius: 20px;
}

.btn-submit {
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
}

.btn-more {
  position: absolute;
  right: 0;
  padding: 0;
  color: #fff;
  background-color: #B576E5;
  box-shadow: 1px 3px 11px 0px rgba(44, 43, 43, 0.75);
  -webkit-box-shadow: 1px 3px 11px 0px rgba(44, 43, 43, 0.75);
  -moz-box-shadow: 1px 3px 11px 0px rgba(44, 43, 43, 0.75);
}

.btn-show-hide {
  color: #fff;
  font-size: 12px;
  font-weight: 800;
}

.btn-show-hide .icon {
  top: 2px;
  position: relative;
}

/* .img-mac {
  width: 600px;
} */

.highlight.orange {
  color: #FF9100;
}

.btn-submit {
  width: auto;
  background: #EC5E5E;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
}

.help-banner-content .input-group {
  background-color: #fff;
  border-radius: 12px;
}

.important-sec h3 span {
  color: #B400DF;
}

.popular-video-sec h3 {
  background: linear-gradient(250deg, #D500FF, #AC00FF);
  display: inline-flex;
  border-radius: 50px;
  padding: 10px 15px;
  position: relative;
}

.popular-video-sec h3:before {
  content: '';
  background: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  position: relative;
  top: 6px;
  margin-right: 12px;
}

.my-video-projects .card {
  background: #AA4CD1;
  /* fallback for old browsers */
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #AA4CD1, #C13066);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border: 3px solid #fff;
  padding: 10px;
  border-radius: 15px;
}

.my-video-projects h2 {
  font-weight: 400;
  font-size: 40px !important;
}

.my-video-projects .view-all {
  float: right;
}

.my-video-projects .card .my-video-projects-box-details {
  display: inline-flex;
  padding: 20px 0px;
}

.video-play {
  background: #fff;
  padding: 4px 10px;
  border-radius: 50px;
}

.video-play img {
  width: 15px;
  height: 15px;
}

.video-title {
  width: 100%;
  background: #ffffff80;
  padding: 7px 15px;
  border-radius: 50px;
  /* margin-left: 14px; */
  color: #fff;
  font-weight: 600;
}

.video-title p {
  font-size: 14px;
}

.my-video-projects .card-img-top {
  border-radius: 15px;
}

.my-video-projects .card-box {
  position: relative;
}

.my-video-projects .card-box-edit-more {
  color: #fff;
  padding: 0px;
  margin-bottom: 0;
  width: 100%;
  text-align: center;
  margin-top: 25px;
}

.my-video-projects .card-box-edit-more li {
  display: inline-block;
  padding: 20px 5px;
}

.my-video-projects .card-box-edit-more li a {
  color: #fff;
  font-size: 12px;
  text-decoration: none;
}

.my-video-projects .card-box-edit-more li a:hover {
  color: yellow;
}

.my-video-projects .card-box-edit-more li a:hover i {
  color: yellow;
}

.my-video-projects .card-box-edit-more li a i {
  font-size: 18px;
}

.my-video-projects .card-box-edit-more li:first-child p{ -webkit-transform: translateX(-4px); transform: translateX(-4px);}

.my-video-projects .card-box-date {
  color: #fff;
  text-align: left;
  margin-top: 10px;
}

.my-video-projects .card-box-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  background: #0000005e;
  height: 100%;
  /* padding: 10px; */
  border-radius: 15px;
  transition: .5s ease-in-out;
  -webkit-clip-path: circle(90.3% at 100% 0);
  clip-path: circle(90.3% at 100% 0);
  opacity: 0;
  z-index: 3;
}

.my-video-projects .card:hover .card-box-overlay {
  opacity: 1;
  transition: .8s ease-in-out;
  -webkit-clip-path: circle(141.4% at 100% 0);
  clip-path: circle(141.4% at 100% 0);
}

.my-video-projects .card:hover .overlay {
  display: block;
}

.video-dropdown {
  position: absolute;
  right: 0px;
  top: 14px;
}

.video-dropdown button {
  border: 0px !important;
}

.video-dropdown button:focus {
  box-shadow: 0 0 0 0 !important;
}

.video-dropdown {
  border-radius: 50px;
  background: none !important;
}

.video-dropdown .btn-secondary {
  background: none !important;
  border: 0px !important;
  border-color: none !important;
}

.date {
  color: #fff;
}

.date p.date {
  position: absolute;
  width: 100%;
  background: #0000007a;
  height: 100%;
  top: 0;
  border-radius: 15px;
  padding: 10px 10px;
}

.position-list {
  padding: 0;
  margin-bottom: 0;
  text-align: center;
}

.position-list li {
  list-style: none;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  margin-right: 8px;
}

.position-list-box {
  width: 23px;
  height: 23px;
  background-color: #BB8ED9;
  margin-bottom: 7px;
}

.position-list-box.green {
  background-color: #00BF85;
}

.followUs {
  background-color: #BB8ED9;
  padding: 20px 10px;
  border-radius: 15px;
  position: relative;
}

.followUs p {
  margin-bottom: 0;
}

.followUs-social {
  font-size: 14px;
  font-weight: 800;
}

.followUs-Show {
  background-color: #9843D9;
  padding: 20px 10px;
  border-radius: 15px;
}

.followUs-Show-more {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
}

.followUs-Show-more-options {
  color: #fff;
}

.followUs-Show-more-options .btn-more {
  right: 15px;
  top: -10px;
}

.followUs-Show-more-options .btn-more:hover {
  color: #fff;
}

.followUs-Show p {
  margin-bottom: 0;
}

.content-box--banner .dropzone-wrapper {
  height: 75px;
}

.content-box--banner .dropzone-desc {
  font-size: 14px;
  top: 20px;
}

.content-box--banner .switch {
  width: 50px;
  height: 25px;
  margin-bottom: 0;
}

.content-box--banner .slider:before {
  height: 18px;
  width: 18px;
}

.content-box--banner .box-tools {
  top: 0;
}

.important-topics a {
  text-decoration: none;
}

.important-topics-content {
  background: linear-gradient(180deg, #72168D, #FF3E53);
  padding: 40px;
  border-radius: 15px;
  transition: .4s ease-in-out;
  height: 247px;
}

.important-topics-content h6 {
  font-weight: 800;
}

.important-topics span {
  width: 100px;
  height: 100px;
  background-color: #FF3E53;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto 20px;
  border: 2px solid #fff;
}

.important-topics span img {
  max-width: 50%;
}

.login-form .form-group {
  padding-left: 10px;
  border-radius: 10px;
}

.login-form .form-group .input-group {
  background-color: #fff;
  border-radius: 8px;
}

.login-form .form-group .input-group-prepend {
  position: relative;
}

.login-form .form-group .input-group-text {
  background-color: transparent;
  border: 0px;
  background-color: transparent;
  border-right: 1px solid #878787 !important;
  position: to;
  position: relative;
  top: 15px;
  height: 22px;
  bottom: 0;
}

.login-form .form-group .input-group-text svg {
  color: #878787;
}

.login-form .form-group .form-control {
  background-color: transparent;
  border: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

h2.title {
  font-family: 'segoeui-regular';
  font-size: 38px;
  text-transform: uppercase;
}

h2.title span {
  font-family: 'seguibl';
}

.gradiant-button-pb {
  background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%);
}

.gradiant-button-pb {
  margin: 0;
  padding: 12px 30px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 50px;
  display: block;
  border-style: hidden;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  border: 1px solid rgba(255,255,255,0);
}

.gradiant-button-pb:hover {
  background-position: 0!important;
  /* change the direction of the change here */
  color: #FF8F4B !important;
  text-decoration: none;
  box-shadow: inset 1000px 0 0 #fff;
  transition: .3s ease-in-out;
  border: 1px solid #FF3D38;
}

.gradiant-button-pb:active {
  background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%) !important;
  color: #fff;
  text-decoration: none;
}

.banner-content .watch-demo-btn button {
  /* background-image: linear-gradient(
90deg
,#00c2ff,#00e9d8,#00ffc2); */
  padding: 15px 58px;
  border-radius: 30px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins",sans-serif;
  font-family: var(--poppins);
  cursor: pointer;
  overflow: hidden;
}

.watch-demo-btn button:before {
  content: "";
  width: 0;
  height: 100%;
  background-color: #fff;
  top: 0;
  left: 0;
  position: absolute;
  border-radius: 30px;
  color: #00f5a0;
  transition: .3s ease-in-out;
  z-index: -1;
}

.watch-demo-btn button:hover:before {
  width: 100%;
  transition: .3s ease-in-out;
  border-radius: 30px;
}

.next {
  width: 90px;
  padding: 20px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.serv-box {
  padding: 10px 8px;
}

.more-about-services {
  float: right;
}

.more-about-services-list p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
  padding: 5px 25px;
  color: #fff;
  display: inline-block;
  line-height: 2.5em;
}

.more-about-services-list .next {
  background-color: #FF8A3B;
}

.serv-box {
  display: inline-flex;
}

.serv-icon {
  width: 60px;
}

.serv-icon img {
  width: 80px;
}

.user-row .dropdown-menu a:hover {
  background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%);
  color: #fff;
}

.user-row .dropdown-menu a span {
  margin-right: 5px;
}

.btn-st {
  display: inline-flex;
  vertical-align: middle;
  padding: 0px;
  width: 400px;
  position: relative;
  background: #ffffff82;
  border-radius: 10px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.btn-st:hover {
  background-color: #FF8A3B;
}

@media only screen and (max-width: 991px) {
  .btn-st {
    width: 100%;
  }
}

.btn-st:hover {
  color: #fff;
}

.btn-st:focus {
  box-shadow: none;
}

.btn-st .right-arrow {
  position: relative;
  top: 4px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 50px;
}

.btn-st img {
  padding: 0 10px;
}

.btn-gradient-one {
  background-image: linear-gradient(to right, #F16BBA 0%, #9C6DEA 51%, #9C6DEA 100%) !important;
  transition: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.btn-gradient-one:hover {
  background-image: linear-gradient(to right, #9C6DEA 0%, #FF5E5E 51%, #FF5E5E 100%) !important;
}

.btn-gradient-two {
  background-image: linear-gradient(to right, #9947A3 0%, #F16BBA 51%, #F16BBA 100%) !important;
  transition: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.btn-gradient-two:hover {
  background-image: linear-gradient(to right, #F16BBA 0%, #B4003B 51%, #B4003B 100%) !important;
}

.btn-gradient-three {
  background-image: linear-gradient(to right, #EA004E 0%, #AA0059 51%, #AA0059 100%) !important;
  transition: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.btn-gradient-three:hover {
  background-image: linear-gradient(to right, #AA0059 0%, #EA004E 51%, #EA004E 100%) !important;
}

.player-box p {
  margin-bottom: 0px;
  color: #000000;
}

.player-box-title {
  font-size: 13px;
}

.player-box-time {
  color: #9C9C9C !important;
}

.edit-page {
  padding-top: 66px;
  background-color: #fff;
}

.edit-page-tab {
  padding: 20px 0;
  height: 100%; 
}

.edit-page-box {
  background-color: #C7B1D8 !important;
  height: 100%;
}

.edit-page-box-tab {
  height: 100%;
  background-color: #9C81B1 !important;
  padding: 20px;
}

.edit-page-box-tab .nav-item {
  margin-bottom: 30px;
}

.edit-page-box-tab a {
  background-color: #BAA5C8 !important;
  border-radius: 15px !important;
  cursor: pointer;
  color: #fff;
  width: 105px;
  height: 105px;
  margin: 0 auto;
  display: table;
}

.edit-page-box-tab a:hover {
  background-color: #D8A5FF !important;
  color: #fff !important;
}

.edit-page-box-tab a.active {
  color: #fff;
  background-color: #D8A5FF !important;
}

.edit-page-box-tab a :focus {
  color: #fff !important;
}

/* .edit-page-box-tab-box {
  display: table-cell;
  vertical-align: middle;
} */

.edit-page-box-tab-box img {
  width: 40px;
  margin-bottom: 5px;
}

.edit-page-box-tab-box p {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 12px;
}

.content-box-position {
  padding: 18px 10px !important;
}

.schedule-later {
  background: #CD9FF1;
  border-left: 10px solid #A633FF;
}

.scheduleTime .btn {
  font-size: 14px;
  padding: 5px;
  margin-right: 5px;
  margin-left: 5px;
  width: 100%;
}

.scheduleTime.content-box--broadcast {
  overflow: hidden;
}

.content-box {
  color: #fff;
  padding: 20px;
}

.content-box--broadcast {
  color: #fff;
}

.content-box--broadcast .dropdown-toggle {
  background-color: #BB8ED9;
  border: #BB8ED9;
}

.content-box--broadcast .btn.today {
  background-color: #BB8ED9;
  border: #BB8ED9;
  width: 100%;
}

.content-box--connection h6 {
  font-weight: 600;
}

/* .content-box--private-chat, .content-box--comments {
  overflow: hidden;
} */

.content-box h6 {
  font-size: 16px;
  font-weight: 600;
}

.purple-box {
  background-color: #A479C5;
  border-radius: 15px;
}

span.icon {
  margin-left: 8px;
  font-size: 18px;
}

span.icon.skyblue {
  color: #9BF5FF;
}

span.icon.white {
  color: #fff;
}

span.icon.blue {
  color: #7899EB;
}

.paticipants-box {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.paticipants-box p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.3em;
  font-size: 14px;
  font-weight: 500;
}

.paticipants-box p-title {
  margin-left: 10px;
}

.paticipants-box-top {
  background-color: #8C40C8;
  padding: 5px 20px;
}

.paticipants-box-top .btn-link {
  color: #00FF66;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
}

.paticipants-box-body {
  color: #676767;
  padding: 10px 15px;
}

.paticipants-box-body-img {
  width: 35px;
  height: 35px;
  border-radius: 10px;
}

.paticipants-box-body-img.male {
  background-color: #B1DDFF;
}

.paticipants-box-body-img.female {
  background-color: #FFB5E4;
}

.paticipants-box-body p {
  margin-left: 8px;
}

.paticipants-box-body-options .icon.red {
  color: #FF5151;
}

.paticipants-box-body-options .icon.green {
  color: #00FFB2;
}

.img-library-box {
  border-radius: 20px;
  overflow: hidden;
}

.img-library #pills-tab {
  background: #8A5CA8;
  overflow: hidden;
}

.img-library #pills-tabContent {
  padding: 20px;
}

.search-bg-white {
  background: #fff;
  border-radius: 8px;
}

.search-bg-white .input-group-text {
  background: transparent;
  border: 0px;
  height: 100%;
}

.search-bg-white .input-group-text i {
  color: orange;
  font-size: 22px;
}

.search-bg-white .form-control {
  border: 0px solid;
  border-radius: 8px;
  padding: 13px 5px;
}

.search-bg-white .form-control:focus {
  box-shadow: 0 0 0 0 !important;
}

.img-library-box {
  height: auto;
}

.img-library-box #pills-tab .nav-link {
  background: #8A5CA8;
  border: 0px;
  color: #fff;
  padding: 12px 15px;
  width: 100%;
  border-radius: 0px;
}

.img-library-box #pills-tab .nav-link.active {
  color: #fff;
  border-bottom: 1px solid orange;
  border-radius: 0px;
}

.img-library-box #pills-tab li {
  width: 25%;
}

.img-library-box #pills-tab li:last-child {
  border-right: 0px;
}

.img-thumbnails {
  overflow-x: hidden;
}

.img-thumbnails::-webkit-scrollbar {
  width: 1em;
}

.img-thumbnails::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.img-thumbnails::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.img-thumbnails .img-thumbnail {
  background-color: transparent;
  border: 0px;
  padding: 0px;
}

.logo-row ul, .overlay-row ul, .background-row ul {
  padding: 0;
  margin: 0;
}

.logo-row ul li, .overlay-row ul li, .background-row ul li {
  display: inline-block;
}

.logo-row .logo-box, .overlay-row .logo-box, .background-row .logo-box {
  width: 60px;
  height: 60px;
  background: #BB8ED9;
  margin-right: 10px;
  display: table;
  overflow: hidden;
  border-radius: 10px;
}

.logo-row .logo-box-add-logo, .overlay-row .logo-box-add-logo, .background-row .logo-box-add-logo {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.logo-row .logo-box-img-logo, .overlay-row .logo-box-img-logo, .background-row .logo-box-img-logo {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.logo-row .overlay-box, .logo-row .background-box, .overlay-row .overlay-box, .overlay-row .background-box, .background-row .overlay-box, .background-row .background-box {
  width: 100px;
  height: 60px;
  background: #BB8ED9;
  margin-right: 10px;
  display: table;
  overflow: hidden;
  border-radius: 10px;
}

.logo-row .overlay-box-add-logo, .logo-row .background-box-add-logo, .overlay-row .overlay-box-add-logo, .overlay-row .background-box-add-logo, .background-row .overlay-box-add-logo, .background-row .background-box-add-logo {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.logo-row .overlay-box-img-logo, .logo-row .background-box-img-logo, .overlay-row .overlay-box-img-logo, .overlay-row .background-box-img-logo, .background-row .overlay-box-img-logo, .background-row .background-box-img-logo {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.social-media-group-box {
  border-radius: 15px;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 35px;
  color: #fff;
  width: 100%;
  overflow: hidden;
}

.social-media-group-box:hover {
  opacity: 8;
  color: #fff;
}

.social-media-group-box img {
  width: 35px;
  margin-right: 8px;
}

.social-media-group-box.facebook-group-box {
  background: #196BF2;
}

.social-media-group-box.youtube-group-box {
  background: #FF0001;
}

.social-media-group-box.twitch-group-box {
  background: #7C3AEC;
}

.social-media-group-box.periscope-group-box {
  background: #3999BF;
}

.social-media-group-box.linkedIn-group-box {
  background: #016CAB;
}

.social-media-group-box.custom-group-box {
  background: #000;
}

div#modalCamMic .modal-dialog {
  width: 700px;
  border-radius: 15px;
  overflow: hidden;
}

div#modalCamMic .modal-body {
  height: 600px;
}

div#modalCamMic .modal-content {
  overflow: hidden;
  border-radius: 20px;
}

div#modalCamMic .modal-content .nav-item {
  margin-right: 10px;
}

div#modalCamMic .modal-content .nav-link {
  background-color: #D0CDD2;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  border-radius: 10px;
}

div#modalCamMic .modal-content .nav-link:active, div#modalCamMic .modal-content .nav-link:hover, div#modalCamMic .modal-content .nav-link:focus {
  background-color: #CBABE3;
}

div#modalCamMic .modal-header {
  background: #F3F3F3;
}

div#modalCamMic .live-camera-options {
  width: 100%;
}

div#modalCamMic .live-camera-options button {
  width: 100%;
  text-align: left;
  background: #F8F8F8;
  color: #000;
  border: 1px solid #F8F8F8;
  position: relative;
}

div#modalCamMic .live-camera-options .dropdown-menu.show {
  width: 100%;
}

div#modalCamMic .live-camera-options .dropdown-toggle:after {
  right: 12px;
  position: absolute;
  top: 15px;
  display: inline-block;
  width: .5em;
  height: .5em;
  margin: -.3em 0 0 .4em;
  vertical-align: middle;
  content: "";
  border: .3em solid;
  border-width: 0 .15em .15em 0;
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
}

div#modalCamMic .live-camera-check .form-check-input {
  width: 30px;
  height: 30px;
}

div#modalCamMic .live-camera-check .form-check-input:checked {
  background-color: #7899EB;
  border-color: #7899EB;
}

div#modalCamMic .live-camera-check .form-check-input:focus {
  box-shadow: 0 0 0 0 !important;
}

div#modalCamMic .live-camera-check label {
  font-size: 20px;
  font-weight: 500;
}

.warning-box p {
  color: #828282;
}

.warning-box img {
  width: 80px;
}

.microphone-test ul {
  padding: 0;
  margin-bottom: 0;
}

.microphone-test ul li {
  list-style: none;
  display: inline-block;
  margin-right: 8px;
}

.microphone-test-volume {
  width: 14px;
  height: 10px;
  background: #CBCBCB;
  border-radius: 10px;
}

.microphone-test-volume.green {
  background-color: #00BF6C;
}

.modal-open .modal {
  background: #0e0e0ecc;
}

.broadcast-content .box-form-control {
  color: #fff;
}

.broadcast-content .box-form-control label {
  font-weight: 600;
}

.broadcast-content .box-form-control input, .broadcast-content .box-form-control textarea {
  background-color: #8A5CA8;
  border: 1px sold #fff;
  color: #fff;
}

.broadcast-content .box-form-control input::-webkit-input-placeholder, .broadcast-content .box-form-control textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}

.broadcast-content .box-form-control input::-moz-placeholder, .broadcast-content .box-form-control textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

.broadcast-content .box-form-control input:-ms-input-placeholder, .broadcast-content .box-form-control textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}

.broadcast-content .box-form-control input:-moz-placeholder, .broadcast-content .box-form-control textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

.broadcast-content .box-form-control input:focus, .broadcast-content .box-form-control textarea:focus {
  border-color: #fff;
  box-shadow: 0 0 0 0 !important;
}

.broadcast-content-box {
  padding: 0;
  margin: 0;
}

.broadcast-content-box li {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}

.broadcast-content-box-circle {
  width: 50px;
  height: 50px;
  background: #E9E9E9;
  border-radius: 50px;
}

.private-chat-content .box-form-control {
  color: #fff;
}

.private-chat-content .box-form-control label {
  font-weight: 600;
}

.private-chat-content .box-form-control input, .private-chat-content .box-form-control textarea {
  background-color: #8A5CA8;
  border: 1px sold #fff;
  color: #fff;
}

.private-chat-content .box-form-control input::-webkit-input-placeholder, .private-chat-content .box-form-control textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}

.private-chat-content .box-form-control input::-moz-placeholder, .private-chat-content .box-form-control textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

.private-chat-content .box-form-control input:-ms-input-placeholder, .private-chat-content .box-form-control textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}

.private-chat-content .box-form-control input:-moz-placeholder, .private-chat-content .box-form-control textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

.private-chat-content .box-form-control input:focus, .private-chat-content .box-form-control textarea:focus {
  border-color: #fff;
  box-shadow: 0 0 0 0 !important;
}

.private-chat-content-box {
  padding: 0;
  margin: 0;
}

.private-chat-content-box li {
  display: inline-block;
  margin-right: 10px;
}

.private-chat-content-box-circle {
  width: 50px;
  height: 50px;
  background: #E9E9E9;
  border-radius: 50px;
}

.private-chat-content-box--chat-room {
  height: 100%;
  min-height: 40px;
}

.private-chat-content-box--chat-conversation {
  min-height: 350px;
  padding: 20px;
}

.private-chat-content-box--chat-submit {
  background-color: #9463B9;
  padding: 20px;
}

input.purple {
  background-color: #CBABE3;
  border: #CBABE3;
  color: #fff;
}

input.purple::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}

input.purple::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

input.purple:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}

input.purple:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

input.purple:focus {
  background-color: #CBABE3;
  border-color: #fff;
  color: #fff;
  box-shadow: 0 0 0 0 !important;
}

.comments-content .box-form-control {
  color: #fff;
}

.comments-content .box-form-control label {
  font-weight: 600;
}

.comments-content .box-form-control input, .comments-content .box-form-control textarea {
  background-color: #8A5CA8;
  border: 1px sold #fff;
  color: #fff;
}

.comments-content .box-form-control input::-webkit-input-placeholder, .comments-content .box-form-control textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}

.comments-content .box-form-control input::-moz-placeholder, .comments-content .box-form-control textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

.comments-content .box-form-control input:-ms-input-placeholder, .comments-content .box-form-control textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}

.comments-content .box-form-control input:-moz-placeholder, .comments-content .box-form-control textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

.comments-content .box-form-control input:focus, .comments-content .box-form-control textarea:focus {
  border-color: #fff;
  box-shadow: 0 0 0 0 !important;
}

.comments-content-box {
  padding: 0;
  margin: 0;
}

.comments-content-box li {
  display: inline-block;
  margin-right: 10px;
}

.comments-content-box-circle {
  width: 50px;
  height: 50px;
  background: #E9E9E9;
  border-radius: 50px;
}

.comments-content-box-circle-img {
  width: 95px;
  background: #E9E9E9;
  border-radius: 50px;
  /* overflow: hidden; */
}

.comments-content-box--chat-room {
  height: 100%;
  min-height: 40px;
}

.comments-content-box--chat-conversation {
  min-height: 350px;
  padding: 20px;
}

.comments-content-box--chat-conversation-body-box {
  background: rebeccapurple;
  border-radius: 50px;
  padding: 10px;
}

.comments-content-box--chat-conversation-body-box p {
  margin-bottom: 0;
  font-size: 12px;
}

.comments-content-box--chat-submit {
  background-color: #9463B9;
  padding: 20px;
}

.comments-content-box--chat-submit .comments-content-box-circle-img {
  width: 52px;
}

.customization-color-picker .color-picker-box {
  padding: 3px 6px;
  border-radius: 5px;
  background-color: #8A5CA8;
  border: 2px solid #BEBEBE;
}

.customization-color-picker .color-picker-box-colors ul {
  padding: 0;
  margin: 0;
}

.customization-color-picker .color-picker-box-colors ul li {
  list-style: none;
  margin-right: 1px;
  display: inline-block;
}

.customization-color-picker .color-picker-box-colors .color-pick {
  height: 30px;
  width: 30px;
  background: transparent;
  border-radius: 2px;
}

.customization-color-picker .color-picker-box p {
  margin-bottom: 0px;
  font-size: 15px;
}

.customization-color-picker .color-picker-box .color-picker {
  border-radius: 2px;
  width: 30px;
  height: 30px;
}

.customization-color-picker .color-picker-box .color-picker.white {
  background: #fff;
}

.customization-color-picker input {
  border-radius: 5px;
  margin-bottom: 0;
  background-color: #8A5CA8;
  border: 2px solid #BEBEBE;
}

.customization-color-picker input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
  font-size: 15px;
}

.customization-color-picker input::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
  font-size: 15px;
}

.customization-color-picker input:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
  font-size: 15px;
}

.customization-color-picker input:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
  font-size: 15px;
}

.customization-color-picker input:focus {
  background-color: #8A5CA8;
  border: 2px solid #BEBEBE;
  border-color: #fff;
  box-shadow: 0 0 0 0 !important;
}

.customization-color-picker p.small {
  text-align: center;
  font-size: 12px;
  color: #fff;
}

.select-template-img {
  margin-bottom: 10px;
}

.select-template-img img {
  background: transparent;
  border: none;
  padding: 0;
  margin-bottom: 10px;
}

.go-live-box-top {
  background: #69167D;
  padding: 20px 0;
}

.go-live-box-top-row-search input.form-control {
  border-radius: 50px !important;
}

.go-live-box-options {
  background-color: #DEDEDE;
  padding: 8px 0;
}

.go-live-box-options ul {
  padding: 0;
  margin-bottom: 0;
}

.go-live-box-options ul li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 0px;
}

.go-live-box-options span.icon {
  color: #616161 !important;
  font-size: 15px;
  margin: 5px;
}

.go-live-box-body {
  padding: 50px 0;
}

.go-live-box-body-template ul {
  padding: 20px 0;
  margin: 0;
}

.go-live-box-body-template ul li {
  display: inline-block;
  margin-right: 5px;
}

.go-live-box-body-template ul li img {
  width: 80px;
}

.go-live-box-footer-row {
  background-color: #EEEEEE;
  border-radius: 12px;
}

.go-live-box-footer-group {
  padding: 20px;
}

.go-live-box-footer-group-box {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}

.go-live-box-footer-group-box p {
  margin-bottom: 0px !important;
  font-size: 14px;
}

.go-live-box-footer-group-box-options {
  position: absolute;
  width: 100%;
  padding: 10px;
}

.go-live-box-footer-group-box-options .btn {
  padding: 0;
  background: transparent;
  border: transparent;
  overflow: hidden;
  border-radius: 5px;
}

.go-live-box-footer-group-box-options img {
  width: 50px;
}

.go-live-box-footer-group-box-options img.opt1 {
  width: 60px;
}

.go-live-box-footer-group-box-name {
  background-color: #00A8FF;
  position: absolute;
  width: 100%;
  bottom: 0;
  color: #fff;
  padding: 8px;
}

.go-live-box-footer-group-box-name .icon {
  color: #fff;
  margin-right: 10px;
  font-size: 14px;
}

.live-options-btn {
  border-radius: 10px;
}

.red-btn {
  background-color: #E34E4E;
  color: #fff;
}

.red-btn span.icon {
  color: #fff !important;
}

.thumbnail-form {
  border-radius: 15px;
}

.thumbnail-form .form-group {
  background: #303030;
}

.thumbnail-form .box-body {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  overflow: hidden;
}

.thumbnail-form .box-body img {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.thumbnail-form label {
  font-size: 14px;
}

.thumbnail-form .btn-remove {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 0;
}

.thumbnail-form .btn-remove i {
  color: #fff;
  font-size: 40px;
  position: relative;
  top: 9px;
  left: 7px;
}

.box {
  position: relative;
  background: transparent;
  width: 100%;
  padding: 10px 0;
}

.box-header {
  position: relative;
}

.box-tools {
  position: relative;
  right: 10px;
  top: 22px;
}

.dropzone-wrapper {
  border: 2px dashed #fff;
  color: #fff;
  position: relative;
  height: 120px;
  background: #BB8ED9;
  border-radius: 10px;
}

.dropzone-desc {
  position: relative;
  top: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  font-size: 16px;
}

.dropzone,
.dropzone:focus {
  position: absolute;
  outline: none !important;
  width: 100%;
  height: 130px;
  cursor: pointer;
  opacity: 0;
  top: 0;
}

.dropzone-wrapper:hover,
.dropzone-wrapper.dragover {
  background: #323232;
}

.preview-zone {
  text-align: center;
}

.preview-zone .box {
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 0;
}

.thumbnail-form {
  border-radius: 15px;
}

.thumbnail-form .form-group {
  background: #303030;
}

.thumbnail-form .box-body {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  overflow: hidden;
}

.thumbnail-form .box-body img {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.thumbnail-form label {
  font-size: 14px;
}

.thumbnail-form .btn-remove {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 0;
}

.thumbnail-form .btn-remove i {
  color: #fff;
  font-size: 40px;
  position: relative;
  top: 9px;
  left: 7px;
}

.upload-form .form-group {
  background: #303030;
}

.upload-form .box-body {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  overflow: hidden;
}

.upload-form .box-body img {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.upload-form label {
  font-size: 14px;
}

.upload-form .btn-remove {
  font-size: 14px;
  font-weight: 500;
  color: #6E6E6E;
  padding: 0;
}

.upload-form .btn-remove i {
  color: #fff;
  font-size: 40px;
  position: relative;
  top: 9px;
  left: 7px;
}

.upload-form.select-template-box .box-tools {
  position: relative;
  right: 0px;
  top: 0px;
}

.upload-form .box-tools {
  position: relative;
  right: 10px;
  top: 22px;
}

.display-name {
  background: #BB8ED9;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
}

.display-name .form-group, .display-name .form-label {
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-size: 14px;
  font-weight: 500;
}

.display-name .box-tools {
  position: relative;
  right: 0px;
  top: 0px;
}

.box {
  position: relative;
  background: transparent;
  width: 100%;
  padding: 10px 0;
}

.pull-right {
  float: right;
}

.thumbnail-form .box-body {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  overflow: hidden;
  background: #8A5CA8;
  border: 1px solid #C6C6C6;
}

.thumbnail-form label {
  font-size: 14px;
  margin-bottom: 0px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 28px;
}

input:checked + .slider {
  background-color: #C23636;
}

input:checked + .slider.green {
  background-color: #00BF85;
}

.slider.round {
  border-radius: 34px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #707070;
  transition: .4s;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round:before {
  border-radius: 50%;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  transition: .4s;
}


html, body{ overflow-x:hidden;}
.edit-page{ background: #333333; padding: 6px 0 0 0;}
.go-live-box-top{ background: #191919; padding: 8px 0;}
.go-live-box-options{ background: #232323;}

.edit-page-box{ padding: 0 15px 0 15px; background: #191919 !important; height: calc(100vh - 66px); padding-bottom: 120px; overflow-x: hidden; overflow-y: auto;}

.editTabNav{ display: flex; align-items: center; justify-content: center; background: #202020; padding: 10px 15px; padding-left: 0;}
.editTabNav .nav-pills .nav-link{ background-color: none; cursor: pointer; padding: 4px 8px; min-width: 70px; color: #FFB300; margin: 0 10px;}
.editTabNav .nav-pills .nav-link.active{  background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%) !important; background-color: transparent !important; color: #FFF !important;}
.editTabNav .nav-pills .nav-link img{ width: 20px; margin: 0; padding: 0;}

.go-live-box-options{ padding: 5px 15px; padding-left: 0; background: none !important;}
.go-live-box-options .btn{ display: inline-flex !important; align-items: center; justify-content: center; padding: 2px 12px !important;}
.go-live-box-options ul{ text-align: center;}
.purple-box{ background: #303030 !important;}

.edit-page-box::-webkit-scrollbar { width: 5px;}
.edit-page-box::-webkit-scrollbar-track { box-shadow: none; background: none;}
.edit-page-box::-webkit-scrollbar-thumb { background-color: #FF8F4B; outline: none; border-radius: 10px;}

.comments-content-box--chat-conversation::-webkit-scrollbar { width: 5px;}
.comments-content-box--chat-conversation::-webkit-scrollbar-track { box-shadow: none; background: none;}
.comments-content-box--chat-conversation::-webkit-scrollbar-thumb { background-color: #FF8F4B; outline: none; border-radius: 10px;}

#scrollableAudio::-webkit-scrollbar { width: 5px;}
#scrollableAudio::-webkit-scrollbar-track { box-shadow: none; background: none;}
#scrollableAudio::-webkit-scrollbar-thumb { background-color: #FF8F4B; outline: none; border-radius: 10px;}

#scrollableImage::-webkit-scrollbar { width: 5px;}
#scrollableImage::-webkit-scrollbar-track { box-shadow: none; background: none;}
#scrollableImage::-webkit-scrollbar-thumb { background-color: #FF8F4B; outline: none; border-radius: 10px;}

.comments-content-box-circle-img{ width: 50px; height: 50px;}
.comments-content-box--chat-conversation-body-box{ border-radius: 10px; padding-top: 4px; padding-bottom: 4px;}

.broadcast-content .box-form-control input, .broadcast-content .box-form-control textarea{ background: #0c0c0c !important; border: 1px solid #707070 !important;}
.dropzone-wrapper{ background: #000 !important; border: 2px dashed #707070 !important; margin-top: 12px;}
.broadcast-content-box-circle{ background: #0c0c0c;}
.thumbnail-form .box-body{ background: #0c0c0c; border: 1px solid #707070;}
.schedule-later{ background: #0c0c0c; border-left: 10px solid #FF3D38; padding: 8px 10px;}
.schedule-later p{ margin: 0; padding: 0;}
.content-box--broadcast .btn.today{ background-color: #0c0c0c; border: #0c0c0c;}
.content-box--broadcast .dropdown-toggle{ background-color: #0c0c0c; border: #0c0c0c;}

::-webkit-input-placeholder { color: #707070 !important;}
::-moz-placeholder {color: #707070 !important;}
:-ms-input-placeholder {color: #707070 !important;}
:-moz-placeholder {color: #707070 !important;}

.customization-color-picker .color-picker-box, .customization-color-picker input{ background: #0c0c0c !important; border: 1px solid #707070 !important;}
.logo-row .logo-box, .overlay-row .logo-box, .background-row .logo-box{ background: #0c0c0c !important; position: relative;}
.logo-row .overlay-box, .logo-row .background-box, .overlay-row .overlay-box, .overlay-row .background-box, .background-row .overlay-box, .background-row .background-box{ background: #0c0c0c !important;}

.img-right-absolute{ z-index: 5; right: 15px; top: 15px; width: auto !important; max-width: 100px !important; max-width: 100px !important;}
.img-left-absolute{ z-index: 4; object-fit: cover; height: 100%; width: 100%;}
.img-left-absolute.bgAbs{ z-index: 2; object-fit: contain;}

.img-library-box #pills-tab .nav-link{ background: #0c0c0c !important;}
.img-library #pills-tab{ background: #0c0c0c !important;}
.img-library-box{ border-radius: 15px;}

.followUs, .followUs-Show, .display-name{ background: #0c0c0c;}
.btn-dark-purple{ background: #0c0c0c !important; border-color: #0c0c0c !important;}

.edit-page .btn.btn-blue{ background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%); border-radius: 10px; border: none !important;}
.edit-page-box-tab-box{ text-align: center;}

.go-live-box-footer{ position: fixed; width: 100%; left: 0; bottom: 0; background: #222020; z-index: 9; padding: 15px 15px; padding-bottom: 8px;}
.go-live-box-footer-row{ background: none; border-radius: 0;}
.go-live-box-footer-row ul{ white-space: nowrap; overflow-y: hidden; overflow-x: auto; margin: 0; padding: 0;}
.go-live-box-footer-row ul li{ display: inline-block; margin: 0; padding: 0; width: 170px; margin-left: 15px;}
.go-live-box-footer-row ul li:first-child{ margin-left: 0;}
.go-live-box-footer-group-box-name{ padding-top: 0; padding-bottom: 0; background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%);}

.go-live-box-options .btn-light{ background: #000; border-color: #000; color: #FFF; font-size: 13px; position: relative;}
.go-live-box-options .btn-light input[type="file"]{ position: absolute; width: 100%; height: 100%; left: 0; top: 0; opacity: 0;}

.btn-gradient-orange{ display: inline-flex; align-items: center; justify-content: center;}
.go-live-box-top-row-button .btn{ margin-left: 10px;}


.scale-video-wrap{ max-width: 940px; margin: 0 auto;}
.scale-video { width: 100%; padding-top: 56.25%; position: relative;}
.scale-video iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%;}

.position-list{ width: 95px;}
.position-list-box{ background-color: #ff7845;}
.position-list-box.green{ background-color: #FF3D38;}

.btn-show-hide{ color: #ff7845;}

.btn-more{ background-color: #ff7845;}
input:checked + .slider{ background-color: #ff7845;}
input:checked + .slider.green{ background-color: #ff7845;}

.go-live-box-top .btn-gradient-orange{ padding: 3px 15px;}
.go-live-box-top-row-button .btn-gradient-orange span.icon{ margin-left: 0;}
.broadcast-content .btn-gradient-orange{ padding: 8px 25px;}

.paticipants-box-top{ background-color: #ff7845;}
.btn-admit{ background-color: #ff7845 !important; border: none !important;}
.paticipants-box-top .btn-link{ color: #FFF !important;}
.btn-unban{ background: #FF2D3B !important;}

.private-chat-content-box--chat-submit{ background: #0c0c0c !important;}
input.purple{ background: #0c0c0c !important; border: 1px solid #707070 !important;}

.comments-content-box-circle{ background: #0c0c0c !important;}
.comments-content-box--chat-conversation-body-box{ background: #0c0c0c !important;}
.btn-reply{ background-color: #ff7845 !important; border: none !important; color: #FFF !important; font-size: 12px;}
.comments-content-box--chat-submit{ background: #0c0c0c !important;}
.comments-content-box-circle-img img{ border-radius: 100%; border: 1px solid #707070 !important;}
.btn-hide-chat{ background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%) !important; border: none !important; color: #FFF !important; font-size: 13px;}
.paticipants-box-body-img.male{ background: #e0725b;}
.paticipants-box-body-img.female{ background: #d40398;}
.private-chat-content-box-circle{ background: #0c0c0c !important;}

.circle-img{ width: 50px; height: 50px; border-radius: 100%; position: relative;}
.circle-img img{ width: 50px; height: 50px; border-radius: 100%;}
.circle-img-wrap{ position: relative; cursor: pointer;}
.circle-img-select{ position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.7); opacity: 0; visibility: hidden; display: flex; align-items: center; justify-content: center; border-radius: 100%;}
.circle-img-select.act{ opacity: 1; visibility: visible;}
/* .circle-img-wrap.active .circle-img-select{ opacity: 1; visibility: visible;} */

.socialIcon{ position: absolute; width: 25px; height: 25px; right: -6px; bottom: 0; z-index: 2;}
.socialIcon img{ width: 100%; height: 100%; object-fit: cover;}

.logo-box:hover .circle-img-select{opacity: 1; visibility: visible;}

.circle-img-select img{ width: 50px; height: 50px;}

.circle-img-disable{ position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.7); z-index: 9; display: flex; align-items: center; justify-content: center; cursor: pointer; opacity: 0; visibility: hidden; transition: all 0.5s ease;} 
.circle-img-disable span{ padding: 0 4px;}
.circle-img-wrap.active:hover .circle-img-disable{ opacity: 1; visibility: visible;}

.scLater{ display: flex; align-items: center; flex-wrap: wrap; padding: 12px 0 0 0;}
.scLater select{ background: #0c0c0c !important; border: 1px solid #707070 !important; color: #FFF !important; padding: 6px 6px; border-radius: 5px;}
.scLater select:focus{ outline: none !important; box-shadow: none !important;}
.scLater span{ margin-left: 5px;}
.scLater span:first-child{ margin-left: 0;}

.todaySelect{ min-width: 80px; background: #0c0c0c !important; border: 1px solid #707070 !important; color: #FFF !important; padding: 6px 6px; border-radius: 5px;}
.dropzone-desc{ font-size: 14px;}

.bannerAddBlock.activated{ background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%) !important;}


.addIcon{ cursor: pointer;}
.logo-box-img-logo{ width: 60px; height: 60px;}
.logo-box-img-logo img{ width: 100%; height: 100%; object-fit: cover;}
.overlay-box-img-logo{ width: 100px; height: 60px;}
.overlay-box-img-logo img{ width: 100%; height: 100%; object-fit: cover;}
.background-box-img-logo{ width: 100px; height: 60px;}
.background-box-img-logo img{ width: 100%; height: 100%; object-fit: cover;}

.overlay-box.forText{ display: flex; align-items: center; justify-content: center; cursor: pointer;}
.overlay-box.forText span{ padding: 5px 0; width: 80%; text-align: center;}
.forText:hover span.textDefault, .forText.active span.textDefault{ background: #FFF; color: #000; border-left: 5px solid #FF3D38;}
.forText:hover span.textNews{ background: #FF3D38; color: #FFF;}
.forText:hover span.textRounded{ background: #FF3D38; color: #FFF; border-radius: 20px;}
.forText.active{ border: 1px solid #FF3D38;}


.bannerAddBlock{ position: relative; color: #FFF; background: #303030 !important; border-radius: 15px; padding: 15px 20px;}
.bannerAddBlock h2{ font-size: 15px; color: #FFF; margin: 0; padding: 0;}
.bannerAddBlock h3{ font-size: 13px; color: #FFF; margin: 0; padding: 0;}

.inTab .nav.nav-pills{ border-radius: 50px; overflow: hidden; background: #000;}
.inTab .nav-item{ position: relative; width: 100%; flex-basis: 0; flex-grow: 1; max-width: 100%; cursor: pointer;}
.inTab .nav-pills .nav-link{ color: #FFF !important; padding-top: 12px; padding-bottom: 12px;}
.inTab .nav-pills .nav-link.active{  background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%) !important; background-color: transparent !important; color: #FFF !important;}

.musicCtrl{ display: flex; align-items: center; justify-content: space-between;}
.musicCtrl .box-tools{ position: static;}
.musicCtrl .box-tools .btn{ margin: 0 !important; padding: 0 !important; color: #FFF !important;}
.musicCtrl .box-tools .btn label{ margin-bottom: 0 !important; padding-bottom: 0 !important;}
.musicCtrl-left{ width: calc(100% - 150px); display: flex; align-items: center;}
.musicCtrl-right{ width: 108px;}
.volIcon{ width: 20px;}
.volTrack{ width: calc(100% - 20px); padding-left: 5px;}

.audioSingle{ display: flex; align-items: center; background: #000; border-radius: 50px; overflow: hidden; position: relative; margin-top: 10px;}
.audioSingle:first-child{ margin-top: 0;}
.audioSingle-left{ background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%) !important; color: #FFF; width: 50px; height: 40px; display: flex; align-items: center; justify-content: center;}
.audioSingle-right{ padding-left: 10px;}
.audioSingle-play{ cursor: pointer; position: absolute; left: 50px; top: 0; width: calc(100% - 93px); height: 100%; background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%) !important; display: flex; align-items: center; justify-content: center; opacity: 0; visibility: hidden; transition: all 0.5s ease;}
.audioSingle-play span{ padding: 0 4px;}
.audioSingle:hover .audioSingle-play{ opacity: 1; visibility: visible;}

.comments-content-box--chat-conversation-body-box{ position: relative; overflow: hidden;}
.commentHd{ position: absolute; width: 100%; height: 100%; left: 0; top: 0; background: rgba(0, 0, 0, 0.8); display: flex; align-items: center; justify-content: center; opacity: 0; visibility: hidden; transition: all 0.5s ease; cursor: pointer;}
.commentHd span{ padding: 0 3px;}
.comments-content-box--chat-conversation-body-box:hover .commentHd{ opacity: 1; visibility: visible;}

#scrollableAudio{ height: calc(100vh - 460px); overflow-x: hidden; overflow-y: auto;}
.col-chat-left{ position: fixed; left: 0; top: 66px; z-index: 2;}

.pChatWindow{ position: fixed; width: 350px; left: 15px; bottom: 0; z-index: 999; border-radius: 15px; overflow: hidden; border-bottom-left-radius: 0; border-bottom-right-radius: 0;}
.pChatWindow-head{ display: flex; align-items: center; justify-content: space-between; color: #FFF; background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%) !important; padding: 9px 20px; cursor: pointer;}
.pChatWindow-head span + span{ padding-left: 8px;}

.comments-content-box--chat-conversation{ min-height: 30px !important; height: calc(100vh - 402px); overflow-x: hidden; overflow-y: auto;}
/* .comments-content-box--chat-submit{ transform: translateY(35px);} */

.pChatWindow-body{ padding: 15px; background: #282828; height: 300px; overflow-x: hidden; overflow-y: auto;}
.pChatWindow-body::-webkit-scrollbar { width: 5px;}
.pChatWindow-body::-webkit-scrollbar-track { box-shadow: none; background: none;}
.pChatWindow-body::-webkit-scrollbar-thumb { background-color: #FF8F4B; outline: none; border-radius: 10px;}

.chatSingle-right-txt{ background: #ff7845; border-radius: 50px; border-bottom-left-radius: 0; padding: 4px 18px;}

.edit-page{ min-height: calc(100vh - 130px);}

.chatSingle{ display: flex; margin-top: 30px;}
.chatSingle:first-child{ margin-top: 0;}
.chatSingle-left{ width: 40px;}
.chatSingle-left img{ width: 100%; height: auto; border-radius: 100%;}
.chatSingle-right{ width: calc(100% - 40px); padding-left: 10px;}
.chatSingle-right-txt{ color: #FFF; font-size: 12px; line-height: 16px;}

.chatSingle.chatLeft{ padding-left: 50px;}

.chatSingle.chatRight{ padding-right: 50px;}
.chatSingle.chatRight .chatSingle-left{ order: 2;}
.chatSingle.chatRight .chatSingle-right{ order: 1; padding-left: 0; padding-right: 10px;}
.chatSingle.chatRight .chatSingle-right-txt{ border-bottom-left-radius: 50px; border-bottom-right-radius: 0px; background: #101010;}

.pChatWindow-footer{ border-top: 1px solid #000; padding: 15px; background: #282828; display: flex; align-items: center; justify-content: space-between; color: #FFF;}
.pChatWindow-footer input[type="text"]{ background: #0c0c0c !important; border: 1px solid #707070 !important; padding: 7px 15px; width: 280px; color: #FFF !important;}
.chatBodyWrap{ height: 0;}
.chatBodyWrap.act{ height: auto;}

.go-live-box-footer-group-box .btn{ background: #FFF !important; color: #FF3D38 !important; padding: 0 8px; border: 1px solid #FF3D38;}

.sliderN {
  -webkit-appearance: none;
  width: 100%;
  height: 7px;
  border-radius: 50px;
  background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%) !important;
  outline: none;
  opacity: 1;
  transition: opacity .2s;
}

.sliderN::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #000;
  border: 2px solid #FFF;
  cursor: pointer;
  border-radius: 100%;
}

.sliderN::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #000;
  border: 2px solid #FFF;
  cursor: pointer;
}

.col-chat-left{ width: 450px !important;}
.col-chat-right{ width: 100% !important; padding-left: 450px !important;}

h2.tabTitle{ color: #FFF; font-size: 18px; padding-top: 30px;}
h2.tabTitle.alt{ padding-top: 10px; padding-bottom: 20px;}

.go-live-box-body-template ul li{ background: #FFF; border-radius: 5px; border: 2px solid #FFF; cursor: pointer;}
.go-live-box-body-template ul li.active{ border: 2px solid #ff3d38;}
.go-live-box-body-template ul li img{ border-radius: 5px;}

.go-live-box-body{ padding-top: 10px !important; padding-bottom: 90px !important;}
.go-live-box-top .btn-gradient-orange{ background: red !important; padding-top: 8px; padding-bottom: 8px;}
.edit-page-box-tab-box p{ font-size: 14px !important; padding-top: 5px !important;}
.editTabNav .nav-pills .nav-link{ color: #FFF !important;}

.ScheduleTextlabel{ font-size: 14px; font-weight: 600;}
.go-live-box-footer{ padding-left: 447px;}
.go-live-box-footer-group-box-options{ padding: 5px;}
.go-live-box-body-template ul li img{ width: 60px;}

.go-live-box-options .btn-light{ border: none !important;}
.go-live-box-options .btn-light:hover{ background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%) !important;}
.go-live-box-options .btn-light span.icon{ color: #FFF !important;}
.circle-img-disable img{ width: 50px; height: 50px;}

.comments-content-box--chat-conversation-body-box.show{ background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%) !important;}
.comments-content-box--chat-submit{ display: flex; align-items: center; justify-content: space-between;}
.comments-content-box-circle-img{ position: relative;}
.proficon{ position: absolute; bottom: 6px; right: -6px; width: 20px; height: 20px;}
.proficon img{ width: 20px; height: 20px;}

.broadSetting{ background: #282828; min-height: calc(100vh - 124px); padding: 80px 0;}
.btOrg{ border: none !important; background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%) !important;}

.camCheck{ background: #303030 !important; border-radius: 15px; padding: 30px;}
.camCheck h2{ color: #FFF; font-size: 18px;}
.camNotification{ border: 1px solid #FF3D38; border-radius: 10px; color: #FF3D38; padding: 15px; margin-top: 15px;}

.micCheck{ display: flex; align-items: flex-start; justify-content: center; margin-top: 30px;}
.micCheck-left{ width: calc(100% - 200px); height: 200px; background: #000; border-top-left-radius: 10px; border-bottom-left-radius: 10px; overflow: hidden; border-radius: 10px;}
.micCheck-right{ width: 200px;}
.micCheck-right-main{ height: 200px; background: #222222; display: flex; align-items: center; border-top-right-radius: 10px; border-bottom-right-radius: 10px; justify-content: center; color: #FFF;}
.micCheck-left video{ width: 100% !important; height: 100% !important;}

.mikeBar{ width: 20px; height: 90px; background: #424242; margin: 0 auto; border-radius: 5px;}
.mcIcon{ font-size: 18px;}
.micControll{ padding-top: 30px; text-align: center;}
.micControll ul{ display: inline-block; text-align: center; margin: 0; padding: 0; border: 1px solid #404040; border-radius: 5px; overflow: hidden;}
.micControll ul li{ display: inline-block; color: #FFF; margin: 0; list-style-type: none; padding: 5px 15px; cursor: pointer; border-left: 1px solid #404040;}
.micControll ul li:first-child{ border-left: 0;}
.micControll ul li span{ display: block;}
.micControll ul li span.camIcon{ font-size: 18px;}
.micControll ul li.active{ background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%) !important;}

.dispName label{ display: block; color: #FFF; font-size: 14px; font-weight: 600;}
.dispName input[type="text"]{ width: 100%; background: #0c0c0c !important; border: 1px solid #707070 !important; color: #FFF !important;}

.footer.footerbg + .footer.footerbg{ display: none !important;}

.scale-video video{ position: absolute; width: 100%; height: 100%; left: 0; top: 0;}
.go-live-box-footer-group-box-img{ position: relative;}
.go-live-box-footer-group-box-img video{ width: 100% !important; height: 100% !important;}

.vidPre5.videoFullheight video{ object-fit: cover;}
.videoFullheight video{ object-fit: cover;}

.go-live-box-footer-group-box-options{ position: absolute !important; left: 0 !important; top: 0 !important; z-index: 2;}
.go-live-box-footer-group-box{ height: 100px !important;}

.audioLeft{ display: flex; align-items: center;}
.audioSingle{ justify-content: space-between; padding-right: 15px; padding-left: 0;}
.audioSingle-control{ font-size: 18px;}
.audioSingle-control svg{ margin-left: 5px;}

.go-live-box-footer-group-box-options button{ font-size: 12px !important;}
.logo-box-add-logo, .overlay-box-add-logo, .background-box-add-logo
{ position: relative; overflow: hidden;}
.logo-box-add-logo input[type="file"], .overlay-box-add-logo input[type="file"], .background-box-add-logo input[type="file"]
{ position: absolute; width: 100%; height: 100%; left: 0; top: 0; opacity: 0;}

.comments-content-box-circle-img{ position: relative;}
.comments-content-box--chat-submit .btn-gradient-orange{ height: 38px; margin: 0 0 0 0;}
.social-select{ position: absolute; left: 0; top: 0; border: none; font-size: 14px; border-radius: 5px; padding: 4px 0; background: #0c0c0c; color: #FFF; border: 1px solid #707070;}
.social-select:focus{ outline: 0; border: none; box-shadow: none; border: 1px solid #707070;}
.commentInp{ position: relative; padding: 0 0 0 0;}

.micCheck-left img{ width: 100%; height: 100%; object-fit: contain;}
.defaultImg-wrap{ position: absolute; width: 100%; height: 100%; left: 0; top: 0; z-index: 2; padding: 30px; overflow: hidden;}
.defaultImg-wrap img{ width: 100%; height: 100%; object-fit: contain;}

.go-live-box-footer-group-box-img{ height: 100%; background: #000;}
.go-live-box-footer-group-box-img img{ width: 100%; height: 70px; object-fit: contain; margin-top: 5px;}

.scalevidforGuest{ min-height: calc(100vh - 280px);}

.comments-content-box-circle-img{ position: relative;}
.socialDrop .dropdown-toggle{ position: absolute; padding: 0 !important; width: 30px; height: 30px; border-radius: 50px; left: 30px; top: -50px; border: none; background: none !important; font-size: 30px;}
.socialDrop .dropdown-menu{ width: 100%; background: none !important; overflow: hidden; padding: 0; border: none; box-shadow: none; -webkit-transform: translateY(-95px) !important; transform: translateY(-95px) !important; top: inherit !important; bottom: 100% !important;}
.socialDrop .dropdown-toggle::after{ display: none !important;}

.socialDrop .dropdown-item{ display: flex; align-items: center; width: 275px; white-space:normal; color: #FFF !important; border-top: 1px solid rgba(255, 255, 255, 0.1); padding: 5px 10px; background: none !important; background: #202020 !important;}
.socialDrop .dropdown-item:first-child{ border: none;}
.socialDrop .userImg{ width: 50px; height: 50px; border-radius: 100%;}
.desctxt{ width: calc(100% - 50px); padding-left: 15px; font-size: 12px;}
.desctxt p{ line-height: 14px; margin: 0; padding: 0;}

.comments-content-box-circle-img:hover .socialDrop .dropdown-toggle{ opacity: 1; visibility: visible;}
.camCheck{ position: relative;}
.camGif{ border-radius: 10px; overflow: hidden; max-width: 350px; margin: 0 auto;  margin-bottom: 20px;}
.camGif img{ width: 100%;}

.box-tools.alt{ position: static;}

.goliveDrop .dropdown-menu{ width: 350px; left: inherit !important; right: 0 !important; background: #333333; color: #FFF; padding: 25px; border: 1px solid rgba(255, 255, 255, 0.2);}
.goliveDrop h5{ font-size: 17px;}
.btn-gradient-orange.alt{ background: none !important; border: 1px solid rgba(255, 255, 255, 0.4) !important; color: #FFF;}

.comments-content-box--chat-submit .comments-content-box-circle-img img{ width: 100%; height: 100%; object-fit: cover;}
.comments-content-box--chat-submit .comments-content-box-circle-img{ height: 52px;}

.bannerCanvas{ position: absolute; width: 100%; height: auto; z-index: 9; left: 0; bottom: 0; height: 51px; text-align: left;}
.BannerCanvas{  position: absolute;  width: 100%; z-index: 9; left: 0; bottom: 0; text-align: left; }
.BannerCanvas canvas{ width: 100%;}
/* .bannerCanvas canvas{ height: auto !important; width: auto !important;} */

.descImg{ position: relative; width: 50px; height: 50px;}

.connection-box .btn-change7{ border-radius: 2px; background: none !important; border: 1.8px solid #FF8F4B !important; color: #FF8F4B !important; box-shadow: 0 2px 4px 0 rgb(255 143 75 / 35%); font-size: 16px; font-weight: 600; min-width: 140px; }
.connection-box .btn-change7:hover{ background: #FF8F4B !important; color: #FFF !important;}
.facebook-connect-account + .facebook-connect-account, .youbtube-connection + .facebook-connect-account{ border-top: 1px solid rgba(255, 255, 255, 0.2); padding-top: 20px; padding-bottom: 20px; margin-top: 0 !important;}
.facebook-connect-account{ padding-bottom: 20px;}
.youbtube-connection{ padding-bottom: 20px;}

.app-name{ font-size: 12px; width: 60px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block; padding-top: 4px;}

.pendingMsg{ position: absolute; left: 0; bottom: 0; width: 100%; z-index: 3;}
.pendingMsg span{ display: block; background: #17a2b8; text-align: center; color: #FFF; padding: 2px 0 5px 0; border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;}

.pendingMsg.bdPending span{ background: #FF8F4B;}
.pendingMsg.bdCompleted span{ background: green;}

.my-video-projects .card-box-edit-more{ margin-top: 0 !important;}
.my-video-projects .card-box-overlay{ padding-top: 40px;}

.card-img-top{ height: 230px;}
.video-title p{ overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}
.my-video-projects .card .my-video-projects-box-details{ padding: 8px 0 0 0 !important;}
.card-img-top{ object-fit: cover;}

.trans-thumb{ position: relative;}
.trans-thumb video{ width: 100%; height: 100%; border: 5px solid #ffffff}
.videoTrans-button{ width: 100%; height: 100%; left: 0; top: 0; position: absolute; display: flex; align-items: center; justify-content: center;}
.videoTrans-button button{ border: none; background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%); color: #FFF !important; padding: 10px 20px; border-radius: 10px;}

.librarySearch{ position: relative;}
.librarySearch .btn-gradient-orange{ position: absolute; right: 18px; top: 7px;}
.librarySearch input[type="text"]{ width: 100%; margin-right: 10px; background-color: #1e1e1e; color: #fff; padding: 12px 20px; border-radius: 10px; border: 1px solid #707070;}
.searchForm{ border-bottom: 1px solid rgba(255, 255, 255, 0.1); margin-bottom: 20px;}

.searchOpt div{ padding: 0 15px;}
.search-load{ margin-top: 10px;}

/* Addition */
/* .more-about-services-list p{ color: #FF8A3B;} */
/* Addition */

@media only screen and (max-width: 1500px) {
  .go-live-box-options ul li > .btn {
    font-size: 14px;
  }
  .go-live-box-options ul li {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1400px) {
  .edit-page-box-tab a {
    width: 80px !important;
    height: 105px;
    padding: 10px;
  }
  .edit-page-box-tab-box img {
    width: 30px;
  }
  .edit-page-box-tab-box p {
    font-size: 10px;
  }
}

@media only screen and (max-width: 1215px) {
  .go-live-box-footer-group-box-options img.opt1 {
    width: 40px;
    height: 30px;
  }
  .go-live-box-footer-group-box-options img {
    width: 40px;
  }
}

@media only screen and (max-width: 1200px) {
  .btn-hide-chat {
    margin-top: 10px;
  }
  .comments-content-box--chat-conversation-body-box p {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1170px) {
  .edit-page-box-tab a {
    width: 80px;
    height: 80px;
    padding: 10px;
  }
  .edit-page-box-tab-box img {
    width: 25px;
  }
  .customization-color-picker input {
    padding-left: 3px;
    padding-right: 3px;
  }
}

@media only screen and (max-width: 1070px) {
  .broadcast-content-box-circle, .comments-content-box-circle, .private-chat-content-box-circle {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 1023px) {
  .col-chat-left{ position: static; width: 100% !important;}
  .col-chat-right{ padding-left: 0 !important;}
  .edit-page-box{ height: auto !important; padding-bottom: 0 !important; border-bottom: 1px solid rgba(255, 255, 255, 0.2);}
}

@media only screen and (max-width: 991px) {
  .btn-st {
    width: 100%;
  }
  .imgsizeright {
    margin-right: 0px;
  }
  .imgsizeleft {
    margin-left: 0px;
  }
  .more-about-services {
    width: 100%;
  }
  .important-topics-content img {
    width: 150px;
  }
  .go-live-box-options {
    padding: 15px;
  }
  .customization-color-picker input {
    padding: 0.375rem 0.75rem;
  }
  .thumbnail-form .box-body {
    margin-top: 60px;
  }
  .scheduleTime .btn {
    margin-right: 0;
    margin-left: 0;
  }
}

@media only screen and (max-width: 950px) {
  .edit-page-box-tab a {
    width: 70px;
    height: 70px;
    padding: 5px;
  }
  .edit-page-box-tab .nav-item {
    margin-bottom: 10px;
  }
  .edit-page-box-tab-box p {
    font-size: 10px;
  }
  .thumbnail-form .btn-remove {
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .img-mac {
    width: 100%;
  }
  .imgsizeright {
    margin-left: 0;
  }
  .webinar-img {
    width: 100%;
  }
  .banner-video-project:before {
    top: 0;
    margin-top: -28px;
  }
  .important-sec:before {
    top: 0;
    margin-top: -28px;
  }
  .personal-details-box .btn-submit, .gdpr-update-btn {
    width: 100%;
  }
  .go-live-box-footer-group-box-options img.opt1 {
    width: 60px;
    height: auto;
  }
  .go-live-box-footer-group-box-options img {
    width: 50px;
  }
  .thumbnail-form .btn-remove {
    font-size: 14x;
  }
  .thumbnail-form .box-body {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 576px) {
  .comments-content-box--chat-conversation-body-box {
    text-align: left;
    margin-left: auto !important;
  }
  .comments-content-box-circle-img.group {
    text-align: left;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 392px) {
  .popular-video-sec h3 {
    font-size: 20px !important;
  }
  .popular-video-sec h3:before {
    top: 2px;
  }
}

@media only screen and (max-width: 350px) {
  .banner-content h1 {
    font-size: 45px;
  }
  .banner-content .watch-demo-btn button {
    padding: 15px 40px;
  }
  .banner-content h1 {
    font-size: 45px !important;
  }
}

@media only screen and (max-width: 330px) {
  .popular-video-sec h3 {
    font-size: 18px !important;
  }
}
/*# sourceMappingURL=Style.css.map */

@media (max-width: 575.98px) {

    .position-text-box{
        margin-top: 10px !important;
    }
    .integration-box li{
        margin-top:20px;
    }

    .facebook-connection{
        margin-top: 20px;
    }

    .webinar-sec{
        padding: 69px 10px;
    }

    .form-box{
        padding: 20px 10px;
    }
    .banner-content {
        padding: 40px 0px;
    }
    .banner-content h1 {
        font-size: 50px;
    }

    .project-header-name .project-item h4 {
        font-size: 20px;

    }
    .project-header-name .project-conut h6{
        font-size: 20px;
    }

    .banner-content .watch-demo-btn button{
        padding: 15px 50px;
    }
    .banner-video-project .video-project a span {

        margin-left: 0 !important;
    }



    .banner-video-project .video-project a{
        font-size: 14px;
    }

    .banner-video-project .video-project h5 {
        font-size: 20px;

    }

    .player-box{
        margin-top: 20px;
    }

    .important-topics{
        margin-top: 20px;
    }

    .banner-content p {
        width: 100%;
    }
    .banner-items{
        padding: 40px 0px;
    }

    .dashborad-sec-3{
        padding: 40px 0px;
    }

    .imgsizeleft{
        margin-top: 30px;
    }

    .dashborad-sec-3-content {
        margin-top: 30px;
    }
    header .dropdown-menu {
        left: 0 !important;
    }

    .video-card{
        margin-top: 30px;
    }

    .login-box{
        padding: 40px 0px;
    }

    .login-form{
        padding: 20px;
        margin-top: 20px;
    }
    .login-form p{
        font-size: 14px;
    }

    .login-form h4 {
        font-size: 17px;
    }
    .login-content h5 {
        font-size: 20px;
    }

    .project-button{
        flex-direction: column !important;
        margin-top: 20px;
        justify-content: center;
    }

    .project-button .project-create-btn{
        margin-top: 20px;
    }

    .project-input-box{
        margin-right: 0px;
    }

    .footer .col-lg-4{
        display: flex;
        justify-content: center !important;
    }


    .search-select{
        flex-direction: column;
        justify-content: start;
    }

    .search-select .search-box{
        margin-top: 20px;
    }

    .template-name h4{
        font-size: 14px;
    }

    .template-number h6{
        font-size: 14px;
    }

    .select-box {
        margin-top: 15px;
    }
    .support-content {
        margin-top: 30px;
    }



    .webiner-content {
        margin-top: 20px;
    }


}


@media (max-width: 767.98px) {

    .position-text-box{
        margin-top: 10px !important;
    }

    .imgsizeleft{
        margin-top: 30px !important;
    }

    .dashborad-sec-3-content {
        margin-top: 30px !important;
    }

    .footer .col-lg-4{
        display: flex;
        justify-content: center !important;
    }

    .player-box{
        margin-top: 20px;
    }

    .important-topics{
        margin-top: 20px;
    }
    .support-content {
        margin-top: 30px;
    }
    .webiner-content {
        margin-top: 20px;
    }

}

@media (max-width: 991.98px) {
    .imgsizeleft{
        margin-top: 30px !important;
    }

    .dashborad-sec-3-content {
        margin-top: 30px !important;
    }

    .login-form{
        margin-top: 30px;
    }

    .video-card{
        margin-top: 30px;
    }

    .footer .col-lg-4{
        display: flex;
        justify-content: center !important;
    }

    .project-button {
        margin-top: 30px;
    }

    .banner-items{
        padding: 60px 0px;
    }
    .banner-content{
        padding: 60px 0px;
    }
    .dashborad-sec-3{
        padding: 30px 0px;
    }

    .position-text-box {
        min-width: 123px !important;
        max-width: 123px !important;
        margin: auto;
    }
    .player-box{
        margin-top: 20px;
    }

    .important-topics{
        margin-top: 20px;
    }
    .support-content {
        margin-top: 30px;
    }
    header .navbar ul li{
        margin-right: 0px;
    }

}





@media (max-width: 1199.98px) {
    .login-content h1{
        font-size: 50px;
    }

    .template-name h4{
        font-size: 18px;
    }

    .position-text-box {
        min-width: 123px;
        max-width: 123px;
    }

    .remove-logo-switch-btn .form-check label{
        font-size: 10px;
    }

    .remove-logo-switch-btn .form-check .form-check-input{
        margin-left: 8px;
        margin-top: 10px !important;
        font-size:10px !important;
    }
    .dimmer-switch-btn  .remove-logo-switch-btn .form-check-input{
        margin-top: 2px;
        margin-left: -30px;
        font-size: 10px !important;
    }

    .integration-box li{
        margin-top:20px;
    }





}



@media (max-width:1300px){

    .position-text-box{
        min-width: 122px;
        max-width: 122px;
    }




    .remove-logo-switch-btn .form-check .form-check-input{
        margin-left: 8px;

        font-size:20px;
    }
    .dimmer-switch-btn  .remove-logo-switch-btn .form-check-input{

        font-size: 20px;
    }


}




@media (min-width:1400px){

    .save-render-sec{
        width: 100%;
    }


    .editor-preview{
        width: 100%;
    }

    .render-projectbtn {
        margin-right: 0px;
    }

    .editor-tab-menu{
        width: 100%;
    }

}



@media (min-width:1500px){
    .container-fluid{
        max-width: 1400px;
    }

    .render-projectbtn{
        margin-right: 0px !important;
    }

    .secarchicone-part #searchimg{
        left: 0%;
    }
}
