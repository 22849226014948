@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');

:root {

  --poppins: 'Poppins', sans-serif;
  --Montserrat: 'Montserrat', sans-serif;
}



@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }

}

button {
  cursor: pointer !important;
}





a:hover {
  text-decoration: none !important;
}

a {
  text-decoration: none !important;
}


header .navbar {
  /*background-image: url('../images/Navbg.png');*/
  background-size: cover;
  background-position: center;
}


header .navbar .navbar-nav .active {
  border-bottom: 1px solid #FFB300;
}

.navmenuid {
  display: flex;
  align-items: center;
  justify-content: center;
}

#bg-video{
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  padding-bottom: 56.25%;
}

.belldropdown button {
  background: linear-gradient(to right, #FFB300, #0482D8);
  padding: 7px 20px !important;
  border-radius: 30px;
  display: inline-block;
}


.belldropdown .dropdown-item {
  white-space: normal !important;
  background-color: #efeeee94;
  margin: 5px 0px;
  font-size: 14px;
}


.navmenuid .text h6 {
  font-size: 12px;
  color: #FFB300;
}

.navmenuid .text {
  color: #fff;
  font-size: 16px;
  text-align: left;
  font-weight: 600;
  margin-left: 10px;
}

header .dropdown-menu {
  position: absolute !important;
  left: -105px !important;
  top: 44px !important;
}

header .navbar ul li a {
  color: #fff !important;
  font-family: var(--poppins) !important;
  transition: .2s ease-in-out;
  padding: 0px 0px;
  font-size: 16px;
}

header .navbar ul li:hover a {
  color: #FFB300 !important;
  transition: .2s ease-in-out;
}

header .navbar ul li {
  margin-right: 30px;
}

.bashboard-banner #bg-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
}

#bg-video iframe {
  position: absolute;
  min-height: 100vh;
  min-width: 177.77vh;
  width: 100vw;
  height: 56.25vw;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.bashboard-banner:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #15191e;
  top: 0;
  left: 0;
  opacity: .8;
}


header button#dropdownMenuButton span {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: block;

  cursor: pointer;
  transition: .2s ease-in-out;
  overflow: hidden;

}

.dropdown-menu.show {
  display: block;
  transition: .2s ease-in-out;
}

header .dropdown-toggle::after {
  display: none !important;
}

header .dropdown button {
  padding: 0;
  border: none;
  background-color: transparent;
}

header .dropdown button:focus {
  box-shadow: none;
  outline: none !important;
}

header .dropdown-item:hover {
  background-image: linear-gradient(to right, #FF8F4B, #FF3D38, #FF3D38);
}

.bashboard-banner .banner-items-list {
  background: linear-gradient(0.25turn, #842DD1, #AB2DBA, #E22D9A);
  border-radius: 15px;
  padding: 5px;
}

.bashboard-banner .banner-items p {
  color: #fff !important;
  font-size: 14px;
}

.bashboard-banner .banner-items a {
  margin-top: 15px;
}

.bg2 {
  background: linear-gradient(0.25turn, #2C7180, #1EAB91, #14D29D) !important;
}

.bg3 {
  background: linear-gradient(0.25turn, #2370BE, #4BB1CE, #4BB4CF) !important;
}

/* .banner-content{
  padding: 136px 0px;
} */

.banner-items {
  padding: 136px 0px;
}


.bashboard-banner {
  position: relative;
}

/* .banner-video-project::before{
  content: "";
  position: absolute;
  width: 100%;
  height: auto;
  background-image: url('images/banner-botbg.png');
  bottom:0;
  left: 0;
  opacity: 0.8;
} */

.banner-video-project:before {
  content: '';
  background-image: url('images/banner-botbg.png');
  background-size: contain;
  background-repeat: no-repeat;
  height: 200px;
  width: 100%;
  position: absolute;
  top: 0px;
  display: block;
  margin-top: -62px;
}

/* .bashboard-banner::before{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color:#15191E;
  top:0;
  left: 0;
  opacity: 0.8;
} */

.bashboard-banner {
  /*background-image: url('../images/Mask\ Group\ 3.png');*/
  background-size: cover;
  background-position: top center;
  width: 100%;

  background-repeat: no-repeat;

}

.banner-content .watch-demo-btn button {
  font-family: var(--poppins);
  font-weight: 600;
  /* background-image: linear-gradient(to right , #00C2FF , #00E9D8 , #00FFC2);
  padding: 15px 58px;
  border-radius: 30px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  overflow: hidden; */
}

button:focus {
  box-shadow: none !important;
  outline: none !important;
}



.banner-content .watch-demo-btn button span {
  margin-right: 5px;

}


.banner-content h4 {
  font-size: 39px;
  font-family: var(--Montserrat);
  font-weight: 300;
}

.banner-content h1 {
  font-size: 73px;
  font-weight: 800;
  font-family: var(--Montserrat);
}

.banner-content h1 span {
  color: #FFAA00;
}

.banner-content p {
  width: 544px;
  font-family: var(--poppins);
  font-size: 18px;
  font-weight: 300;
}

.dashborad-sec-3 {
  position: relative;
  overflow: hidden;
  padding: 80px 0px;
  background-color: #C8AED6;
}

.banner-video-project {
  background-color: #15191e;
  padding: 40px 0px;
  position: relative;
}

.banner-video-project .video-project h5 {
  color: #6A6A6A;
  font-size: 40px;
  font-family: var(--poppins);
}

.banner-video-project .video-project h5 span {
  color: #B54AE2;
}


.banner-video-project .video-project a span {
  color: #6A6A6A;
  margin-left: 20px;
}

.video-card {
  background-image: linear-gradient(to bottom, #00FDC3, #2DA8C7);
  border-radius: 10px;
  padding-top: 1px;
}


.project-create-btn button:hover {
  color: #2fcac9 !important;
}


.ClipsReel-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .8s ease-in-out;
  background-color: #000000a3;
  border-radius: 10px;
  clip-path: circle(30.3% at 100% 0);
}

.ClipsReel-overlay ul {
  height: auto;
}

.ClipsReel-overlay ul li a span {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  color: #000;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vidoe-play-icon span i {
  color: #fff !important;
}


.ClipsReel-overlay ul li:hover i {
  color: #00F5A0 !important;
}

.ClipsReel-overlay ul li:hover a {
  color: #00F5A0;
}

.ClipsReel-overlay ul li a {
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ClipsReel-overlay ul li a i {
  font-size: 18px;
  color: #000;
}


.video-img:hover .ClipsReel-overlay {
  opacity: 1;
  transition: .8s ease-in-out;
  clip-path: circle(141.4% at 100% 0);
}

.video-card .video-img {
  border-radius: 5px;
  position: relative;
  margin: 10px;
}

.video-card .video-title {
  padding: 10px;
  padding-top: 0px;
}

.video-card .video-title .vidoe-play-icon span {
  cursor: pointer;

}

.video-name {
  background-color: #B1FCFF;
  width: 100%;
  padding: 5px 10px;
  border-radius: 30px;
}

.video-name p {
  color: #000;
  font-family: var(--poppins);
  font-size: 14px;
  padding-left: 7px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600;
}

.vidoe-play-icon span img {
  width: 45px;
}

.banner-video-project .video-project a {
  /* color:#6a6a6a;
  font-size: 25px;
  font-family: var(--poppins); */
  transition: .2s ease-in-out;
}

.banner-video-project .video-project a:hover {
  /* color:#00F5A0; */
  transition: .2s ease-in-out;
}

.banner-video-project .video-project a:hover span {
  color: #00F5A0;
}





.dashborad-sec-3-content h4 {
  font-size: 30px;
  font-weight: 800;
  color: #915BD8;
  font-family: var(--poppins);
  transition: .2s ease-in-out;
}


.dashborad-sec-3-content h4:hover {
  color: #915BD8;
  opacity: .6;
  transition: .2s ease-in-out;
}

.dashborad-sec-3-content h6 {
  font-size: 18px;
  font-family: var(--poppins);
  color: #fff;
  text-transform: uppercase;

}

.dashborad-sec-3-content p {
  font-size: 18px;
  font-family: var(--poppins);
  font-weight: 200;
}

.imgsizeleft {
  margin-left: 50px;
}

.imgsizeright {
  margin-left: -320px;
}


.logoimg {
  /*background-image: url('../images/BG.png');*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
}

.logo-img {
  width: 200px;
}

.login-box {
  padding: 0px 0px 30px;
  min-height: calc(100vh - 118px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-content h1 {
  font-size: 55px;
  font-family: var(--Montserrat);
  font-weight: 800;
}

.login-content h1 span {
  color: #FF9100;
}

.login-content p {
  width: 90%;
  font-size: 18px;
  font-family: var(--poppins);
  font-weight: 300;
  margin: auto;
  color: #C1C1C1;
}

.login-content h5 {
  font-size: 29px;
  font-family: var(--poppins);
}



.login-form {
  padding: 50px;
  /* background-color: rgb(255 255 255 / 52%); */
  border-radius: 25px;
  border: 1px solid #eb0022 !important;
  background-color: #4b0064;
}

.login-form h4 span {
  color: #FF9100;
}

.login-form h4 {
  font-family: var(--Montserrat);
  font-size: 26px;
  /* font-weight: 600; */
}

/* .login-form button{
  background-image: linear-gradient(to left , #00D3EB , #16B891);
  border:none;
  color:#fff;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 600;
  font-family: var(--poppins);
  cursor: pointer;
} */

/* .login-form button:focus{
  color:#00b889 !important;
  background-color: #fff !important;
} */

/* .login-form button:focus{
  background-image: none !important;
} */

.login-form .forgot a {
  color: #c3c3c3 !important;
}

.login-form .forgot span:hover {
  color: #ff9100;
  transition: .2s ease-in-out;
}

.login-form label {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-family: var(--poppins);
  color: #c3c3c3;
}

/* .login-form .form-group input{
    background-color: transparent !important;
    border-color: #34B8CC !important;
    border-radius: 10px !important;
  } */

.login-form .form-group input:focus {
  box-shadow: none !important;
}

.login-form .form-group .form-control {
  color: #878787 !important;
}

.login-form a {
  font-weight: 500;
}

.login-form a span {
  color: #ff3d38;
  margin-left: 5px;
  transition: .2s ease-in-out;
}

/* .login-form a span:hover{
  color:#FF9100;
} */

.login-form .form-group {
  background-color: red;
}

.footer .footer-menu ul li a {
  color: #fff;
  font-size: 16px;
  font-family: var(--poppins);
  transition: .2s ease-in-out;
}

.footer .footer-menu ul li a:hover {
  color: #00F5A0;
  transition: .2s ease-in-out;
}

.footer-copyright p a:hover {
  color: #ff9100 !important;
  transition: .2s ease-in-out;
}

.footer .footer-menu ul li+li {
  margin-left: 40px;
}

.footer .footer-copyright p {
  font-size: 16px;
  font-family: var(--poppins);
}

.footer .footer-copyright p a {
  transition: .2s ease-in-out;
  color: #fff;
}

.footer {
  background-color: #0000007a;
  padding: 10px 0px;
}



.footerbg {
  background-color: #000000;
  padding: 15px 0px;
}

.vineasx-logo img {
  width: 170px;
  object-fit: cover !important;
}


.banner-items .banner-items-list .col-md-3 img {
  transform: scale(1);
  transition: .4s ease-in-out;
}

/* .banner-items .banner-items-list{
    transform: scale(1);
    transition: .4s ease-in-out;
}

 .banner-items .banner-items-list:hover{
    transform: scale(1.05);
    transition: .4s ease-in-out;
}  */

.banner-items .banner-items-list:hover .col-md-3 img {
  transform: scale(0.9);
  transition: .4s ease-in-out;
}



.project-banner {
  padding: 20px 0px;
  background-color: #15191E;
}


.project-header-name .project-conut h6 {
  color: #A2A2A2;
  font-size: 25px;
  font-family: var(--poppins);
  margin-left: 30px !important;
  position: relative;
  font-weight: 200;
}

.project-header-name .project-conut h6::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 20px;
  background-color: #A2A2A2;
  top: 3px;
  left: -15px;
}



.project-header-name .project-item {
  font-size: 25px;
  font-family: var(--poppins);
  font-weight: 600;
}



.video-img img {
  width: 100%;
  height: 192px;
}


.project-button .project-create-btn button {
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  background-image: linear-gradient(to right, #36D6FF, #C867FF);
  border: none;
  font-size: 16px;
  font-family: var(--poppins);
  font-weight: 600;
  cursor: pointer;
}

.project-button .project-create-btn button:focus {
  background-color: #fff !important;
  background-image: none !important;
  color: #2fcac9 !important
}

.project-input-box .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background: #fff;
  border: none !important;
  color: #979797;
  padding-top: 10px;
  font-size: 14px;
}

.project-input-box .input-group .input-group-prepen {
  margin-right: 0px !important;
}

.project-input-box .input-group input {
  border: none !important;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 44px;
}

.project-input-box .input-group input:focus {
  box-shadow: none !important;
}

.project-input-box ::placeholder {
  color: #7A7A7A !important;
  font-size: 14px;
  font-family: var(--poppins);
}

.project-input-box {
  margin-right: 20px;
}

.project-banner .bottom-line {
  border-bottom: 1px solid #A2A2A2;
  padding-bottom: 20px;
  display: block;
  width: 98%;
  margin: auto;
}

.project-banner {
  min-height: 100vh;
  padding-bottom: 40px;
  padding-top: 90px;
}



.login-form .form-group label {
  font-size: 20px;
  font-family: var(--poppins);
  color: #C3C3C3;
  margin-left: 10px;
}

.login-form .form-group input {
  height: 50px;
}

.login-form ::placeholder {
  font-size: 16px;
  color: #707070;
  font-family: var(--poppins);
  padding-left: 10px;
}

.btn-change6 {

  box-shadow: 0 0 1px #ccc;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  box-shadow: 0 0 0 #fff inset;
  cursor: pointer;

}






/* .login-form button:hover {
  color: #00b889 !important;
} */


.btn-change6:hover {
  box-shadow: 1000px 0 0 #fff inset;
  transition: .3s ease-in-out;
  cursor: pointer;
}


.medium-btn {

  box-shadow: 0 0 1px #ccc;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  box-shadow: 0 0 0 #fff inset;
  cursor: pointer;
}


.medium-btn:hover {
  box-shadow: 300px 0 0 #fff inset;
  transition: .3s ease-in-out;
  cursor: pointer;
}


.btn-change7 {

  box-shadow: 0 0 1px #ccc;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  box-shadow: 0 0 0 #fff inset;
  cursor: pointer;
}

.btn-change7:hover {
  box-shadow: 400px 0 0 #fff inset;
  transition: .3s ease-in-out;
  cursor: pointer;
}

.toggleupdate {
  background: linear-gradient(to left, #00D3EB, #16B891);
  border: none;
  padding: 7px 30px;
  border-radius: 30px;
  color: #fff;
  height: 45px;
}

.toggleupdate:hover {
  color: #2fcac9;
}

.cancl-btn a {
  color: #fff;
}

.btn-cancl {
  position: relative;
  z-index: 9999;
  display: inline-block;
}

.cancl-btn {
  background: #fff;
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
  color: #000;
  overflow: hidden;
  z-index: 2;
  position: relative;
}

.cancl-btn:hover {
  color: #fff !important;
}


.cancl-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to left, #00D3EB, #16B891);
  width: 0%;
  height: 100%;
  z-index: -1;
  transition: .3s ease-in-out;
  border-radius: 30px;

}

.cancl-btn:hover::before {
  width: 100%;
  transition: .3s ease-in-out;
  color: #fff !important;
}


.fixed-me {
  /*background-image: url('../images/Navbg.png');*/
  background-size: cover;
  background-position: center;

  margin-bottom: 5px;
  position: fixed;
  top: 0;
  z-index: 8888;
  width: 100%;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 50%);
}






.watch-demo-btn button {
  position: relative;
  z-index: 999;
}

/* .watch-demo-btn button::before {
  content: '';
  width: 0%;
  height: 100%;
  background-color: #fff;
  top: 0;
  left: 0;
  position: absolute;
  border-radius: 30px;
  color:#00F5A0;
  transition: .3s ease-in-out;
  z-index: -1;
} */

.watch-demo-btn button:hover::before {
  width: 100%;
  transition: .3s ease-in-out;
  border-radius: 30px;
}

/* 
.watch-demo-btn button:hover{
  color:#00b889 !important; 
  transition: .4s ease-in-out;
} */

.select-box .form-group {
  width: 260px;
  border: 1px solid #00FFD4;
  border-radius: 5px;
  margin-right: 20px !important;
}

.select-box .form-group select {
  border: none !important;
}

.select-box .form-group select:focus {
  box-shadow: none !important;
}

.select-box .form-group select {
  background-color: #101010;
}

.search-select .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background: #fff;
  border: none !important;
  color: #979797;
  padding-top: 10px;
  font-size: 14px;
}

.search-select .input-group .input-group-prepen {
  margin-right: 0px !important;
}

.search-select .input-group input {
  border: none !important;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 44px;
}

.search-select .input-group input:focus {
  box-shadow: none !important;
}

.search-select ::placeholder {
  color: #8b7676 !important;
  font-size: 14px;
  font-family: var(--poppins);
}



.premium-header {
  background-color: #303030;
  padding: 15px 0px;
  margin-top: 65px;
}

.select-box select {
  color: #fff !important;
}


.template-name h4 {
  font-size: 22px;
  font-family: var(--poppins);
  font-weight: 400;
}



.template-number h6 {
  color: #A2A2A2;
  font-size: 22px;
  font-family: var(--poppins);
  margin-left: 30px;
  position: relative;
  font-weight: 200;
}


.template-number h6::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 18px;
  background-color: #A2A2A2;
  top: 4px;
  left: -15px;
}


.template-body {
  padding: 60px 0px;
  background-color: #171717;
  min-height: 100vh;
}

.template-names h6 {
  color: #DEDFDD;
}


/* .template-body .row {
    overflow-y: auto;
    height: 60vh;
} */




.template-body ::-webkit-scrollbar {
  width: 5px;
}

.template-body ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.template-body ::-webkit-scrollbar-thumb {
  background: #00F5A0;
  border-radius: 10px;
  height: 10vh !important;
}


.customModal .modal-dialog .modal-body {
  height: 60vh;
}

.customModal .modal-dialog .modal-footer button {
  background: linear-gradient(to left, #00D3EB, #16B891);
  padding: 7px 25px;
  border: none;
  color: #fff;
  border-radius: 5px;
}

.customModal .modal-dialog .modal-footer button:hover {
  color: #2fcac9;
}

.customModal .modal-dialog .modal-footer button:focus {
  background: #fff;
  color: #2fcac9;
}

.customModal .modal-dialog .modal-footer {
  border: none;
}

.customModal .modal-scrollbar {
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.modal-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.modal-scrollbar::-webkit-scrollbar-thumb {
  background: #00F5A0 !important;
  border-radius: 10px;
  height: 10vh !important;
}



/* .template-body ::-webkit-scrollbar-thumb:hover {
  background: #b30000;
} */



.modal-header .close {
  padding: 0px !important;
  margin: 0px !important;
}

.modal-header {
  width: 100%;
  margin: auto;
}

.modal-header .modal-title span {
  color: #00F5A0;
}

.modal-dialog {
  max-width: 1000px !important;
}


button.close {
  opacity: 0.5;
}

button.close:hover {
  opacity: 0.8;
}

.modal-content {
  background-color: #0c0c0c;
  color: #000 !important;
}

/* .banner-content .modal-dialog .close{
  opacity: 9 !important;
} */

.modal-title button span {
  color: #fff !important;
}

.facebook-item-box {
  border: 1px solid #707070;
  border-radius: 10px;
  background-color: #1C1C1C;
  transition: .4s ease-in-out;
  cursor: pointer;
}

.facebook-item-box:hover {
  border: 1px solid #00F5A0;
  transition: .4s ease-in-out;
  cursor: pointer;
}

.facebook-item-box h6 {
  color: #6A6A6A;
}

.facebook-item-box h5 {
  font-size: 14px;
}

.vidoe-play-icon {
  margin-top: 6px;
}



/* editor css start */


.editor-sec {
  background: #000000;
  min-height: 95vh;

  overflow: hidden;
}

.save-render-sec {
  background-color: #0c0c0c9e;
  padding: 10px;
}

/* .render-projectbtn {
    margin-right: 85px;
} */
.editor-part {
  overflow-y: auto;
  overflow-x: hidden;
  background: #191919;
  padding: 10px 0px;
  height: 650px;
}

.editor-part {
  overflow-y: auto;
  overflow-x: hidden;
  background: #191919;
  padding: 10px 0px;
  height: 650px;
}


.editor-part ::-moz-scrollbar {
  width: 5px !important;
  height: 10em !important;
}

.editor-part ::-moz-scrollbar-track {
  box-shadow: inset 0 0 10px grey !important;
  border-radius: 10px !important;

}

.editor-part ::-moz-scrollbar-thumb {
  background: #00F5A0 !important;
  border-radius: 10px !important;
  height: 10px !important;
}








.editor-part::-webkit-scrollbar {
  width: 5px !important;
  height: 10em !important;
}

.editor-part::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px grey !important;
  border-radius: 10px !important;

}

.editor-part::-webkit-scrollbar-thumb {
  background: #00F5A0 !important;
  border-radius: 10px !important;
  height: 10px !important;
}





.save-render-sec .add-campaign input {
  border-radius: 30px;
  border: none;
  background: #161616;
  width: 290px;
  height: 42px;
}

.save-render-sec ::placeholder {
  color: #A5A5A5;
}

.save-render-sec .add-campaign .form-control {
  color: #A5A5A5;
}

.save-render-sec .save-projectbtn button {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  padding: 7px 25px;
  border-radius: 5px;
  border: none;
  color: #fff;
}

.save-render-sec .render-projectbtn button {
  background: linear-gradient(to right, #A72CE3, #CB3B71);
  padding: 7px 25px;
  border-radius: 5px;
  border: none;
  color: #fff;
}

.editor-tab-menu .nav-item .nav-link h6 {
  color: #fff;
  margin-top: 5px;
  font-size: 12px;
  font-family: var(--poppins);
  margin-bottom: 0px;
}

.editor-tab-menu .nav-item {
  padding: 0px 10px;
}

.editor-tab-menu .nav-item .nav-link.active {
  background: #414141;
  border-radius: 10px;
  padding: 5px 10px;
}

.editor-tab-menu .nav-item .nav-link.active h6 {
  color: #00FFB1;
}

.editor-tab-menu .nav-item .nav-link {
  padding: 5px 10px;
}

.editor-tab-menu .nav-item .nav-link img {
  width: 17px;
}

.editor-tab-menu {
  padding: 10px;
}

.editor-preview {
  padding: 40px 0px;
  background: #333333;
  position: relative;
  /* width: 110%; */
}


.story-part-sec .story-para p {
  font-size: 12px;
  font-family: var(--poppins);
  color: #fff;
}

.story-part-sec .story-para {
  background-color: #242424;
  padding: 20px;
  border-radius: 30px;
  border: 1px solid #707070;
}

.editor-preview-box {
  width: 656px;
  height: 369px;
  overflow: hidden;
  margin: auto;
}

.para-active {
  background-color: #2DA882 !important;
}

.slide-box ul li {
  width: 150px;
  height: 70px;
  border: 1px solid #434343;
  background-color: #101010;
  margin: 0px 10px;
  text-align: center;
  display: flex;
  align-items: center;
  color: #9A9A9A;
  border-radius: 10px;
  font-size: 14px;
}


.slide-sec {
  background-color: #0c0c0c;
  padding: 15px;
}

.slide-box .plus-icon i {
  color: #565656;
}

.start-end-time input {
  width: 100px;
  background: transparent;
  border: none;
  color: #fff !important;
}

.start-end-time input:focus-visible {
  outline: none !important;
}

.start-end-time input.form-control {
  color: #fff;
}

.start-end-time ::placeholder {
  color: #fff;
}


.controls-box {
  background: #000;
  padding: 20px;
  width: 657px;
  margin: auto;
  position: relative;
  z-index: 999;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 3px 5px -1px #000;
}



.controls-box .start-end-time ul li>input:focus {
  box-shadow: none !important;
}

.save-projectbtn button:hover {
  color: #2fcac9;
}

.save-projectbtn button:focus {
  background-color: #fff !important;
  background-image: unset !important;
  color: #2fcac9 !important;
}

.add-campaign input:focus {
  box-shadow: none;
}

.render-projectbtn button:hover {
  color: #2fcac9;
}

.render-projectbtn button:focus {
  background-color: #fff !important;
  background-image: unset !important;
  color: #2fcac9 !important;
}

.style-upload-part {
  background: #303030;
  /* padding: 10px; */
  border-radius: 10px;
}

.style-upload-box {
  background-color: #171717;
  height: 77px;
  border: 1px dashed #707070;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.style-upload-box .custom-file-input {
  position: absolute;
  top: 0;
  opacity: 0;
  background: red;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.style-upload-box h6 {
  font-size: 12px;
  margin-bottom: 0px;
  color: #8F8F8F;
}

.style-upload-box .custom-file {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.position-text-box {
  background: #303030;
  padding: 10px 10px;
  border-radius: 10px;
}

.position-text-box .text-position-dropdown label {
  font-size: 14px;
  margin-left: 14px;
}

.position-text-box .text-position-dropdown .box:hover {
  background-color: #00BF85;
  transition: all .2s;
  border: 2px solid #00BF85;
}

.position-text-box .text-position-dropdown .box.box-active {
  background-color: #00BF85 !important;
  border: 2px solid #00BF85 !important;

}

.position-text-box .text-position-dropdown .box {
  margin: 5px;
  border: 2px solid #707070;
  border-radius: 0;
  background-color: #636363;
  cursor: pointer;
  padding: 10px;
  width: 20px;
  height: 20px;
  color: #fff;
  transition: all .2s;
}

.position-text-box .text-position-dropdown .box-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.remove-logo-switch-btn .form-check label {
  font-size: 14px;
  color: #8F8F8F;
}

.remove-logo-switch-btn .form-check .form-check-input {
  margin-top: 2px;
  margin-left: 10px;
  font-size: 24px;
}

.style-remove-cricle {
  width: 50px;
  height: 50px;
  background-color: #000;
  border-radius: 50%;
  border: 2px solid #686868;
}

.dimmer-wrapper {
  background-color: #303030;
  padding: 10px;
  border-radius: 10px;
}

.dimmer-wrapper .dimmer-light h6 {
  font-size: 14px;
}

.dimmer-switch-btn .remove-logo-switch-btn .form-check-input {
  margin-top: 2px;
  font-size: 24px;
}

.swap-template-wrapper {
  background-color: #303030;
  padding: 10px;
  border-radius: 10px;
}

.swap-template-wrapper .swap-template-box {
  background-color: #101010;
  height: 100px;
}

.swap-template-wrapper h6 {
  font-size: 14px;
}

.editor-tab-menu {
  /* width: 110%; */
  background: #232323;
}



/* media tab css start */




.media-menu-box .nav-item .nav-link {
  color: #fff;
  background-color: #3E3E3E;
  padding: 6px 20px;

  font-size: 13px;
  padding: 9px;
  font-family: var(--poppins);
  text-align: center;

}

.media-menu-box .nav-item .nav-link.active {
  background-color: #fff;
  color: #10CA9C;
  font-size: 13px;
  padding: 9px;
  font-family: var(--poppins);
  text-align: center;
}

.media-search-box .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: #fff;
  border: 1px solid #fff;
  color: #979797;
  cursor: pointer;
}

.media-search-box .input-group input {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border: none;

}

.media-search-box .input-group-prepend {
  margin-left: 0px;
}

.media-search-box input:focus {
  box-shadow: none;
}

.search-laod {
  background-color: #101010;
  height: 100px;
}

.media-upload-box button {
  background: linear-gradient(to right, #B376FF, #4AC6FF);
  border: none;
  color: #fff;
  border-radius: 30px;
}

.media-upload-box {
  position: relative;
}

.media-upload-box .custom-file {
  position: absolute;
  top: 0px;
}

.media-upload-box .custom-file .custom-file-input {
  opacity: 0;
  background-color: red;
  border-radius: 30px;
  font-size: 2px;
  position: absolute;
  top: 0px;
  cursor: pointer;
}

/* media start css */


.credites-wrapper textarea {
  background-color: #0C0C0C;
  border: 1px solid #707070;
}

.credites-wrapper textarea:focus {
  background-color: #0C0C0C;
  box-shadow: none;
}

.credites-wrapper textarea.form-control {
  color: #787878;
}


.credites-wrapper label {
  font-family: var(--poppins);
}

.credites-upload-box .outer-images {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #000;
}

.credites-uplaod {
  position: relative;
  height: 100px;
  background-color: #171717;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #707070
}

.credites-uplaod h6 {
  font-size: 14px;
  color: #8F8F8F;
}

.credites-uplaod .custom-file {
  position: absolute;
  top: 0;
}


.credites-uplaod .custom-file-input {
  position: absolute;
  z-index: 2;
  width: 100%;
  margin: 0;
  opacity: 0;
  background: red;
  height: 261%;
  font-size: 1px;
  cursor: pointer;
}

.credites-upload-box {
  padding: 20px;
  background: #303030;
  border-radius: 10px;
}

.remove-outer-logo .form-check-input {
  margin-left: -5px;
  font-size: 24px;
  margin-top: 0px;
}

.outro-template-box {
  padding: 20px;
  background: #303030;
  border-radius: 10px;
}

.outro-template-box h6 {
  font-family: var(--poppins);
}

.outer-images-box {
  background-color: #000000;
  height: 100px;
}


/* audio editor css start */



.audio-box-sec .audio-box-menu .nav-item .nav-link.active {
  background-color: #fff;
  color: #10CA9C;
  text-align: center;
  padding: 10px;
  font-size: 13px;
  font-family: var(--poppins);
}

.audio-box-sec .audio-box-menu .nav-item .nav-link {
  background-color: #3E3E3E;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
  font-family: var(--poppins);
  padding: 10px;
  text-align: center;
}

.horigental-line {
  border: 1px solid #BEBEBE;
  width: 100%;
  display: block;
}

.audio-music-box .card {
  background: linear-gradient(to bottom, #00EDBC, #009DB0);
  border-radius: 10px;
  transition: .4s ease-in-out;
}

.audio-music-box .card:hover {
  transform: scale(1.05);
  transition: .4s ease-in-out;
}

.audio-music-box .card .card-footer {
  background-color: #54D1CE;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}


.audio-music-box .card .card-title {
  padding: 10px;
  font-size: 14px;
}

.audio-music-box .card-body {
  padding: 0px;
}

.card-music-name h6 {
  font-size: 14px;
}

.audio-slide-box .form-group select {
  background-color: #0C0C0C;
  color: #fff;
  box-shadow: none;
  border-color: #707070;
  font-size: 14px;
}

.audio-language-box .form-group select {
  background-color: #0C0C0C;
  color: #fff;
  box-shadow: none;
  border-color: #707070;
  font-size: 14px;
}

.audio-language-box .form-group label {
  font-size: 12px;
  font-family: var(--poppins);
}

.audio-voice-box .form-group label {
  font-size: 12px;
  font-family: var(--poppins);
}

.audio-slide-box .form-group label {
  font-size: 12px;
  font-family: var(--poppins);
}

.audio-language-box .form-group .form-select {
  padding-right: 0px;
}

.audio-voice-box .form-group select {
  background-color: #0C0C0C;
  color: #fff;
  box-shadow: none;
  border-color: #707070;
  font-size: 14px;
}

.audio-voice-box .form-group .form-select {
  padding-right: 0px;
}

.audio-add-text-box textarea {
  background: #0C0C0C;
  border-color: #707070;

}

.audio-add-text-box input {
  background: #0C0C0C !important;
  border-color: #707070 !important;

}

.audio-add-text-box textarea:focus {
  background: #0C0C0C;
  box-shadow: none;
}

.audio-add-text-box textarea.form-control {
  color: #787878;
}

.tts-box-wrapper {
  background: #303030;
  border-radius: 10px;
  padding: 10px;
}

.audio-preview-btn button {
  background: linear-gradient(to right, #4AC6FF, #B376FF);
  border: none;
  color: #fff;
  border-radius: 50px;
  height: 45px;
}


.audio-preview-btn button:hover {
  color: #2fcac9;
}

.audio-save-use-btn button:hover {
  color: #2fcac9;
}

.audio-save-use-btn button {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  border: none;
  color: #fff;
  border-radius: 50px;
  height: 45px;
}

.audio-library-boxs .audio-upload-btn button {
  border-radius: 30px;
  background: linear-gradient(to right, #4AC6FF, #B376FF);
  border: none;
  height: 45px;
  color: #fff;
}

.audio-upload-btn {
  position: relative;
}






.audio-library-boxs .audio-upload-btn .custom-file-input {
  position: absolute;
  z-index: 2;
  width: 100%;
  margin: 0;
  opacity: 0;
  background: red;
  border-radius: 30px;
  font-size: 1px;
  height: 45px;
  cursor: pointer;
}

.audio-library-boxs .audio-upload-btn .custom-file {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
  top: 0;
}


.upload-box-card .card {
  background: linear-gradient(to bottom, #B94EFF, #DB438F);
  border: none;
  border-radius: 10px;
  transition: .4s ease-in-out;
}

.upload-box-card .card:hover {
  transform: scale(1.05);
  transition: .4s ease-in-out;
}

.upload-box-card .card-footer {
  background: #B240E6;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.upload-box-card .card-title {
  padding: 10px;
  font-size: 14px;
}

.upload-box-card .card-body {
  padding: 0px;
}

.card-play-icon {
  cursor: pointer;
}


.remove-logo-switch-btn .form-check-input:checked {
  background: #C23636;
  box-shadow: none;
  border-color: #C23636;
}

.remove-logo-switch-btn .form-switch .form-check-input:checked {
  background-position: right center !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-repeat: no-repeat !important;
  box-shadow: none;
}


.remove-logo-switch-btn .form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  box-shadow: none;
}



.dimmer-switch-btn .remove-logo-switch-btn .form-check-input:checked {
  background: #00BF85;
  box-shadow: none;
  border-color: #00BF85;
}


.dimmer-switch-btn .remove-logo-switch-btn .form-switch .form-check-input:checked {
  background-position: right center !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-repeat: no-repeat !important;
  box-shadow: none;
}


.dimmer-switch-btn .remove-logo-switch-btn .form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  box-shadow: none;
}


header .editormenu {
  left: -182px !important;
}

.slide-box {
  overflow-x: auto;
  overflow-y: hidden;
}

.text-font-size {
  width: 120px;
}

.text-box-sec .text-box-wrapper select {
  background-color: #0C0C0C;
  color: #fff;
}

.text-box-sec .text-box-wrapper .text-font-style select {
  border-color: #00F5A0;
}


.font-style-boxs .btn-group button {
  background-color: #0C0C0C;
  margin: 0px 10px;
  border-radius: 5px !important;
}



.profile-sec {
  background-color: #E5E3F2;
  padding: 120px 0px 60px;
  min-height: 100vh;
}

.user-img {
  position: relative;
  width: 100px;
  height: 100px;

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  overflow: hidden;
}

.user-img .custom-file {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 65px);
  margin-bottom: 0;
  top: 0;
  left: 0;
  background: red;
  font-size: 1px;
  border-radius: 50%;
  opacity: 0;
}


.user-img .custom-file-input {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  background: orange;
  border-radius: 100%;
  cursor: pointer;
}

.user-details h5 {
  font-size: 24px;
  font-family: var(--poppins);
  color: #fff;
}

/* .user-details button{
  padding: 10px 30px;
  border:none;
  background:linear-gradient(to right,#16B891 ,  #00D3EB);
  border-radius: 30px;
  color:#fff;
  font-size: 16px;
  font-weight: 400;
  font-family: var(--poppins);
} */

.user-details .user-img span {
  color: #14BB9B;
}


.form-box .form-group input {
  background-color: transparent !important;
  border: none;
  padding: 0px;
}

.form-box .form-group textarea {
  background-color: transparent !important;
  border: none;
  padding: 0px;
  height: 100px;
}

.form-box .form-group select {
  /* background-color: transparent !important; */
  border: none;
  padding: 0px;
  background: #1e1e1e !important;
}

.form-box .form-group select:focus {
  box-shadow: none;
  outline: none !important;
}

.form-box .form-group input:focus {
  box-shadow: none !important;
}

.form-box .form-group {
  background-color: #E1DFF2;
  color: #fff;
  padding: 5px 20px;
  border-radius: 10px;
  border: 1px solid #707070;
}

.formactive {
  border: 1px solid #00F8B8 !important;
}

.form-box .form-group .form-control {
  color: #2a2a2a !important;
  font-weight: 200;
  font-size: 14px;
  font-family: var(--poppins);
}

.form-box .form-group ::placeholder {
  color: #6c757d !important;
}


.personal-details-box h6 {
  font-size: 20px;
  font-family: var(--poppins);
  color: #606060;
}

/* .form-box button{
  background: linear-gradient(to right , #36D6FF , #C867FF);
  border-radius: 10px;
  border:none;
  height: 45px;
  color:#fff;
} */

/* .form-box button:hover{
  color:#2fcac9 !important
} */

/* .form-box button:focus{
  background: #fff;
  color:#2fcac9;
} */
.btn-submit {
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  margin: 0 auto;
}

.form-box {
  background-color: #fff;
  padding: 50px;
  border-radius: 30px;
}


.gdpr-sec {
  padding: 80px 0px 80px;
  min-height: 100vh;
  background: #171717;
}

.gdpr-update-email {
  padding: 30px;
  background: #000000;
  border-radius: 10px;
}

.gdpr-sec .gdpr-update-email .form-group input {
  background-color: transparent;
  border: none;
  padding: 0px;
}

.gdpr-update-email .form-group {
  background: #1E1E1E;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid #1E1E1E;
}




.gdpr-update-email .form-group input:focus {
  box-shadow: none !important;
}

.gdpr-update-email .form-group .form-control {
  color: #fff;
  font-weight: 200;
  font-size: 14px;
}

.gdpr-update-email .form-group ::placeholder {
  color: #6c757d;
}

.gdpr-update-btn button {
  background: linear-gradient(to left, #FF8F4B, #FF3D38);
  border: none;
  color: #fff;
  border-radius: 30px;
  padding: 7px 30px;
  font-size: 16px;
}

.gdpr-update-btn button:hover {
  color: #FF3D38;
}

.gdpr-update-btn button:focus {
  background: #fff;
  color: #2fcac9;
}


.manage-data-box {
  background: #000000;
  padding: 40px 20px;
  border-radius: 10px;
}

.manage-data-box p {
  font-size: var(--poppins);
}

.manage-data-box p span {
  color: #f27277;
}

.manage-update-btn button {
  border: none;
  padding: 7px 20px;
  border-radius: 30px;
  color: #fff;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.manage-update-btn button::before {
  content: '';
  position: absolute;
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 30px;
  z-index: -1;
  transition: .3s ease-in-out;
  overflow: hidden;
}

.manage-update-btn button:hover::before {
  width: 100%;
  transition: .3s ease-in-out;
  overflow: hidden;
}

.manage-update-btn button:hover {
  color: #2fcac9;
  position: relative;
  z-index: 999;
  overflow: hidden;
}



.add-account-sec {
  padding: 60px 0px;
  background: #171717;
  min-height: 100vh;
}

.add-account-box {
  background: #000000;
  padding: 40px;
  border-radius: 10px;
}

.add-account-box .form-group input {
  background: transparent;
  border: none;
  padding: 0px;
}

.text-muted {
  color: #717171 !important;
}


.add-account-box .form-group input:focus {
  box-shadow: none;
}

.add-account-box .form-group ::placeholder {
  color: #6c757d;
  font-weight: 200;
}

.add-account-box .form-group .form-control {
  color: #fff;
  font-weight: 200;
}

.add-account-box .form-group select {
  background: transparent;
  border: none !important;
  padding: 0px;
  text-align: left;
  height: 24px !important;
  color: #6c757d;
}

.add-account-box .form-group select:focus {
  box-shadow: none;
}

.add-account-box .form-group {
  background: #1E1E1E;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid #1E1E1E;
}

.add-account-box .form-group:focus-within {
  border-color: #FFB300 !important;
}

.add-account-save-btn button {
  background: linear-gradient(to left, #00D3EB, #16B891);
  border: none;
  padding: 7px 30px;
  color: #fff;
  font-family: var(--poppins);
  border-radius: 30px;
}


.add-account-save-btn button:hover {
  color: #2fcac9;
}

.add-account-save-btn button:focus {
  background: #fff;
  color: #2fcac9;
}

.team-member-box {
  background: #000000;
  padding: 40px;
  border-radius: 10px;
}

.text-wrapper {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 150px;

}


.help-support-banner {
  background-image: url('images/help-support-bg.png');
  margin-top: 65px;
  overflow: hidden;
  height: 620px;
  background-repeat: no-repeat;
  background-size: cover;
}

.important-sec:before {
  content: '';
  background-image: url('images/help-suppot-banner-down.png');
  background-size: contain;
  background-repeat: no-repeat;
  height: 200px;
  width: 100%;
  position: absolute;
  top: 0px;
  display: block;
  margin-top: -62px;
}

.customer-sec {
  padding: 60px 0px;
  background-color: #fff;
}

.customer-content h6 {
  color: #000;
  font-weight: 300;
  font-size: 25px;
}

.customer-content h5 {
  font-size: 32px;
  font-family: var(--poppins);
  font-weight: 600;
}

.customer-content a {
  padding: 10px 30px;
  background-color: #01D084;
  color: #fff;
  border-radius: 30px;
  margin-top: 10px;
  display: inline-block;
  font-weight: 600;
}

/* .customer-content a:hover{
  color:#01D084;
  font-weight: 600;
} */

.gdpr-update-email .form-group:focus-within {
  border-color: #01D084 !important;
}

.form-group:focus-within {
  border-color: #FFB300 !important;
}

.secarchicone-part #searchimg {
  position: relative;
  left: 40%;
}



.help-banner-content .input-group .input-group-text {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  margin-left: 0px;
  background-color: #ffffff;
  border: none;
  color: #7A7A7A;
  font-size: 20px;
  padding-right: 0px;
  cursor: pointer;
}

.help-banner-content .input-group .input-group-prepend {
  margin-right: 1px;
}

.help-banner-content .input-group input {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  margin: 0px;
  background-color: #fff;
  border: none;
}

.help-banner-content .input-group input:focus {
  box-shadow: none;
}

.help-banner-content .input-group ::placeholder {
  font-family: var(--poppins);
  color: #7A7A7A;
  font-size: 20px;
}

.help-banner-content .input-group .form-control {
  color: #000;
  font-size: 18px;
}


.help-banner-content h3 {
  font-size: 42px;
  font-family: var(--poppins);
  font-weight: 600;
}

.help-banner-content .input-group.mt-4 {
  height: 50px;
}

.help-banner-content h3 span {
  color: #F5CC00;
}

.important-topics span {
  width: 100px;
  height: 100px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}


.important-topics span img {
  max-width: 50%;
}

.important-sec {
  background-color: #fff;
  padding: 60px 0px;
  position: relative;
}


.important-topics {
  background: linear-gradient(to bottom, #00E2A4, #00B5DF);
  padding: 40px;
  border-radius: 15px;
  transition: .4s ease-in-out;
  height: 247px;
}

.important-topics:hover {
  transform: scale(1.02);
  transition: .4s ease-in-out;
}

.popular-see {
  /* background-color: #000000; */
  background: linear-gradient(250deg, #72168D, #FF3E53);
  padding: 60px 0px;
}

.popular-video-sec h3 {
  font-size: 26px;
  font-weight: 600;
  font-family: var(--poppins);
}

.important-sec h3 {
  font-size: 26px;
  font-weight: 600;
  font-family: var(--poppins);
}

.player-box {
  background: #fff;
  /* background: linear-gradient(to bottom , #00FDC3, #2DA8C7); */
  padding: 10px;
  border-radius: 10px;
}

.support-sec {
  padding: 60px 0px;
  background-color: #242424;
}

.support-content h6 {
  font-size: 25px;
  font-family: var(--poppins);
  color: #00F5A0;
}

.support-content h3 {
  font-size: 40px;
  font-family: var(--poppins);
}

.support-content p {
  font-size: 18px;
  width: 90%;
  font-family: var(--poppins);
}

.support-content button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  color: #fff;
  padding: 7px 30px;
  border-radius: 30px;
  border: none;
}

.upgrade-menu .nav .nav-link:hover {
  background-color: #00BBAE;
}

.support-content button:hover {
  color: #2fcac9;
}

.support-content button:focus {
  background: #fff;
  color: #2fcac9;
}

.traning-sec {
  background-color: #232323;

  min-height: 100vh;
}

.popular-topics-content {
  background-color: #171717;
  padding: 20px 30px;
  min-height: 100vh;
}

.popular-topics-content .vidoebtn {
  background: linear-gradient(to right, #00D3EB, #16B891);
  padding: 10px 30px;
  border: none;
  border-radius: 30px;
  color: #fff;
}

.popular-topics-box .nav {
  background: #000000;
  border-radius: 10px;
  overflow: hidden;
  padding: 0px 0px 20px 0px;
}

.popular-topics-box .nav .nav-link {
  color: #fff;
  padding-left: 40px;
}


.popular-topics-box .nav .nav-link.active {
  background-color: #00BBAE;
}



.integration-box .img-wrraper {
  min-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.integration-box li {
  max-width: 220px;
  width: 100%;
  background-color: #000000;
  text-align: center;
  padding: 20px;
  margin: 0 12px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 40%), 0 0 20px 0 rgb(0 0 0 / 40%);
  display: block;
  color: #fff;
  cursor: pointer;
  transform: scale(1.05);
  transition: .4s all;
}

.integration-box li:hover {
  transform: scale(1);
  transition: .4s all;
}


.integration-box ul li a {
  color: #fff;
}


.integration-sec {
  background: #171717;
  padding: 120px 0px 60px;
}


.my-connection {
  background: #171717;
  padding: 60px 0px;
}

.connection-box {
  background: #000000;
  padding: 20px;
  border-radius: 10px;
  scroll-margin: 80px 0 0 0;
}

.facebook-delete button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #fff;
}

.facebook-connection {
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
}

.facebook-connect-account button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #fff;
}

.facebook-connect-account button:hover {
  color: #2fcac9;
}

.facebook-connect-account button:focus {
  background: #fff;
  color: #2fcac9;
}

.facebook-delete button:hover {
  color: #2fcac9;
}

.facebook-delete button:focus {
  background: #fff;
  color: #2fcac9;
}

.youtube-connect button:hover {
  color: #2fcac9 !important;
}

.youtube-connect button:focus {
  background: #fff;
  color: #2fcac9;
}

.youbtube-connection .youtube-connect button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  border-radius: 30px;
  padding: 10px 30px;
  border: none;
  color: #fff;
}

.webinar-sec {
  padding: 60px 0px;
  /*background: url('../images/webnibeimg.png') 50%/cover no-repeat;*/


}

.webiner-content span {
  color: #fff;
}

.webiner-content h3 {
  font-size: 30px;
  font-family: var(--Montserrat);
  color: #fff;
}

.webiner-content h3 span {
  color: #F7FF00;
}


.webiner-content p {
  font-size: 18px;
  font-family: var(--poppins);
  color: #fff;
}

/* 
.webiner-content button{
  background:linear-gradient( to right, #00C2FF ,#00FFC2);
  border-radius: 30px;
  padding: 10px 30px;
  border:none;
  color:#000;
  font-weight: 600;
}

.webiner-content button:hover{
  color: #00b889 !important;
} */

.warraper {
  min-height: 100vh;
  background: #171717;
}


.upgrads-sec {
  padding: 120px 0px 60px;
  background: #171717;
  min-height: 100vh;
}


.upgrade-menu {
  background: #000000;
  border-radius: 10px;
  overflow: hidden;
}

.upgrade-menu .nav .nav-link {
  color: #fff;
  padding: 10px 40px;

}

.upgrade-menu .nav .nav-link.active {
  background-color: #00BBAE;

}

.upgrade-content {
  background-color: #000000;
  padding: 20px 30px;
  min-height: 50vh;
}

.upgrade-box-content {
  background-color: #171717;
  border-radius: 10px;
  overflow: hidden;
}

.upgrade-heading h6 {
  background: #00BBAE;
  padding: 10px;
}

.upgrade-para {
  padding: 10px;
}

.upgrade-box-content button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  padding: 7px 30px;
  border-radius: 30px;
  border: none;
  color: #fff;
}


.upgrade-para button {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.upgrade-para button:hover {
  color: #2fcac9;

}

.upgrade-para button:focus {
  background: #fff !important;
  color: #2fcac9 !important;
}

.upgrade-box-content button:hover {
  color: #2fcac9;
}

.upgrade-box-content button:focus {
  background: #fff !important;
  color: #2fcac9;
}

.tutorial-content .upgrade button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  padding: 10px 30px;
  border: none;
  color: #fff;
  border-radius: 30px;

}

.tutorial-content .upgrade button {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 257px;
}

.tutorial-content .upgrade button:hover {
  color: #2fcac9;
}

.tutorial-content .upgrade button:focus {
  background: #fff;
  color: #2fcac9;
}


.modal .ecommercebox {
  max-width: 600px !important;
}


.ecommercebox .modal-body .form-group {
  width: 80%;
  margin: auto;
}

.ecommercebox .modal-body .modal-scrollbar {
  display: flex;
  align-items: center;
  justify-content: center;
}


.ecommercebox .modal-body .modal-scrollbar .form-group input {
  background: #1E1E1E;
  height: 50px;
}

.ecommercebox .modal-body .modal-scrollbar .form-group input:focus {
  border-color: #01D084;
  box-shadow: none;
}

.ecommercebox .modal-body .modal-scrollbar .form-group .form-control {
  color: #fff;
}

.ecommercebox .modal-body .modal-scrollbar {
  height: 40vh;
}



.ecommercebox .modal-body .modal-scrollbar .form-group button {
  background: linear-gradient(to right, #00D3EB, #16B891);
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
  color: #fff;
}

.ecommercebox .modal-body .modal-scrollbar .form-group button:hover {
  color: #2fcac9;
}

.ecommercebox .modal-body .modal-scrollbar .form-group button:focus {
  background: #fff;
  color: #2fcac9;
}

.hide-table-padding .form-group {
  background-color: #1E1E1E;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #1E1E1E;
}

.hide-table-padding .form-group:focus-within {
  border-color: #01D084;
}

.hide-table-padding .form-group label {
  font-size: 14px;
  color: #ffffffbd;
}

.brandonfocus:focus-within {
  border-color: #01D084;
  box-shadow: none;
}

.hide-table-padding .form-group input {
  background-color: transparent;
  border: none;
  padding: 0px;
}

.hide-table-padding .form-group input:focus {
  box-shadow: none;
}

.hide-table-padding .form-group .form-control {
  color: #fff !important;
  font-weight: 200;
}

.hide-table-padding ::placeholder {
  color: #6c757d;
}


.template-cover {
  background-image: linear-gradient(to bottom, #00FDC3, #2DA8C7);
  border-radius: 10px;
  padding-top: 1px;
}

.template-cover .template-img {
  border-radius: 10px;
  position: relative;
  margin: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 50%);
}

.template-time {
  position: absolute;
  background-color: #fff;
  padding: 1px 10px;
  color: #000;
  top: 5px;
  left: 5px;
  display: inline-block;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
}

.template-names {
  padding: 0px 0px 5px;
}

.template-img:hover .ClipsReel-overlay {
  opacity: 1 !important;
  transition: .8s ease-in-out !important;
  clip-path: circle(70.7% at 50% 50%) !important;
}


/** Custom */
.my-account-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #16d489;
}

.hide-social {
  display: none !important;
}


.sweet-alert {
  color: #0C0C0C !important;
}

.modal {
  z-index: 99999;
}

.show-modal {
  display: block !important;
}

.load-icon-help {
  color: #02c98c;
  font-size: 30px;
}

.webinar-sec {
  padding: 60px 0px;
  background: url('../src/images/webnibeimg.png') 50%/cover no-repeat;


}

.webiner-content span {
  color: #fff;
}

.webiner-content h3 {
  font-size: 30px;
  font-family: var(--Montserrat);
  color: #fff;
}

.webiner-content h3 span {
  color: #F7FF00;
}


.webiner-content p {
  font-size: 18px;
  font-family: var(--poppins);
  color: #fff;
}

/* .webiner-content button{
  background:linear-gradient( to right, #00C2FF ,#00FFC2);
  border-radius: 30px;
  padding: 10px 30px;
  border:none;
  color:#000;
  font-weight: 600;
}

.webiner-content button:hover{
  color: #00b889 !important;
} */

.accnt-mngmt-btn:hover {
  color: #00b889 !important;
}

.upload-btn-wrapper .custom-file-input {
  position: absolute;
  top: 0;
}


.script-sec {
  padding: 120px 0px 60px;
  background-color: #262626;
  min-height: 100vh;
}

.script-sec .form-group label {
  color: #fff;
}

.script-sec .form-group input {
  background-color: #0D0D0D;
  height: 49px;
}

.script-sec .form-group input:focus {
  border-color: #00FFB1;
  box-shadow: none;
}

.script-sec .form-group textarea {
  background-color: #0D0D0D;
  border-color: #707070;

}

.script-sec .form-group textarea:focus {
  border-color: #00FFB1;
  box-shadow: none;
}

.script-sec .form-group .form-control {
  color: #fff;
}

.script-create-btn button {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  color: #fff;
  border: none;
  padding: 10px 30px;
  font-family: var(--poppins);
  font-weight: 500;
  border-radius: 5px;
}

.script-create-btn a {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  color: #fff;
  border: none;
  padding: 10px 30px;
  font-family: var(--poppins);
  font-weight: 500;
  border-radius: 5px;
}

.script-create-btn a:hover {
  color: #2fcac9;
}


.script-create-btn button:hover {
  color: #2fcac9;
}


.secarchicone-part {
  position: relative;
  animation: mymove 3s;
  animation-iteration-count: 5;
}

@keyframes mymove {
  from {
    transform: scale(0.09);
  }

  to {
    transform: scale(1.1);

  }
}


.fb-card .youtubeicon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-60%, -30%);
  font-size: 30px;

}


.script-create-btn button:focus-within {
  color: #2fcac9 !important;
  background: #fff !important;
}

.active-dim {
  border: 1px solid #00f5a0;
}

.active-slide {
  border-color: #00f5a0 !important;
}

.modal-backdrop {
  position: unset !important;
}

.cursor-pointer {
  cursor: pointer;
}

.hide-box {
  display: none !important;
}

.story-loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.preview-text {
  position: absolute;
  bottom: 0;
  padding: 20px;
}

/*color picker*/
.swatch {
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
  display: inline-block;
  cursor: pointer;
  width: 100%;
  padding: 10px;
}

.swatch-color {
  width: 100%;
  height: 14px;
  border-radius: 2px;
}

.swatch-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.sketch-picker {
  position: absolute;
  z-index: 999;
}

.templateModel {
  margin: auto;
  max-width: 450px;
  height: auto;
}


.templateModel .modal-body {
  height: auto !important;
  box-shadow: 0 0 5px rgba(255, 255, 255, .1);
}

.btn-custom {
  background: linear-gradient(to right, #00FFBA, #3B91CB);
  color: #fff;
  border: none;
  padding: 10px 30px;
  font-family: var(--poppins);
  font-weight: 500;
  border-radius: 5px;
}

.btn-custom:hover {
  color: #2fcac9;
}

.preview-text span div {
  display: inline;
}

.highlight-text {
  background-color: yellow;
}


.search-load-box ::-webkit-scrollbar {
  width: 5px;
}

.search-load-box ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.search-load-box ::-webkit-scrollbar-thumb {
  background: #00F5A0;
  border-radius: 10px;
  height: 10vh !important;
}

.scroll-bar ::-webkit-scrollbar {
  width: 5px;
}

.scroll-bar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.scroll-bar ::-webkit-scrollbar-thumb {
  background: #00F5A0;
  border-radius: 10px;
  height: 10vh !important;
}

/* Infinite scroll */
.infinite-scroll-component {
  display: flex;
  flex-wrap: wrap;
}


.slide-active {
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #10ca9c;
}

.slide-active i {
  color: #10CA9C;
  transform: scale(1.5);
}

.loader-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.delete-media {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  filter: drop-shadow(2px 4px 6px black);
  width: unset !important;
  height: unset !important;
}

.delete-media svg {
  position: absolute;
  right: 5px;
  top: 5px;
}

.delete-media i {
  color: #d20424;
}

.disable-click {
  pointer-events: none
}

.custom-anchor {
  text-decoration: underline !important;
  cursor: pointer;
}



/* Modified */
.dashborad-sec-3 {
  background-color: #15191e;
}

.dashborad-sec-3-content h6 {
  color: #FFAA00;
}

.dashborad-sec-3-content h4 {
  font-size: 30px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #FFF;
}

.dashborad-sec-3-content h4:hover {
  color: #FF8F4B;
  font-weight: 400;
}

.banner-video-project .video-project h5 {
  color: #FFF;
}

.banner-video-project .video-project h5 span {
  color: #FFAA00;
}

header .dropdown-item {
  font-size: 15px;
  color: #000;
}

header .dropdown-item:hover {
  color: #FFF;
}

.profile-sec {
  background: #171717;
}

.form-box {
  background: #000;
}

.form-box .form-group {
  background-color: #1e1e1e;
  color: #fff;
  padding: 5px 20px;
  border-radius: 10px;
  border: 1px solid #707070;
}

.form-box .form-group.forSelect {
  padding-left: 0;
  padding-right: 0;
}

.form-box .form-group.forSelect select {
  padding-left: 15px;
  padding-right: 15px;
}

.form-box .form-group .form-control {
  color: #fff !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  font-size: 14px;
}

.text-muted {
  color: #b1b1b1 !important;
  font-size: 14px;
}

.personal-details-box h6 {
  color: #FFF;
}

.footer .footer-menu ul li a:hover {
  color: #FF8F4B;
}

.manage-data-box p {
  font-size: 16px;
}

.manage-update-btn button {
  font-size: 16px;
}

.upgrade-menu .nav .nav-link {
  font-size: 16px;
}

.upgrade-para {
  font-size: 16px;
}

.upgrade-content {
  min-height: calc(100vh - 220px);
}

.upgrade-heading h6 {
  background: #FF8F4B;
}

.upgrade-menu .nav .nav-link {
  padding-top: 13px;
  padding-bottom: 13px;
}

.upgrade-menu .nav .nav-link:hover {
  background: #FF8F4B;
}

.upgrade-menu .nav .nav-link.active {
  background: #FF8F4B;
}

.upgrads-sec {
  font-size: 16px;
}

.upgrade-box-content button {
  background: linear-gradient(to right, #FF8F4B, #FF3D38);
}

.upgrade-box-content button:hover {
  color: #FF3D38 !important;
}

.tutorial-content .upgrade button {
  background: linear-gradient(to right, #FF8F4B, #FF3D38);
}

.tutorial-content .upgrade button:hover {
  color: #FF3D38;
}

.upgrade-box-content.alt {
  background: none;
}

.ibox-content .btn-primary {
  background-color: #FF8F4B !important;
  border-color: #FF8F4B !important;
}

.facebook-connect-account button {
  background: linear-gradient(to right, #FF8F4B, #FF3D38);
}

.facebook-connect-account button:hover {
  color: #FF3D38;
}

.add-account-save-btn button {
  background: linear-gradient(to right, #FF8F4B, #FF3D38);
}

.add-account-save-btn button:hover {
  color: #FF3D38;
}

.toggleupdate {
  background: linear-gradient(to right, #FF8F4B, #FF3D38);
}

.toggleupdate:hover {
  color: #FF3D38;
}

.add-account-sec input:-webkit-autofill,
.add-account-sec input:-webkit-autofill:hover,
.add-account-sec input:-webkit-autofill:focus,
.add-account-sec input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #1E1E1E inset !important;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.8) !important;
  border: none !important;
  border-radius: 0 !important;
}

.project-input-box {
  margin-right: 0 !important;
}

.my-video-projects-box-details .video-play {
  display: none !important;
}

.video-title {
  margin-left: 0;
  text-align: center !important;
}

.card-box-overlay .video-dropdown.dropdown {
  display: none !important;
}

.my-video-projects .card-box-date {
  text-align: center !important;
}

.my-video-projects .card-box-edit-more li a:hover {
  color: #FFB300 !important;
}

.my-video-projects .card-box-edit-more li a:hover i {
  color: #FFB300 !important;
}

.integration-box li p {
  font-size: 16px;
}

.my-connection,
.integration-sec {
  font-size: 16px;
}

.my-video-projects .card {
  border: none !important;
}

.banner-video-project .video-project a span {
  color: #FFF;
  font-size: 20px;
}

.banner-video-project .video-project a:hover span {
  color: #FF8F4B !important;
}

.text-white a {
  color: #FFF;
}

.popular-topics-box .nav .nav-link {
  font-size: 17px;
}

.popular-topics-box .nav .nav-link.active {
  background: #FF8F4B;
}

.popular-topics-content .vidoebtn {
  font-size: 16px;
  background: linear-gradient(to right, #FF8F4B, #FF3D38);
}

.popular-topics-content .vidoebtn:hover {
  color: #FF3D38;
}

.integration-box .img-wrraper img {
  transition: all 0.5s ease;
}

/* .integration-box li:hover .img-wrraper img{ filter: grayscale(0%);} */
/*.connection-box .connection-img img{ filter: grayscale(100%);}*/
/*.connection-box .youtube-logo img{ filter: grayscale(100%);}*/
.imgsizeright {
  margin-left: -200px;
}

.banner-video-project:before {
  background-position: center center;
}

.container {
  max-width: 1400px;
}

.upgrade-content {
  min-height: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.footer-copyright p a {
  color: #ff9100 !important;
}


.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.fileLoad {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.95);
}

#v-pills-client img {
  width: auto !important;
  max-width: 100% !important;
}

/* body{ padding-top: 65px !important;} */
.integration-box li {
  transition: all 0.9s ease;
}

/* .integration-box li:hover{ 
  background: linear-gradient(180deg,#ff8d4b,#ff4139);
  box-shadow: 0 0 20px 0 #ff8649, 0 0 20px 0 #ff4c3b;
  transform: scale(1.1);
} */


body {
  padding-top: 60px;
}

.help-support-banner {
  margin-top: 0;
}

.profile-sec {
  padding-top: 60px;
}

.gdpr-sec {
  padding-top: 1px;
}

.footer .footer-menu ul li a:hover {
  color: blue;
}

.footer-copyright a {
  color: #00F5A0;
}

/* .integration-box li:hover{ background: linear-gradient( 180deg ,#FF8F4B,#FF3D38); box-shadow: 0 0 20px 0 #FF8F4B, 0 0 20px 0 #FF3D38; transform: scale(1.1) !important;} */
.user-img .custom-file {
  z-index: 999;
}

.imgEdit {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

.user-img:hover .imgEdit {
  opacity: 1;
  visibility: visible;
}

.imgsizeright {
  margin-left: -230px;
}

.commonModal {
  background: rgba(0, 0, 0, 0.5);
}

.commonModal .modal-content {
  background: none !important;
  border: none !important;
}

.commonModal .modal-body {
  background: #000;
  overflow: hidden;
  border-radius: 20px;
  padding: 25px;
}

.commonModal .embed-responsive {
  width: 100%;
  height: 0;
  padding-bottom: 56%;
  position: relative;
  aspect-ratio: 16 / 9;
}

.commonModal .embed-responsive iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}

.commonModal .modal-dialog {
  max-width: 1130px !important;
}

.commonModal .close {
  position: absolute;
  right: 0;
  top: -42px;
  opacity: 1 !important;
}

.modal {
  display: block !important;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

.modal.show-modal {
  opacity: 1;
  visibility: visible;
}

.commonModal .modal-dialog {
  transform: scale(0);
  transition: all 0.5s ease;
}

.commonModal.show-modal .modal-dialog {
  transform: scale(1);
}

.site-nav>ul>li {
  float: left;
  position: relative;
}

.site-nav>ul>li>a {
  display: inline-block;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1em;
  color: #2c2c2c;
  padding: 30px 30px 17px 30px;
}

.site-nav>ul>li:hover>a,
.site-nav>ul>li>a.active {
  background: #023e87;
  color: #FFF;
}

.site-nav>ul>li.has-sub>a {
  padding-right: 34px;
}

.subDropAlt {
  position: absolute;
  right: 15px;
  top: 26px;
}

.subDropAlt::before {
  content: '\f0d7';
  font-family: FontAwesome;
  color: #000;
  font-size: 1.2em;
}

.site-nav>ul>li:hover>ul {
  opacity: 1;
  visibility: visible;
  top: 70px;
}

.site-nav>ul>li:hover>.subDropAlt::before,
.site-nav>ul>li.active .subDropAlt::before {
  color: #FFF;
}

.site-nav>ul>li ul {
  position: absolute;
  left: 0;
  top: 75px;
  z-index: 99;
  background: #023e87;
  width: 200px;
  min-width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

.site-nav>ul>li ul li a {
  color: #FFF;
  font-size: 0.85em;
  padding: 10px 20px;
  padding-top: 12px;
  display: block;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.site-nav>ul>li>ul>li:hover>a {
  background: #f73131;
  color: #FFF;
}

.site-nav>ul>li ul li .subDropAlt {
  top: 8px;
}

.site-nav>ul>li ul li .subDropAlt::before {
  color: #FFF !important;
}

.site-nav>ul>li ul li:hover>.subDropAlt::before {
  color: #FFF;
}

.site-nav>ul>li ul li {
  position: relative;
}

.site-nav>ul>li ul li ul {
  top: 0;
  left: 100%;
  background: #1e47b4;
}

.site-nav>ul>li>ul>li:hover>ul {
  opacity: 1;
  visibility: visible;
  top: 0;
}

.site-nav>ul>li ul li ul li a:hover {
  background: #f73131;
  color: #FFF;
}

.switch input {
  opacity: 0;
}

.backgroundCanvas {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}

.scale-video video {
  z-index: 2;
}

.logoCanvas {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 4;
}

.overlayCanvas {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 3;
}

/* .scale-video canvas{ width: 100% !important; height: 100% !important;} */

.preview-bg {
  background-color: #202020
}

.vidPre5 {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.vidPre5 .vidPre5-left {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0;
  top: 0;
}

.vidPre5 .vidPre5-left.screen {
  left: 50%;
  right: 0;
}

.vidPre5 .vidPre5-left.screen.right-top {
  height: 50%;
}

.vidPre5 .vidPre5-left.screen.right-bottom {
  height: 50%;
  top: 50%;
}

.go-live-box-footer-row ul li {
  position: relative;
}

.addStbtn {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

.addStbtn button {
  background-image: linear-gradient(to right, #FF8F4B 0%, #FF3D38 51%, #FF3D38 100%);
  color: #FFF;
  border: none !important;
  border-radius: 50px;
  font-size: 13px;
  padding-bottom: 2px;
  padding-left: 12px;
  padding-right: 12px;
}

.go-live-box-footer-row ul li:hover .addStbtn {
  opacity: 1;
  visibility: visible;
}

.commonModal-sm .modal-dialog {
  max-width: 600px !important;
}

.box-form-control .btn.popBtn {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.popTitle {
  font-size: 20px;
  margin: 0 0 15px 0;
  padding: 0 0 15px 0;
  border-bottom: 1px solid #565656;
}

.hh {
  text-decoration: none;
}

.content-box h6 {
  position: relative;
}

.downBtn {
  position: absolute;
  right: 0;
  top: 0;
  color: #9BF5FF !important;
}

.downBtn svg {
  color: #9BF5FF;
}

/* .footerEdit .row{ align-items: center; justify-content: center;}
.footerEdit [class*='col-']{ -ms-flex-preferred-size: 0 !important;
  flex-basis: 0 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  max-width: 100% !important; } */

@media screen and (max-height: 800px) {
  .commonModal .modal-dialog {
    max-width: 940px !important;
  }

  .commonModal-sm .modal-dialog {
    max-width: 600px !important;
  }
}


.add-account-sec {
  padding-top: 1px;
}

.upgrads-sec {
  padding-top: 40px;
}

.support-data {
  font-size: 16px;
  padding-bottom: 20px;
}

.project-banner {
  padding-top: 40px;
}

.logoimg {
  margin-top: -60px;
}


@media (max-width: 1600px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 1699px) {
  .imgsizeleft {
    margin: 0 !important;
    padding: 0 !important;
  }

  .imgsizeleft img {
    width: 100% !important;
    max-width: 100% !important;
  }

  .imgsizeright {
    margin-left: 0 !important;
  }

  .imgsizeright img {
    width: 100% !important;
    max-width: 100% !important;
  }

  .banner-content h1 {
    font-size: 60px;
  }

  .banner-content h4 {
    font-size: 30px;
  }

  .footer .footer-copyright p {
    font-size: 14px;
  }

  .footer .footer-menu ul li a {
    font-size: 14px;
  }
}

@media (max-width: 1199px) {
  .container {
    max-width: 960px;
  }

  .login-form h4 {
    font-size: 23px;
  }

  .login-form {
    padding: 35px;
  }

  .login-content h1 {
    font-size: 45px !important;
  }

  .login-content p {
    font-size: 15px;
  }

  .login-content h5 {
    font-size: 23px;
  }

  .login-form label {
    font-size: 17px;
  }

  .footer .footer-copyright p {
    font-size: 13px;
  }

  .footer .footer-menu ul li a {
    font-size: 13px;
  }

  .banner-content h1 {
    font-size: 50px;
  }

  .banner-content h4 {
    font-size: 30px;
  }

  .banner-content p {
    font-size: 15px;
    width: 100%;
  }

  .banner-video-project .video-project h5 {
    font-size: 35px;
  }

  .dashborad-sec-3-content h4 {
    font-size: 25px;
  }

  .dashborad-sec-3-content h6 {
    font-size: 16px;
  }

  .dashborad-sec-3-content p {
    font-size: 15px;
  }

  .webiner-content h3 {
    font-size: 25px;
  }

  .webiner-content p {
    font-size: 15px;
  }

  header .navbar ul li a {
    font-size: 14px;
  }

  header .navbar ul li {
    margin-right: 20px;
  }

  .banner-content h1 {
    font-size: 45px;
  }

  .banner-content h4 {
    font-size: 25px;
  }

  .banner-content p {
    font-size: 15px;
  }

  .banner-video-project .video-project h5 {
    font-size: 35px;
  }

  .dashborad-sec-3-content h4 {
    font-size: 25px;
  }

  .dashborad-sec-3-content h6 {
    font-size: 16px;
  }

  .dashborad-sec-3-content p {
    font-size: 15px;
  }

  .webiner-content p {
    font-size: 15px;
  }

  .webiner-content h3 {
    font-size: 25px;
  }

  .footer .footer-copyright p {
    font-size: 13px;
  }

  .footer .footer-menu ul li a {
    font-size: 13px;
  }

  .integration-sec {
    padding-top: 60px;
  }

  .help-banner-content h3 {
    font-size: 25px;
  }

  .secarchicone-part #searchimg {
    left: 0;
  }

  .popular-topics-box .nav .nav-link {
    padding-left: 20px;
  }

  .footer .footer-copyright p {
    font-size: 11px;
  }

  .footer .footer-menu ul li a {
    font-size: 11px;
  }
}

@media (max-width: 1023px) {
  .container {
    max-width: 720px;
  }

  .login-content {
    width: 100%;
  }

  .footer-copyright {
    padding: 8px 0;
  }

  .webinar-sec .banner-content {
    padding: 0;
    padding-bottom: 35px;
  }

  header .navbar .navbar-nav .nav-link {
    padding: 15px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  /* header .navbar{ height: 100vh;} */
  header .navbar .navbar-nav {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 20px;
  }

  header .dropdown {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .upgrade-menu .nav .nav-link {
    padding-left: 15px;
    padding-right: 15px;
  }


  .footer-copyright {
    padding: 8px 0;
  }

  .navbar-collapse {
    position: fixed;
    left: 0;
    top: 66px;
    width: 100%;
    height: 100%;
    background: #000;
    padding: 30px;
    padding-top: 0;
  }

  header .navbar ul li {
    margin-right: 0;
  }

  header .navbar .navbar-nav .nav-link {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  header .dropdown button {
    padding: 15px 0;
  }

  header .dropdown-menu {
    position: static !important;
    width: 100%;
  }

  .traning-sec .col-md-4,
  .traning-sec .col-md-8 {
    width: 100%;
  }

  .popular-topics-content {
    min-height: 10px;
    margin-top: 30px;
  }

  .col-lg-3.d-flex.align-items-center {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .login-form label {
    font-size: 15px;
  }

  #banner {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }

  #banner .banner-content {
    text-align: center !important;
  }

  #banner .watch-demo-btn {
    text-align: center !important;
    display: inline-block;
    margin: 0 !important;
    padding: 0;
  }

  .banner-video-project .video-project h5 {
    font-size: 30px !important;
  }

  .webinar-img {
    padding: 15px;
  }

  .connection-box {
    text-align: center !important;
  }

  .connection-box .text-white {
    display: block !important;
    padding: 20px 0 0 0 !important;
  }

  .upgrads-sec {
    padding-top: 40px;
  }

  .upgrade-content {
    padding: 20px;
    height: auto;
    min-height: 50px;
  }

  .upgrade-box-content {
    margin-bottom: 30px;
  }

  .popular-topics-content {
    padding-top: 60px;
  }

  .banner-content h1 {
    font-size: 35px;
  }

  .banner-content h4 {
    font-size: 20px;
  }

  .banner-items {
    padding: 0 0 30px 0 !important;
  }

  .banner-content {
    padding: 20px 0 !important;
    text-align: center !important;
  }

  .help-support-banner {
    padding-top: 60px;
  }

  .add-account-box,
  .team-member-box {
    padding: 15px;
  }

  .team-member-box table tr td,
  .team-member-box table tr th {
    white-space: nowrap;
  }
}

/* Modified */


#v-pills-client img {
  width: 100%;
}

.support-data {
  color: #FFFFFF;
}

.support-data a {
  text-decoration: none;
  color: #FFFFFF;
}

.disable-img img {
  filter: grayscale(100%);
}

.active-help {
  color: #FF8F4B !important;
}

.thumb-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

/*color picker*/
.swatch {
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
  display: inline-block;
  cursor: pointer;
  width: 100%;
  padding: 10px;
}

.swatch-color {
  width: 100%;
  height: 14px;
  border-radius: 2px;
}

.swatch-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.sketch-picker {
  position: absolute;
  z-index: 999;
}

/* end Color picker */

.hidden-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
}

/* Adi Css */
.img-left-absolute {
  position: absolute;
  top: 0;
  left: 0;
}

.img-right-absolute {
  position: absolute;
  top: 0;
  right: 0;
}

.react-calendar {
  width: 100% !important;
}


/* for video training text color */
.text-white p span {
  color: #ffffff !important;
}

.broadcast-content-box .active .circle-img-select {
  opacity: 1 !important;
}

.btn-change-8{
  background: none !important;
  border: none;
  color: #FF8F4B !important;
}
.btn-change-8 img{
  width: 225px;
}


