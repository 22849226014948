
@media (max-width: 575.98px) {

    .position-text-box{
        margin-top: 10px !important;
    }
    .integration-box li{
        margin-top:20px;
    }

    .facebook-connection{
        margin-top: 20px;
    }

    .webinar-sec{
        padding: 69px 10px;
    }

    .form-box{
        padding: 20px 10px;
    }
    .banner-content {
        padding: 40px 0px;
    }
    .banner-content h1 {
        font-size: 50px;
    }

    .project-header-name .project-item h4 {
        font-size: 20px;

    }
    .project-header-name .project-conut h6{
        font-size: 20px;
    }

    .banner-content .watch-demo-btn button{
        padding: 15px 50px;
    }
    .banner-video-project .video-project a span {

        margin-left: 0 !important;
    }



    .banner-video-project .video-project a{
        font-size: 14px;
    }

    .banner-video-project .video-project h5 {
        font-size: 20px;

    }

    .player-box{
        margin-top: 20px;
    }

    .important-topics{
        margin-top: 20px;
    }

    .banner-content p {
        width: 100%;
    }
    .banner-items{
        padding: 40px 0px;
    }

    .dashborad-sec-3{
        padding: 40px 0px;
    }

    .imgsizeleft{
        margin-top: 30px;
    }

    .dashborad-sec-3-content {
        margin-top: 30px;
    }
    header .dropdown-menu {
        left: 0 !important;
    }

    .video-card{
        margin-top: 30px;
    }

    .login-box{
        padding: 40px 0px;
    }

    .login-form{
        padding: 20px;
        margin-top: 20px;
    }
    .login-form p{
        font-size: 14px;
    }

    .login-form h4 {
        font-size: 17px;
    }
    .login-content h5 {
        font-size: 20px;
    }

    .project-button{
        flex-direction: column !important;
        margin-top: 20px;
        justify-content: center;
    }

    .project-button .project-create-btn{
        margin-top: 20px;
    }

    .project-input-box{
        margin-right: 0px;
    }

    .footer .col-lg-4{
        display: flex;
        justify-content: center !important;
    }


    .search-select{
        flex-direction: column;
        justify-content: start;
    }

    .search-select .search-box{
        margin-top: 20px;
    }

    .template-name h4{
        font-size: 14px;
    }

    .template-number h6{
        font-size: 14px;
    }

    .select-box {
        margin-top: 15px;
    }
    .support-content {
        margin-top: 30px;
    }



    .webiner-content {
        margin-top: 20px;
    }


}


@media (max-width: 767.98px) {

    .position-text-box{
        margin-top: 10px !important;
    }

    .imgsizeleft{
        margin-top: 30px !important;
    }

    .dashborad-sec-3-content {
        margin-top: 30px !important;
    }

    .footer .col-lg-4{
        display: flex;
        justify-content: center !important;
    }

    .player-box{
        margin-top: 20px;
    }

    .important-topics{
        margin-top: 20px;
    }
    .support-content {
        margin-top: 30px;
    }
    .webiner-content {
        margin-top: 20px;
    }

}

@media (max-width: 991.98px) {
    .imgsizeleft{
        margin-top: 30px !important;
    }

    .dashborad-sec-3-content {
        margin-top: 30px !important;
    }

    .login-form{
        margin-top: 30px;
    }

    .video-card{
        margin-top: 30px;
    }

    .footer .col-lg-4{
        display: flex;
        justify-content: center !important;
    }

    .project-button {
        margin-top: 30px;
    }

    .banner-items{
        padding: 60px 0px;
    }
    .banner-content{
        padding: 60px 0px;
    }
    .dashborad-sec-3{
        padding: 30px 0px;
    }

    .position-text-box {
        min-width: 123px !important;
        max-width: 123px !important;
        margin: auto;
    }
    .player-box{
        margin-top: 20px;
    }

    .important-topics{
        margin-top: 20px;
    }
    .support-content {
        margin-top: 30px;
    }
    header .navbar ul li{
        margin-right: 0px;
    }

}





@media (max-width: 1199.98px) {
    .login-content h1{
        font-size: 50px;
    }

    .template-name h4{
        font-size: 18px;
    }

    .position-text-box {
        min-width: 123px;
        max-width: 123px;
    }

    .remove-logo-switch-btn .form-check label{
        font-size: 10px;
    }

    .remove-logo-switch-btn .form-check .form-check-input{
        margin-left: 8px;
        margin-top: 10px !important;
        font-size:10px !important;
    }
    .dimmer-switch-btn  .remove-logo-switch-btn .form-check-input{
        margin-top: 2px;
        margin-left: -30px;
        font-size: 10px !important;
    }

    .integration-box li{
        margin-top:20px;
    }





}



@media (max-width:1300px){

    .position-text-box{
        min-width: 122px;
        max-width: 122px;
    }




    .remove-logo-switch-btn .form-check .form-check-input{
        margin-left: 8px;

        font-size:20px;
    }
    .dimmer-switch-btn  .remove-logo-switch-btn .form-check-input{

        font-size: 20px;
    }


}




@media (min-width:1400px){

    .save-render-sec{
        width: 100%;
    }


    .editor-preview{
        width: 100%;
    }

    .render-projectbtn {
        margin-right: 0px;
    }

    .editor-tab-menu{
        width: 100%;
    }

}



@media (min-width:1500px){
    .container-fluid{
        max-width: 1400px;
    }

    .render-projectbtn{
        margin-right: 0px !important;
    }

    .secarchicone-part #searchimg{
        left: 0%;
    }
}